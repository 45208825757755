export const useMasterDataFileValidation = () => {
  const validateTypeAndExtension = (file: File) => {
    // Used for CSV and XLSX files
    const [fileType, fileExtension] = file.type.split("/");
    const allowedTypes = ["application", "text"];
    const allowedExtensions = [
      "csv",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (
      allowedTypes.includes(fileType) &&
      allowedExtensions.includes(fileExtension)
    ) {
      return true;
    }

    return false;
  };

  return { validateTypeAndExtension };
};
