<template>
  <div class="text-center">
    <div class="marquee">
      <p>This is a hijacked session</p>
      <button @click="endHijackSession()" class="marquee-button">
        End Hijack
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();
const endHijackSession = () => {
  localStorage.clear();
  sessionStorage.clear();
  router.push({ name: "login" });
};
</script>

<style scoped lang="css">
.marquee {
  width: 100%;
  line-height: 50px;
  background-color: #ea4040;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  margin-bottom: 0;
}

.marquee-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 20px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.marquee-button:hover {
  background-color: #45a049;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.my-button {
  margin-top: 20px; /* Adjust the space between marquee and button */
  padding: 10px 20px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.my-button:hover {
  background-color: #45a049;
}
</style>
