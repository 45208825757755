<template>
  <div>
    <div class="list-header">
      <div class="list-header__text">
        <h5>{{ t("Order.SelectedOrders_97") }}</h5>
      </div>
      <div class="list-header__actions">
        <button
          type="button"
          class="btn btn-warning"
          v-if="filterCount"
          @click="resetFilters()"
        >
          {{ t("Order.ResetFilters_156") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary position-relative"
          @click="showFilterPopup = true"
        >
          {{ t("Order.Filters_154") }}
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            v-if="filterCount"
          >
            {{ filterCount }}
          </span>
        </button>
      </div>
      <!--    filter popup -->
      <transition name="fade">
        <div class="generic-popup" v-if="showFilterPopup">
          <div class="popup-wrapper popup-wrapper-lg">
            <div class="popup-header">
              <h4 class="popup-title">
                {{ t("Order.SearchAndFilter_155") }}
              </h4>
              <span class="popup-close" @click="closePopup">
                <i class="icon-close"></i>
              </span>
            </div>
            <div class="popup-body">
              <OrderFilter
                @apply="filterOrders"
                :hidden-fields="['clarification_requested_only']"
                use-local-store
                :initial-filter="filters"
              />
            </div>
          </div>
        </div>
      </transition>
      <!--    filter popup -->
    </div>
    <InocuDataTable
      v-model:items-selected="selectedOrders"
      v-model:server-options="serverOptions"
      :server-items-length="ordersListTotalCount"
      :loading="loading"
      :headers="tableHeaders"
      :items="computedOrderList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <!-- Filtrable header cells -->
      <template #header-order_number_without_prefix="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showNameFilter = !showNameFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showNameFilter">
            <input
              v-model="ordersListSearchTerm"
              type="text"
              id="orderNumber"
              class="form-control"
              @input="debounceSearchOrders"
            />
          </div>
        </div>
      </template>
      <template #item-order_number_without_prefix="order">
        <router-link
          :to="{ name: 'order', params: { id: order.id } }"
          class="dropdown-item"
        >
          {{ order.order_number }}</router-link
        >
      </template>
      <template #item-customer="order">
        {{ order.customer ? order.customer.name : "-" }}
      </template>
      <template #item-document_no_internal="order">
        {{ order.document_no_internal || "-" }}
      </template>
      <template #header-service_date="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showServiceDateFilter = !showServiceDateFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showServiceDateFilter">
            <VueDatePicker
              v-model="ordersListServiceDate"
              id="orderServiceDate"
              :placeholder="t('Order.SelectDate_49')"
              :enable-time-picker="false"
              :month-change-on-scroll="false"
              :format="formatSingleDate"
              :preview-format="formatSingleDate"
              auto-apply
            />
          </div>
        </div>
      </template>
      <template #item-service_date="order">
        <VueDatePicker
          v-if="currentUser?.user_role.includes('Payer')"
          :model-value="order.service_date"
          @update:model-value="setServiceDate(order.id, $event)"
          :placeholder="t('Order.SelectDate_49')"
          :enable-time-picker="false"
          :auto-apply="true"
          :format="formatSingleDate"
        >
          <template #trigger>
            <div style="width: 120px">
              <span
                v-if="order.new_service_date && order.service_date"
                class="old-service-date"
              >
                {{
                  order.service_date === null || order.service_date === ""
                    ? "-"
                    : moment(order.service_date).format("DD.MM.YYYY")
                }}
              </span>
              <br v-if="order.new_service_date && order.service_date" />
              <span v-if="order.new_service_date" class="clickable-text">
                {{
                  order.new_service_date === null ||
                  order.new_service_date === ""
                    ? "-"
                    : moment(order.new_service_date).format("DD.MM.YYYY")
                }}
              </span>
              <span v-else class="clickable-text">
                {{
                  order.service_date === null || order.service_date === ""
                    ? "-"
                    : moment(order.service_date).format("DD.MM.YYYY")
                }}
              </span>
              &nbsp;
              <MazIcon
                name="calendar-days"
                size="1.2rem"
                path="/icons"
                class="clickable-text"
              />
            </div>
          </template>
        </VueDatePicker>
        <span v-else>
          {{
            order.service_date === null || order.service_date === ""
              ? "-"
              : moment(order.service_date).format("DD.MM.YYYY")
          }}
        </span>
      </template>
      <template #item-order_amount="order">
        {{ order.amount ? n(+order.amount, "currency") : "-" }}
      </template>
      <template #item-balance="order">
        {{ order.balance !== "" ? n(+order.balance, "currency") : "-" }}
      </template>
      <template #header-released_by="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showReleasedByNameFilter = !showReleasedByNameFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showReleasedByNameFilter">
            <input
              v-model="ordersListReleasedBy"
              id="orderReleasedBy"
              type="text"
              class="form-control"
              @input="debounceSearchOrders"
            />
          </div>
        </div>
      </template>
      <template #item-released_by="order">
        {{ order.released_by ? order.released_by.display_name : "N/A" }}
      </template>
      <template #item-created_on="order">
        {{
          order.created_on === null || order.created_on === ""
            ? ""
            : moment(order.created_on).format("DD.MM.YYYY")
        }}
      </template>

      <template #item-status="order">
        <span
          class="order-badge approved-badge"
          v-if="order.status === 'APPROVED'"
        >
          Approved
        </span>
      </template>
      <template #item-action="order">
        <button
          v-if="order.new_service_date"
          class="btn btn-sm btn-primary"
          @click="updateCurrentOrder(order.id, order)"
        >
          {{ t("Order.Save_164") }}
        </button>
      </template>
    </InocuDataTable>
  </div>
</template>

<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, reactive, type Ref, onMounted, watch, computed } from "vue";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";
import moment from "moment";
import debounce from "lodash/debounce";
import { useOrderStore } from "@/stores/order.store";
import { useToast } from "vue-toast-notification";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import MazIcon from "maz-ui/components/MazIcon";
import OrderFilter from "@/components/orders/OrderFilter.vue";

const { getOrders, patchOrder } = useOrderStore();

const { currentUser } = storeToRefs(useUserStore());

const props = defineProps({
  isEdit: {
    default: false,
    type: Boolean,
  },
  index: {
    default: 0,
    type: Number,
  },
  customer: {
    default: null,
    type: Number,
  },
  orders: {
    default: () => [],
    type: Array,
  },
});

const filterCount = computed(() => {
  let count = 0;
  for (const key in filters.value) {
    // if the key is a date range, count as 1
    if (key.includes("before")) {
      // the after will be counted
      continue;
    }
    if (filters.value[key] !== "" && filters.value[key] !== false) {
      count += 1;
    }
  }
  return count;
});

const toast = useToast();

const { t, n } = useI18n();

let selectedOrderFilter = reactive({
  date: [],
});

const loading = ref(false);

const showNameFilter = ref(false);
const showReleasedByNameFilter = ref(false);
const showServiceDateFilter = ref(false);
const ordersListSearchTerm: Ref<string> = ref("");
const ordersListReleasedBy: Ref<string> = ref("");
const ordersListServiceDate: Ref<string> = ref("");

const ordersListTotalCount = ref(0);

const selectedOrders: any = ref([]);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "order_number_without_prefix",
  sortType: "desc",
});

const orderList: any = ref([]);

const customerId: any = ref(null);

const tableHeaders: Ref<Header[]> = ref([
  {
    text: t("Order.OrderNo_75"),
    value: "order_number",
  },
  {
    text: t("Order.Supplier_31"),
    value: "supplier_name",
  },
  {
    text: t("Order.DocumentNo_112"),
    value: "document_no_internal",
    width: 100,
  },
  {
    text: t("Order.ServiceDate_30"),
    value: "service_date",
  },
  {
    text: "Amount",
    value: "order_amount",
  },
  {
    text: t("Order.Remaining_Balance_58"),
    value: "balance",
  },
  {
    text: "Released By",
    value: "released_by_name",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "",
    value: "action",
  },
]);

const formatSingleDate = (date: Date) => {
  const date1 = moment(date).format("DD.MM.YYYY");
  return date1;
};

const debounceSearchOrders = debounce(async function (event) {
  switch (event.target.id) {
    case "orderReleasedBy":
      ordersListReleasedBy.value = event.target.value;
      break;
    case "orderNumber":
      ordersListSearchTerm.value = event.target.value;
      break;
    default:
      ordersListSearchTerm.value = event.target.value;
      break;
  }
  await getOrderList(props.customer);
}, 300);

const getOrderList = async (customer: number) => {
  loading.value = true;
  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  await getOrders({
    ...filters.value,
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: sortDirection + sortBy,
    created_after:
      filters.value.created_after ||
      (selectedOrderFilter.date !== null && selectedOrderFilter.date.length > 0)
        ? moment(selectedOrderFilter.date[0]).format("YYYY-MM-DD")
        : null,
    created_before:
      filters.value.created_before ||
      (selectedOrderFilter.date !== null && selectedOrderFilter.date.length > 0)
        ? moment(selectedOrderFilter.date[0]).format("YYYY-MM-DD")
        : null,
    customer: customer,
    is_active: true,
    real_status: "APPROVED",
  }).then((response) => {
    orderList.value = response.results;
    ordersListTotalCount.value = response.count;

    orderList.value = orderList.value.map((obj) => ({
      ...obj,
      ["temp_amount"]: 0,
    }));

    loading.value = false;
  });
};

const updateCurrentOrder = async (id: any, order: any) => {
  await patchOrder(id, {
    service_date: order.new_service_date,
  })
    .then(() => {
      order.service_date = order.new_service_date;
      order.new_service_date = null;
      let index = orderList.value.findIndex((e: any) => e.id === id);
      orderList.value[index] = order;
      toast.open({
        message: "Service date has been updated.",
        type: "success",
      });
    })
    .catch((error) => {
      toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
    });
};

const computedOrderList = computed(() => {
  return orderList.value;
});

const setServiceDate = (id: number, date: any) => {
  const order = orderList.value.find((e: any) => e.id === id);
  let new_date = moment(date).format("YYYY-MM-DD");
  if (order.service_date === new_date) {
    order.new_service_date = null;
  } else {
    order.new_service_date = new_date;
  }
};

const showFilterPopup = ref(false);

const closePopup = () => {
  showFilterPopup.value = false;
};

const filters = ref({
  amount_max: "",
  amount_min: "",
  clarification_requested_only: false,
  created_after: "",
  created_before: "",
  created_by: "",
  current_balance_max: "",
  current_balance_min: "",
  customer: "",
  document_no_internal: "",
  hide_zero_balance: false,
  order_number: "",
  ordering: "",
  page: "",
  page_size: "",
  project: "",
  project_no: "",
  released_by: "",
  service_date_after: "",
  service_date_before: "",
  status: "",
  supplier_name: "",
});

const filterOrders = async (filter: any) => {
  filters.value = filter;
  serverOptions.value.page = 1;
  closePopup();
  await getOrderList(customerId.value);
};

const resetFilters = () => {
  filters.value = {
    amount_max: "",
    amount_min: "",
    clarification_requested_only: false,
    created_after: "",
    created_before: "",
    created_by: "",
    current_balance_max: "",
    current_balance_min: "",
    customer: "",
    document_no_internal: "",
    hide_zero_balance: false,
    order_number: "",
    ordering: "",
    page: "",
    page_size: "",
    project: "",
    project_no: "",
    released_by: "",
    service_date_after: "",
    service_date_before: "",
    status: "",
    supplier_name: "",
  };
  serverOptions.value.page = 1;
  getOrderList(customerId.value);
};

onMounted(async () => {
  customerId.value = props.customer;
  /* Get Order */
  await getOrderList(customerId.value);
  /* Get Order */
  let preSelectOrders = props.orders.map((i: any) => i.order_id);
  orderList.value.map((i: any) => {
    if (preSelectOrders.includes(i.id)) {
      selectedOrders.value.push(i);
    }
  });
});

watch(
  serverOptions,
  async () => {
    await getOrderList(customerId.value);
  },
  {
    deep: true,
  }
);

const selectedOrderWatcher = computed(() => {
  return selectedOrders.value;
});

const emit = defineEmits(["order-selected", "get-current-index"]);

watch(
  selectedOrderWatcher,
  (newVal) => {
    emit("get-current-index", props.index);
    emit("order-selected", newVal);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  props,
  async (newValue) => {
    customerId.value = newValue.customer;
    await getOrderList(newValue.customer);
  },
  {
    deep: true,
  }
);
</script>

<style scoped>
.clickable-text {
  color: #1976d2;
  cursor: pointer;
}
.old-service-date {
  color: red;
  text-decoration: line-through;
}
</style>
