<template>
  <div class="generic-list-page">
    <h1 class="page-title">{{ t("CustomerManagement.ProjectsList_62") }}</h1>
    <div class="list-table">
      <Table />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Table from "@/components/shared/tables/projects-table/Table.vue";

const { t } = useI18n();
</script>
