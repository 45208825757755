export const supportedLocales = {
  en: "English",
  de: "Deutsch (German)",
};

export function getBrowserLocale(options = {}) {
  // Get locale string as "en-US" or get countryCodeOnly .eg "en"
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}

export function supportedLocalesInclude(locale: string | undefined) {
  if (!locale) return false;

  return Object.keys(supportedLocales).includes(locale);
}
