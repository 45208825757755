<template>
  <div class="tw-flex tw-flex-row tw-space-x-8 tw-w-full">
    <StatisticsBarChart :series="series" :xaxis="xaxis" :isLoading="isLoading">
      <template #title> {{ t("Statistics.NumberOfRequests_6") }} </template>
    </StatisticsBarChart>

    <div class="tw-w-2/12 tw-flex tw-flex-col tw-gap-y-4 tw-max-h-72">
      <StatisticsCard
        :title="t('Statistics.TotalOrderRequests_7')"
        :value="orderCount"
        :trendingValue="totalOrdersComparison"
      >
        <template #icon>
          <ordersCountIcon />
        </template>
        <template #value>
          {{ formattedOrderCount.value
          }}<span class="tw-text-sm">
            {{ formattedOrderCount.unit }}
          </span>
        </template>
      </StatisticsCard>

      <StatisticsCard
        :title="t('Statistics.TotalPaymentRequests_8')"
        :value="paymentCount"
        :trendingValue="totalPaymentsComparison"
        dark
      >
        <template #icon>
          <approvedPaymentVolume />
        </template>
        <template #value>
          {{ formattedPaymentCount.value
          }}<span class="tw-text-sm">
            {{ formattedPaymentCount.unit }}
          </span>
        </template>
      </StatisticsCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import ordersCountIcon from "../icons/ordersCountIcon.svg";
import approvedPaymentVolume from "../icons/approvedPaymentVolume.svg";
import { ref, onMounted, watch, type Ref, computed } from "vue";
import StatisticsBarChart from "../components/StatisticsBarChart.vue";
import StatisticsCard from "../components/StatisticsCard.vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";

const { formatLargeNumber } = useFormatValue();

const { getNumberOfRequests } = useDashboardStore();
const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const isLoading = ref(true);

const { t } = useI18n();

const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.TotalPaymentRequests_8"),
    data: [],
  },
  {
    name: t("Statistics.TotalOrderRequests_7"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);
const orderCount = ref(0);
const paymentCount = ref(0);
const totalOrdersComparison = ref(0);
const totalPaymentsComparison = ref(0);

const formattedOrderCount: Ref<any> = computed(() => {
  return formatLargeNumber(orderCount.value, true);
});
const formattedPaymentCount: Ref<any> = computed(() => {
  return formatLargeNumber(paymentCount.value, true);
});

const fetchData = async () => {
  isLoading.value = true;
  try {
    const data = await getNumberOfRequests(
      props.startDate,
      props.endDate,
      props.customerId
    );
    series.value[0].data = data.payments;
    series.value[1].data = data.orders;
    xaxis.value = data.dates;
    orderCount.value = data.total_orders;
    paymentCount.value = data.total_payments;
    totalOrdersComparison.value = data.total_orders_comparison;
    totalPaymentsComparison.value = data.total_payments_comparison;
  } catch (error) {
    console.error("Error fetching number of requests:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchData);
watch(() => props, fetchData, { deep: true });
</script>
