<template>
  <InocuDataTable
    v-model:server-options="serverOptions"
    :server-items-length="assignTeamListTotalCount"
    :loading="loading"
    :headers="teamTableHeaders"
    :items="assignTeamList"
    :rows-items="[10, 25, 50, 100]"
    theme-color="#727cf3"
    table-class-name="listing-custom-table"
    alternating
    buttons-pagination
    no-hover
    must-sort
  >
    <template #item-first_name="team">
      {{ team.user.first_name }}
    </template>

    <template #item-last_name="team">
      {{ team.user.last_name }}
    </template>

    <template #item-role="team">
      {{ toCapitalize(team.user_role) }}
    </template>

    <template #item-auto_approval_limit="team">
      {{ team.approver_limit }}
    </template>

    <!-- No Data View -->
    <template #empty-message>
      {{ t("CustomerManagement.NoRolesToShow_147") }}...
    </template>
  </InocuDataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useCurrencyFormatting } from "@/_helpers/composables/currency-formatting.composable";
import { useFilters } from "@/_helpers/composables/global-filters.composable";
import { useCustomerStore } from "@/stores/customer.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { setCurrency } = useCurrencyFormatting();
const { toCapitalize } = useFilters();
const { getAssignTeamById } = useCustomerStore();

const props = defineProps({
  CustomerId: Number,
});

const teamTableHeaders: Ref<Header[]> = ref([
  { text: "First Name", value: "first_name", width: 300 },
  { text: "Last  Name", value: "last_name", width: 300 },
  { text: "Role", value: "role", width: 300, sortable: true },
  {
    text: "Automatic Approval Limit",
    value: "auto_approval_limit",
    width: 300,
  },
]);

const assignTeamList: any = ref([]);
const loading = ref(false);
const assignTeamListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "role",
  sortType: "asc",
});

const getTeamTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getAssignTeamById({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    customer: props.CustomerId!,
    ordering: sortDirection + sortBy,
    is_active: true,
  });

  assignTeamList.value = [...response.results];
  assignTeamList.value.map((item: any) => {
    item.approver_limit =
      item.approver_limit != null ? setCurrency(item.approver_limit) : "None";
  });
  assignTeamListTotalCount.value = response.count;

  loading.value = false;
};

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getTeamTableList();
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  await getTeamTableList();
});
</script>
