<template>
  <RouterView />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView } from "vue-router";
import router from "./router";

const { locale } = useI18n();

watch(router.currentRoute, (route) => {
  const lang = route.params.locale;

  if (lang === "de" || lang === "en") {
    locale.value = lang;
  }
});
</script>

<style lang="scss"></style>
