export const useFilters = () => {
  const toCapitalize = (value: any) => {
    value = value.toLowerCase();
    value = value.replace(/[_]/g, " ");
    return value.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  return { toCapitalize };
};
