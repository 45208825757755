<template>
  <table class="order-detail-table">
    <thead>
      <tr>
        <th>{{ t("Order.Description_108") }}</th>
        <th>{{ t("Order.Value_109") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <span> {{ t("Order.OrderNumber_110") }} </span>
        </td>
        <td>
          <span> {{ order.order_number }} </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.Customer_1") }} </span>
        </td>
        <td>
          <span> {{ order.customer?.name }} </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.Project_4") }} </span>
        </td>
        <td>
          <span>
            {{
              order.project && order.project.name !== ""
                ? order.project.name
                : "-"
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.RemainingProjectBudget_147") }} </span>
        </td>
        <td>
          <span>
            {{
              order.project && order.project?.id
                ? n(+order.remaining_project_budget, "currency")
                : "-"
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.Supplier_31") }} </span>
        </td>
        <td>
          <span> {{ order.supplier?.name }} </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.SupplierNo_35") }} </span>
        </td>
        <td>
          <span> {{ order.supplier?.supplier_number || "-" }} </span>
        </td>
      </tr>
      <tr v-if="order.net_eur !== null">
        <td>
          <span> {{ t("Order.NetEuro_37") }} </span>
        </td>
        <td>
          <span>
            {{ order.net_eur ? n(+order.net_eur, "currency") : "-" }}
          </span>
        </td>
      </tr>

      <tr>
        <td>
          <span> {{ t("Order.GrossEuro_38") }} </span>
        </td>
        <td>
          <span>
            {{ order.amount ? n(+order.amount, "currency") : "-" }}
          </span>
        </td>
      </tr>
      <tr v-if="order.name !== '' && order.is_recurring">
        <td>
          <span> {{ t("Order.OrderName_13") }} </span>
        </td>
        <td>
          <span> {{ order.name }} </span>
        </td>
      </tr>
      <tr v-if="order.frequency !== '' && order.is_recurring">
        <td>
          <span> {{ t("Order.Frequency_17") }} </span>
        </td>
        <td>
          <span> {{ order.frequency }} </span>
        </td>
      </tr>
      <tr v-if="order.end_after !== '' && order.is_recurring">
        <td>
          <span> {{ t("Order.EndsAfter_22") }} </span>
        </td>
        <td>
          <span> {{ order.end_after !== null ? order.end_after : "0" }} </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.CreatedBy_140") }} </span>
        </td>
        <td>
          <span>
            {{ order.created_by ? order.created_by.display_name : "-" }}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.CreatedOn_139") }} </span>
        </td>
        <td>
          <span> {{ moment(order.created_on).format("DD.MM.YYYY") }} </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.ServiceDate_30") }} </span>
        </td>
        <td>
          <span> {{ moment(order.service_date).format("DD.MM.YYYY") }} </span>
        </td>
      </tr>
      <tr v-if="order.date_order_list !== null">
        <td>
          <span> {{ t("Order.DateOrderList_111") }} </span>
        </td>
        <td>
          <span>
            {{ moment(order.date_order_list).format("DD.MM.YYYY") }}
          </span>
        </td>
      </tr>
      <tr v-if="order.document_no_internal !== null">
        <td>
          <span> {{ t("Order.DocumentNo_112") }} </span>
        </td>
        <td>
          <span>
            {{ order.document_no_internal ? order.document_no_internal : "-" }}
          </span>
        </td>
      </tr>
      <tr v-if="order.project_no !== null">
        <td>
          <span> {{ t("Order.ProjectNo_146") }} </span>
        </td>
        <td>
          <span> {{ order.project_no ? order.project_no : "-" }} </span>
        </td>
      </tr>
      <tr v-if="order.order_triggered_on !== null">
        <td>
          <span> {{ t("Order.OrderTriggeredOn_115") }} </span>
        </td>
        <td>
          <span>
            {{ moment(order.order_triggered_on).format("DD.MM.YYYY") }}
          </span>
        </td>
      </tr>
      <tr v-if="order.order_no_supplier !== ''">
        <td>
          <span> {{ t("Order.OrderNoSupplier_117") }} </span>
        </td>
        <td>
          <span>
            {{ order.order_no_supplier ? order.order_no_supplier : "-" }}
          </span>
        </td>
      </tr>
      <tr v-if="order.expected_date_processing !== null">
        <td>
          <span> {{ t("Order.ExpectedDateProcessing_119") }} </span>
        </td>
        <td>
          <span>
            {{
              order.expected_date_processing
                ? order.expected_date_processing
                : "-"
            }}
          </span>
        </td>
      </tr>
      <tr v-if="order.comment !== null">
        <td>
          <span> {{ t("Order.Comment_120") }} </span>
        </td>
        <td>
          <span> {{ order.comment ? order.comment : "-" }} </span>
        </td>
      </tr>
      <tr v-if="order.comment_management !== null">
        <td>
          <span> {{ t("Order.CommentManagement_122") }} </span>
        </td>
        <td>
          <span>
            {{ order.comment_management ? order.comment_management : "-" }}
          </span>
        </td>
      </tr>
      <tr v-if="order.comment_insv !== null">
        <td>
          <span> {{ t("Order.CommentINSV_123") }} </span>
        </td>
        <td>
          <span> {{ order.comment_insv ? order.comment_insv : "-" }} </span>
        </td>
      </tr>
      <tr v-if="order.preliminary_insolvency_proceedings !== null">
        <td>
          <span> {{ t("Order.PreliminaryInsolvencyProcedding127") }} </span>
        </td>
        <td>
          <span>
            {{ order.preliminary_insolvency_proceedings ? "Yes" : "No" }}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span> {{ t("Order.Remaining_Balance_58") }} </span>
        </td>
        <td>
          <span>
            {{ order.balance ? n(+order.balance, "currency") : "-" }}
          </span>
        </td>
      </tr>
      <tr v-if="order?.is_restrict">
        <td>
          <span> {{ t("Order.Cleared_by_149") }} </span>
        </td>
        <td>
          <ul class="clearers-list">
            <li v-for="(clearer, index) in order?.cleared_by" :key="index">
              <span class="clearer-badge mx-3" :key="clearer">
                {{ clearer }}
              </span>
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="order.status == 'APPROVED'">
        <td>
          <span> {{ t("Order.ReleasedBy_148") }} </span>
        </td>
        <td>
          <span> {{ order?.released_by?.display_name }} </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import moment from "moment";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

defineProps({
  order: {
    type: Object,
    default: () => {},
  },
});
</script>

<style>
.clearer-badge {
  font-size: 12px;
  line-height: 28px;
  padding: 4px 8px;
}
.clearers-list {
  max-height: 100px;
  overflow-y: scroll;
}
</style>
