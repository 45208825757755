export const numberFormats = {
  en: {
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "code",
    },
  },
  de: {
    decimal: {
      style: "decimal",
      minimumSignificantDigits: 3,
      maximumSignificantDigits: 5,
    },
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
};
