import { defineStore } from "pinia";
import axios from "axios";

import {
  type CurrentUser,
  type User,
  type UsersListPaginationOptions,
  type UserInList,
  UserRoles,
} from "@/_helpers/interfaces/";
import { ref, type Ref } from "vue";
import type { ItemsListResponse } from "@/_helpers/interfaces/listing/items-list-response.interface";
import _ from "lodash";

export const useUserStore = defineStore("user", () => {
  const currentUser: Ref<CurrentUser | null> = ref(null);

  const getCurrentUser = async (): Promise<CurrentUser> => {
    try {
      const { data } = await axios.get(`/users/me/`);

      currentUser.value = data;
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getAssignedCustomersWithRolesList = (
    user: User | CurrentUser
  ): Array<{
    customerId: number;
    customerName: string;
    roles: string[];
  }> => {
    const assignmentsGroupedByCustomers = _.chain(user!.customer_assignments)
      .groupBy("customer_name")
      .map((value, key) => ({ customerName: key, assignments: value }))
      .value();

    const customersWithUserRolesList = assignmentsGroupedByCustomers.map(
      (customerAssignments) => {
        return {
          customerName: customerAssignments.customerName,
          ...customerAssignments.assignments.reduce(
            (previous: any, current) => {
              previous["customerId"] = current.customer;
              previous["roles"] = previous["roles"]
                ? [...previous["roles"], current.user_role]
                : [current.user_role];

              return previous;
            },
            {}
          ),
        };
      }
    );

    return customersWithUserRolesList;
  };

  const fetchCurrentUserAndAssociatedRoles = async () => {
    currentUser.value = await getCurrentUser();

    currentUser.value!.user_role = [];

    if (currentUser.value?.is_admin) {
      currentUser.value.user_role = [UserRoles.ADMIN];
    } else {
      const listOfAssignedCustomers = getAssignedCustomersWithRolesList(
        currentUser.value
      );

      if (listOfAssignedCustomers.length) {
        const savedSelectedCustomerId =
          localStorage.getItem("selectedCustomerId");
        let selectedCustomer;

        if (
          !savedSelectedCustomerId ||
          savedSelectedCustomerId === "undefined"
        ) {
          selectedCustomer = listOfAssignedCustomers[0];
        } else {
          selectedCustomer = listOfAssignedCustomers.filter(
            (assignedCustomer) =>
              assignedCustomer.customerId === +savedSelectedCustomerId
          )[0];
        }

        if (!selectedCustomer) selectedCustomer = listOfAssignedCustomers[0];

        currentUser.value!.user_role = selectedCustomer.roles.map(
          (role) => UserRoles[role]
        );
        localStorage.setItem(
          "selectedCustomerId",
          `${selectedCustomer.customerId}`
        );
      }
    }
  };

  const resetPassword = async (
    newPassword: string,
    confirmPassword: string
  ): Promise<any> => {
    try {
      const { data } = await axios.post(`/users/change_password/`, {
        new_password: newPassword,
        new_password_confirm: confirmPassword,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getEditProfilePayload = (payload: {
    profilePicture?: File;
    userFirstName?: string;
    userLastName?: string;
    signature?: File;
    email_notification_preference?: "daily" | "never" | "immediate";
  }) => {
    const { userFirstName, userLastName, profilePicture, signature } = payload;

    const formData = new FormData();

    if (profilePicture) {
      formData.append("profile_picture", profilePicture);
    }

    if (userFirstName) {
      formData.append("first_name", userFirstName);
    }

    if (userLastName) {
      formData.append("last_name", userLastName);
    }

    if (signature) {
      formData.append("signature", signature);
    }
    if (payload.email_notification_preference) {
      formData.append(
        "email_notification_preference",
        payload.email_notification_preference
      );
    }

    return formData;
  };

  const editUserProfile = async (payload: {
    profilePicture?: File;
    userFirstName?: string;
    userLastName?: string;
    signature?: File;
    email_notification_preference?: "daily" | "never" | "immediate";
  }): Promise<any> => {
    const formData = getEditProfilePayload(payload);

    try {
      const { data } = await axios.patch(`/users/update_profile/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      currentUser.value!.first_name = data.first_name;
      currentUser.value!.last_name = data.last_name;
      currentUser.value!.profile_picture = data.profile_picture;
      currentUser.value!.signature = data.signature;
      currentUser.value!.email_notification_preference =
        data.email_notification_preference;

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const addUser = async (payload: {
    first_name: string;
    last_name: string;
    email: string;
    roles?: string[];
    customer?: number;
  }): Promise<any> => {
    try {
      const { data } = await axios.post(`/users/add_user/`, payload);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getUsersList = async (
    options: UsersListPaginationOptions
  ): Promise<ItemsListResponse<UserInList>> => {
    try {
      const { data } = await axios.get(`/users/`, { params: options });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getUserById = async (userId: number): Promise<User> => {
    try {
      const { data } = await axios.get(`/users/${userId}/`);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const changeUserActivityStatus = async (
    status: "activate" | "deactivate",
    userId: number
  ): Promise<{
    is_active: boolean;
    warning: "string";
  }> => {
    try {
      const { data } = await axios.patch(`/users/${userId}/${status}/`);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const changeBulkUsersActivityStatus = async (
    status: "activate" | "deactivate",
    usersIds: number[]
  ): Promise<{
    users: Number[];
    warnings: string[];
  }> => {
    try {
      const { data } = await axios.post(`/users/bulk_${status}/`, {
        users: usersIds,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getAvailableReplacements = async (params: {
    customer_id: number;
    vacation_start: string;
    vacation_end: string;
  }) => {
    try {
      const { data } = await axios.get(`/users/get_available_replacements/`, {
        params,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const updateVacation = async (payload: {
    vacation_start: string;
    vacation_end: string;
    replacements: {
      user: number;
      customer: number;
    }[];
  }) => {
    try {
      const { data } = await axios.patch(`/users/update_vacation/`, payload);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const editUserAssignments = async (
    userId: number,
    payload: {
      customer: number;
      roles: string[];
    }
  ) => {
    try {
      const { data } = await axios.patch(
        `/users/${userId}/edit_user_customer_assignment/`,
        payload
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const editUserAdminStatus = async (userId: number, isAdmin: boolean) => {
    try {
      const { data } = await axios.patch(`/users/${userId}/edit_user/`, {
        is_admin: isAdmin,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return {
    currentUser,
    getAssignedCustomersWithRolesList,
    fetchCurrentUserAndAssociatedRoles,
    resetPassword,
    editUserProfile,
    addUser,
    getUsersList,
    getUserById,
    changeUserActivityStatus,
    changeBulkUsersActivityStatus,
    getAvailableReplacements,
    updateVacation,
    editUserAssignments,
    editUserAdminStatus,
  };
});
