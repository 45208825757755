<template>
  <div class="generic-list-page">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <h1 class="page-title">{{ t("Order.Orders_72") }}</h1>
      </div>
    </div>
    <div class="list-table">
      <!-- Tabs -->
      <ul class="orders-tabs">
        <li :class="!route.query.status ? 'active' : ''">
          <router-link :to="{ name: 'orders' }" class="dropdown-item">
            {{ t("Order.AllTypes_89") }}
          </router-link>
        </li>
        <li
          v-if="showPendingTab()"
          :class="route.query.status === 'PENDING_APPROVAL' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'orders', query: { status: 'PENDING_APPROVAL' } }"
            class="dropdown-item"
          >
            <div
              class="status-indicator status-indicator--pending_approval"
            ></div>
            {{ t("Order.Pending_90") }}
          </router-link>
        </li>
        <!-- Hide this tab for clearers -->
        <li
          v-if="!currentUser?.user_role?.includes('Order Clearer')"
          :class="route.query.status === 'CLEARING' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'orders', query: { status: 'CLEARING' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--clearing"></div>
            {{ t("Order.Clearing_141") }}</router-link
          >
        </li>
        <li :class="route.query.status === 'APPROVED' ? 'active' : ''">
          <router-link
            :to="{ name: 'orders', query: { status: 'APPROVED' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--approved"></div>
            {{ t("Order.Approved_91") }}
          </router-link>
        </li>
        <li :class="route.query.status === 'REJECTED' ? 'active' : ''">
          <router-link
            :to="{ name: 'orders', query: { status: 'REJECTED' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--rejected"></div>
            {{ t("Order.Rejected_92") }}
          </router-link>
        </li>
        <li :class="route.query.status === 'DRAFT' ? 'active' : ''">
          <router-link
            :to="{ name: 'orders', query: { status: 'DRAFT' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--draft"></div>
            {{ t("Order.Drafts_93") }}
          </router-link>
        </li>
      </ul>
      <div>
        <OrderTable
          :status="
            typeof route.query.status === 'string' ? route.query.status : ''
          "
          :customer="orderFilter.customer ? orderFilter.customer : -1"
        />
      </div>
      <!-- Tabs -->
    </div>
  </div>
</template>

<script setup lang="ts">
import "@vuepic/vue-datepicker/dist/main.css";
import { reactive, onBeforeMount } from "vue";
import OrderTable from "@/components/shared/tables/orders-table/OrdersTable.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute();

const { t } = useI18n();

import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const { currentUser } = storeToRefs(useUserStore());

let orderFilter: any = reactive({
  customer: null,
});

const showPendingTab = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return false;
  }
  if (
    currentUser.value?.user_role.includes("Order Clearer") ||
    currentUser.value?.user_role.includes("Approver")
  ) {
    return true;
  }
  if (currentUser.value?.user_role.includes("Purchaser")) {
    return false;
  }
  return true;
};

onBeforeMount(() => {
  let getStoreCustomerId = localStorage.getItem("selectedCustomerId");
  getStoreCustomerId ? (orderFilter.customer = +getStoreCustomerId) : null;
});
</script>
