<template>
  <div class="customer">
    <div class="form-wrapper mb-4">
      <div class="generic-form">
        <div class="row">
          <!-- Project -->
          <div class="col-lg-6 col-12">
            <div class="form-input mb-lg-0">
              <label class="form-label"> {{ t("Order.Project_4") }}</label>
              <LegacyMultiSelect
                v-model="formData.project"
                name="projects"
                :object="false"
                :options="projectsList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="t('Order.SelectProject_5')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
            </div>
          </div>
          <!-- Bank -->
          <div class="col-lg-6 col-12">
            <div class="form-input mb-lg-0">
              <label class="form-label"> Choose Bank </label>
              <LegacyMultiSelect
                v-model="formData.bank"
                name="banks"
                :object="true"
                :options="banksList"
                :resolve-on-load="true"
                :min-chars="0"
                placeholder="Select Bank Account"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="bank_name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="formData.customer === null" class="text-center">
      Please Select Customer to view the related sepa payments fields
    </div>
    <div v-else>
      <div class="form-wrapper mb-4">
        <div class="form-title">
          <h2>Bank Details</h2>
        </div>
        <!-- Sepa Form -->
        <div class="generic-form">
          <div class="row">
            <!--  Account Owner -->
            <div class="col-md-6 col-12">
              <div class="form-input">
                <label class="form-label"> Account Owner </label>
                <input
                  type="text"
                  placeholder="Enter Account Owner"
                  @keyup="changeValuekeyUp()"
                  v-model="sepaPayment.owner"
                />
              </div>
            </div>
            <!--  Swift Code / BIC -->
            <div class="col-md-6 col-12">
              <div class="form-input">
                <label class="form-label"> Swift Code / BIC </label>
                <input
                  type="text"
                  placeholder="Enter Swift Code / BIC"
                  @keyup="changeValuekeyUp()"
                  v-model="sepaPayment.swiftCode"
                />
              </div>
            </div>
            <!-- IBAN -->
            <div class="col-md-6 col-12">
              <div class="form-input">
                <label class="form-label"> IBAN </label>
                <input
                  type="text"
                  placeholder="Enter IBAN"
                  @keyup="changeValuekeyUp()"
                  v-model="sepaPayment.iban"
                />
              </div>
            </div>
            <!-- Execution Date -->
            <div class="col-md-6 col-12">
              <div class="form-input">
                <label class="form-label"> Execution Date </label>
                <VueDatePicker
                  v-model="sepaPayment.execution_date"
                  placeholder="Select Date"
                  :enable-time-picker="false"
                  :format="formatDate"
                  auto-apply
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="list-table mt-4">
        <SelectPaymentsTable
          :customer="formData.customer"
          @select-payments="updateSelectedPayments"
        />
      </div>
      <!-- Actions -->
      <div class="row mt-4 border-t">
        <div class="col-12">
          <div class="generic-form">
            <div class="mt-4" v-if="isCreatedSuccessfully != null">
              <div
                v-if="isCreatedSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                {{ t("Payments.PaymentSepaSuccess_29") }}
              </div>
              <div v-else class="col-md-5 alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
            </div>

            <div class="form-options mt-4">
              <button
                class="submit-btn"
                :disabled="isgenerate"
                :style="isgenerate ? 'cursor: not-allowed' : ''"
                @click="generate()"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectPaymentsTable from "@/components/shared/tables/sepa-payments-table/SelectPaymentsTable.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ref, reactive, onMounted, type Ref, watch, computed } from "vue";

import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import router from "@/router";

import { useCustomerStore } from "@/stores/customer.store";
import { useProjectStore } from "@/stores/project.store";
import { useBankAccountStore } from "@/stores/bank-account.store";
import { useSepaStore } from "@/stores/sepa.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { getBankAccountsList, getBankAccountById, createBankAccount } =
  useBankAccountStore();
const { createSepaPayment } = useSepaStore();

let formData: any = reactive({
  customer: null,
  project: null,
  bank: null,
});

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);

const rules = {
  customer: {
    required,
  },
};

let customersList: any = ref([]);

let projectsList: any = ref([]);

let banksList: any = ref([]);

const errorMessage: any = ref("");

const keyUp: any = ref(false);

const $v = useVuelidate(rules, formData);

let sepaPayment: any = ref({
  owner: "",
  swiftCode: "",
  iban: "",
  execution_date: new Date(),
});

let paymentData: any = ref([]);

/* Get Project By Customer id*/
const getProjectsList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

/* Get Banks*/
const getBankList = async (customerId: number) => {
  const response = await getBankAccountsList({
    page: 1,
    page_size: 100,
    ordering: "bank_name",
    customer: customerId,
  });

  banksList.value = [...response.results];
};

/* Get Bank details*/
const getBankDetail = (bank_id: number) => {
  getBankAccountById(bank_id)
    .then((response) => {
      sepaPayment.value.owner = response.owner_name;
      sepaPayment.value.swiftCode = response.swift_code;
      sepaPayment.value.iban = response.iban;
      keyUp.value = false;
    })
    .catch((error) => console.log(error));
};

const formatDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};

const updateSelectedPayments = (newValue: any) => {
  paymentData.value = [];
  newValue.map((payment: any) => {
    let obj = {
      payment_id: payment.id,
      note: payment.invoice_number !== null ? payment.invoice_number : "",
    };
    paymentData.value.push(obj);
  });
};

const changeValuekeyUp = () => {
  keyUp.value = true;
};

const generateBankDetail = async () => {
  try {
    await createBankAccount({
      owner_name: sepaPayment.value.owner,
      bank_name: formData.bank.bank_name,
      swift_code: sepaPayment.value.swiftCode,
      iban: sepaPayment.value.iban,
      customer: formData.customer !== null ? formData.customer : null,
    });
  } catch (error) {
    throw error;
  }
};

const createSepa = async () => {
  let payload = {
    customer: formData.customer,
    payment_data: paymentData.value,
    source_account: formData.bank.id,
    execution_date: moment(sepaPayment.value.execution_date).format(
      "YYYY-MM-DD"
    ),
  };

  await createSepaPayment(payload)
    .then(() => {
      isCreatedSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "sepa-payments" });
      }, 2000);
    })
    .catch((error) => {
      console.log(error);
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    });
};

const isgenerate = computed(() => {
  return (
    sepaPayment.value.owner === "" ||
    sepaPayment.value.swiftCode === "" ||
    sepaPayment.value.iban === ""
  );
});

const generate = async () => {
  isCreatedSuccessfully.value = null;
  if (keyUp.value === true) {
    await generateBankDetail()
      .then(() => {
        createSepa();
      })
      .catch((error) => {
        isCreatedSuccessfully.value = false;
        if (error.response.data.type === "validation_error") {
          let field = error.response.data.errors[0].attr.replace("_", " ");
          field = field.toUpperCase();
          errorMessage.value =
            field + ": " + error.response.data.errors[0].detail;
        } else {
          errorMessage.value = error.response.data.errors[0].detail;
        }
      });
  } else {
    createSepa();
  }
};

onMounted(async () => {
  let getSelectedCustomerID = localStorage.getItem("selectedCustomerId");
  if (getSelectedCustomerID) {
    formData.customer = JSON.parse(getSelectedCustomerID);
    await getProjectsList(formData.customer);
    await getBankList(formData.customer);
  }
});

watch(
  () => formData.bank,
  async (newVal) => {
    await getBankDetail(newVal.id);
  }
);
</script>
