class TokenService {
  getRefreshToken(): string | null {
    return sessionStorage.getItem("refresh_token");
  }

  getAccessToken(): string | null {
    return sessionStorage.getItem("access_token");
  }

  setRefreshToken(refreshToken: string): void {
    sessionStorage.setItem("refresh_token", refreshToken);
  }

  setAccessToken(accessToken: string): void {
    sessionStorage.setItem("access_token", accessToken);
  }
}

export default new TokenService();
