<template>
  <div class="customer">
    <div class="form-wrapper">
      <div class="form-title">
        <h2>{{ t("CustomerManagement.AssignTeam_7") }}</h2>
      </div>
      <AssignTeam
        v-if="customerId"
        :customerId="customerId"
        :isEditMode="true"
        :isPrevious="false"
        :isFormWizard="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AssignTeam from "../../CreateCustomer/Tabs/AssignTeam/AssignTeam.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

import { ref, onMounted } from "vue";

let customerId: any = ref("");

onMounted(() => {
  const selectedCustomerId = localStorage.getItem("selectedCustomerId");
  if (selectedCustomerId) {
    customerId.value = parseInt(selectedCustomerId);
  }
});
</script>
