<template>
  <header class="login-header">
    <h2>{{ t("Login.MfaSetup_21").toUpperCase() }}</h2>
    <p v-if="!mfaMethodConfirmed">
      {{ t("Login.PleaseChooseYourMfaMethod_22") }}
    </p>
    <p v-else-if="mfaMethodConfirmed && selectedMfaMethod === 'qrCode'">
      {{ t("Login.PleaseScanQrCode_26") }}
    </p>
    <p v-else-if="mfaMethodConfirmed && selectedMfaMethod === 'sms'">
      {{ t("Login.PleaseEnterYourMobileNumber_29") }}
    </p>
  </header>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div class="method-selection-container" v-if="!mfaMethodConfirmed">
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="mfaSetup"
        id="mfa_qrCode"
        value="qrCode"
        v-model="selectedMfaMethod"
      />
      <label class="form-check-label" for="mfa_qrCode">
        {{ t("Login.QRCode_23") }}
      </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="mfaSetup"
        id="mfa_sms"
        value="sms"
        v-model="selectedMfaMethod"
      />
      <label class="form-check-label" for="mfa_sms">
        {{ t("Login.SendSMS_24") }}
      </label>
    </div>

    <button class="login-action-btn" @click="selectMethod">
      {{ t("Login.Next_25") }}
    </button>
  </div>

  <div
    class="qr-code-method"
    v-else-if="mfaMethodConfirmed && selectedMfaMethod === 'qrCode'"
  >
    <div class="qr-wrapper">
      <VueQrcode
        :value="qrCodeUrl"
        :options="{
          width: 250,
          height: 250,
          color: {
            dark: '#38678c',
          },
        }"
      />
    </div>

    <Form
      class="login-form"
      v-slot="{ errors, isSubmitting }"
      @submit="setupQrCodeMfa"
    >
      <div class="form-group">
        <label for="code" class="form-label">{{ t("Login.Code_12") }}</label>

        <Field
          id="code"
          name="code"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.code }"
          :placeholder="t('Login.EnterCodeHere_13')"
          :rules="codeRules"
        />

        <div class="invalid-feedback">{{ errors.code }}</div>
      </div>

      <button class="login-action-btn" :disabled="isSubmitting">
        {{ t("Login.Login_6") }}
      </button>
    </Form>
  </div>

  <div
    class="sms-method"
    v-else-if="mfaMethodConfirmed && selectedMfaMethod === 'sms'"
  >
    <Form
      class="login-form"
      v-slot="{ errors, isSubmitting }"
      @submit="setupMobileNumberMfa"
    >
      <div class="form-group">
        <label for="mobileNumber" class="form-label">
          {{ t("Login.MobileNumber_27") }}
        </label>

        <Field
          id="mobileNumber"
          name="mobileNumber"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.mobileNumber }"
          :placeholder="t('Login.EnterYourMobileNumberHere_28')"
          :rules="mobileNumberRules"
        />

        <div class="invalid-feedback">{{ errors.mobileNumber }}</div>
      </div>

      <button class="login-action-btn" :disabled="isSubmitting">
        {{ t("Login.Verify_14") }}
      </button>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/auth.store";
import { storeToRefs } from "pinia";
import { ref } from "vue";

import VueQrcode from "@chenfengyuan/vue-qrcode";
import parseMobile from "libphonenumber-js/mobile";

import { Form, Field } from "vee-validate";
import { string } from "yup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const codeRules = string()
  .required(t("Login.CodeIsRequired_32"))
  .matches(/^\d{6}$/, t("Login.CodeMustBeValid_33"));

const mobileNumberRules = string()
  .required(t("Login.MobileNumberIsRequired_37"))
  .test(
    "validate-mobile-number",
    t("Login.MobileNumberIsNotValid_38"),
    (value) => {
      if (value) {
        return (parseMobile(value as string) &&
          parseMobile(value as string)?.isValid()) as boolean;
      }

      return false;
    }
  );

const emit = defineEmits<{
  (e: "nextChallenge", nextChallengeType: string): void;
}>();

const { errorMessage, qrCodeUrl, nextChallenge } = storeToRefs(useAuthStore());
const { setupMfaMethod } = useAuthStore();

const selectedMfaMethod = ref("");
const mfaMethodConfirmed = ref(false);

const selectMethod = () => {
  if (selectedMfaMethod.value) {
    mfaMethodConfirmed.value = true;
  }
};

const setupQrCodeMfa = async (values: any) => {
  await setupMfaMethod("TOTP", { code: values.code });
};

const setupMobileNumberMfa = async (values: any) => {
  await setupMfaMethod("SMS", { mobileNumber: values.mobileNumber });

  emit("nextChallenge", nextChallenge.value);
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";

.form-check {
  margin-bottom: 2rem;

  .form-check-input:checked {
    background-color: #38678c;
    border-color: #38678c;
  }
}

.qr-wrapper {
  text-align: center;
}

.login-action-btn {
  width: 100%;
}
</style>
