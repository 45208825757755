<template>
  <div class="footer">© Inocu {{ new Date().getFullYear() }}</div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #dcdcdd;
  padding: 1rem 1.6rem;
  color: #38678c;
  font-size: 0.8rem;
}
</style>
