import { defineStore } from "pinia";
import axios from "axios";
import type { BankAccount } from "@/_helpers/interfaces/bank-accounts/bank-account.interface";
import type { BankAccountsListPaginationOptions } from "@/_helpers/interfaces/bank-accounts/bank-accounts-pagination-options.interface";
import type { ExportingBankAccountsOptions } from "@/_helpers/interfaces/bank-accounts/exporting-bank-accounts-options.interface";
import type { ItemsListResponse } from "@/_helpers/interfaces/listing/items-list-response.interface";
import type { BankAccountInList } from "@/_helpers/interfaces/bank-accounts/bank-account-in-list.interface";
import { number } from "yup";

export const useBankAccountStore = defineStore("bank-account", () => {
  const createBankAccount = async (
    bankAccountData: BankAccount
  ): Promise<void> => {
    try {
      await axios.post(`/orders/bank_accounts/`, bankAccountData);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getBankAccountsList = async (
    options: BankAccountsListPaginationOptions
  ): Promise<ItemsListResponse<BankAccountInList>> => {
    try {
      const { data } = await axios.get(`/orders/bank_accounts/`, {
        params: options,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const deleteBankAccount = async (bankAccountId: number): Promise<void> => {
    try {
      await axios.delete(`/orders/bank_accounts/${bankAccountId}/`);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const bulkDeleteBankAccounts = async (
    bankAccountsIds: number[]
  ): Promise<void> => {
    try {
      await axios.post(`/orders/bank_accounts/bulk_delete/`, {
        bank_accounts: bankAccountsIds,
      });
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const exportBankAccountsList = async (
    exportedFileFormat: "csv" | "xlsx",
    options?: { export_type: string; customer: string | null | number }
  ): Promise<string> => {
    try {
      const { data } = await axios.get(`/orders/bank_accounts/export/`, {
        params: {
          export_format: exportedFileFormat,
          ...options,
        },
      });

      return data.file;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getImportCustomerBankAccountsTemplates = async () => {
    try {
      const { data } = await axios.get(
        `/orders/bank_accounts/customer_import_templates/`
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getImportSupplierBankAccountsTemplates = async () => {
    try {
      const { data } = await axios.get(
        `/orders/bank_accounts/supplier_import_templates/`
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const uploadBankAccountsFile = async (
    bankAccountsFile: File,
    import_type: string,
    customer?: string | number | null
  ) => {
    const formData = new FormData();
    formData.append("file", bankAccountsFile);
    formData.append("import_type", import_type);
    if (customer) {
      formData.append("customer", customer.toString());
    }

    try {
      const { data } = await axios.post(
        `/orders/bank_accounts/bulk_import/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const editBankAccount = async (
    bankAccountId: number,
    bankAccountData: BankAccount
  ): Promise<void> => {
    try {
      await axios.patch(
        `/orders/bank_accounts/${bankAccountId}/`,
        bankAccountData
      );
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getBankAccountById = async (
    bankAccountId: number
  ): Promise<BankAccount> => {
    try {
      const { data } = await axios.get(
        `/orders/bank_accounts/${bankAccountId}/`
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return {
    createBankAccount,
    getBankAccountsList,
    deleteBankAccount,
    bulkDeleteBankAccounts,
    exportBankAccountsList,
    getImportSupplierBankAccountsTemplates,
    getImportCustomerBankAccountsTemplates,
    uploadBankAccountsFile,
    editBankAccount,
    getBankAccountById,
  };
});
