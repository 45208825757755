import type { UserCustomerAssignment } from "./user-customer-assignment.interface";

export interface CurrentUser {
  id: number;
  email: string;
  display_name: string;
  first_name: string;
  last_name: string;
  active: boolean;
  is_admin: boolean;
  vacation_start: string;
  vacation_end: string;
  profile_picture: string;
  signature: string;
  show_signature: boolean;
  customer_assignments: Array<UserCustomerAssignment>;
  customers_count: number;
  projects_count: number;
  user_role: Array<string>;
  email_notification_preference: "daily" | "never" | "immediate";
}

export const UserRoles: {
  ADMIN: string;
  PURCHASER: string;
  PAYER: string;
  ORDER_CLEARER: string;
  PAYMENT_CLEARER: string;
  APPROVER: string;
  VIEWER: string;
} = {
  ADMIN: "Admin",
  PURCHASER: "Purchaser",
  PAYER: "Payer",
  ORDER_CLEARER: "Order Clearer",
  PAYMENT_CLEARER: "Payment Clearer",
  APPROVER: "Approver",
  VIEWER: "Viewer",
};
