<template>
  <div class="related-orders-container">
    <div class="related-orders-title">
      {{ t("Order.RelatedOrders_150") }}
    </div>
    <div class="related-orders-container--divider"></div>

    <div
      class="related-orders-title related-orders-subtitle"
      v-if="upcomingOrders.length"
    >
      {{ t("Order.UpcomingOrders_151") }}
    </div>
    <div>
      <table class="related-orders-table">
        <tbody>
          <tr v-for="relatedOrder in upcomingOrders" :key="relatedOrder?.id">
            <td>
              <div :class="getOrderIndicatorClasses(relatedOrder)"></div>
              <span style="margin-left: 8px; color: #aaaaaa">
                <router-link
                  :to="{ name: 'order', params: { id: relatedOrder.id } }"
                >
                  {{ relatedOrder?.order_number }}
                </router-link>
              </span>
            </td>
            <td>
              <div style="color: #aaaaaa">
                {{ relatedOrder?.service_date }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="related-orders-title related-orders-subtitle"
      v-if="pastOrders.length"
    >
      {{ t("Order.PastOrders_152") }}
    </div>
    <div>
      <table class="related-orders-table">
        <tbody>
          <tr v-for="relatedOrder in pastOrders" :key="relatedOrder.id">
            <td>
              <div :class="getOrderIndicatorClasses(relatedOrder)"></div>
              <span style="margin-left: 8px; color: #aaaaaa">
                <router-link
                  :to="{ name: 'order', params: { id: relatedOrder.id } }"
                >
                  {{ relatedOrder?.order_number }}
                </router-link>
              </span>
            </td>
            <td>
              <div style="color: #aaaaaa">
                {{ relatedOrder?.service_date }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { getRecurringInstructionChildren } = useRecurringInstructionStore();

const props = defineProps({
  order: {
    type: Object,
    default: () => {},
  },
  recurringInstructionId: {
    type: Number,
    default: null,
  },
});

const upcomingOrders: any = ref([]);

const pastOrders: any = ref([]);

const loadOrders = async (parentId: Number) => {
  const response = await getRecurringInstructionChildren(parentId);
  if (props.recurringInstructionId) {
    upcomingOrders.value = response;
  } else {
    pastOrders.value = response.filter(
      (order: any) =>
        order.order_number_without_prefix <
        props.order.order_number_without_prefix
    );
    upcomingOrders.value = response.filter(
      (order: any) =>
        order.order_number_without_prefix >
        props.order.order_number_without_prefix
    );
  }
};

const getOrderIndicatorClasses = (order: any) => {
  return [
    "status-indicator",
    `status-indicator--${order?.status_display.toLowerCase()}`,
  ];
};

onMounted(() => {
  let parentId = props.recurringInstructionId
    ? props.recurringInstructionId
    : props.order.recurring_instruction;
  loadOrders(parentId);
});

watch(
  () => props.order,
  () => {
    let parentId = props.recurringInstructionId
      ? props.recurringInstructionId
      : props.order.recurring_instruction;
    loadOrders(parentId);
  }
);
</script>
