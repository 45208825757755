<template>
  <InocuDataTable
    v-model:server-options="serverOptions"
    :server-items-length="paymentsListTotalCount"
    :loading="loading"
    :headers="paymentsTableHeaders"
    :items="paymentsList"
    :rows-items="[10, 25, 50, 100]"
    theme-color="#727cf3"
    table-class-name="listing-custom-table mb-5"
    alternating
    buttons-pagination
    no-hover
    must-sort
  >
    <template #item-field_name="order">
      {{ order.field.field_name }}
    </template>

    <template #item-type="order">
      {{ order.is_mandatory ? "Mandatory" : "Optional" }}
    </template>

    <!-- No Data View -->
    <template #empty-message>
      {{ t("CustomerManagement.NoFieldsToShow_146") }}...
    </template>
  </InocuDataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
import { useCustomerStore } from "@/stores/customer.store";
const { getFormPaymentById } = useCustomerStore();

const props = defineProps({
  CustomerId: Number,
});

const paymentsTableHeaders: Ref<Header[]> = ref([
  { text: "Field Name", value: "field_name", width: 250 },
  { text: "Type", value: "type", width: 250 },
]);

const paymentsList: any = ref([]);
const loading = ref(false);
const paymentsListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "field_name",
  sortType: "asc",
});

const getPaymentsTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getFormPaymentById({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    customer: props.CustomerId!,
    is_active: true,
  });

  paymentsList.value = [...response.results];
  paymentsListTotalCount.value = response.count;

  loading.value = false;
};

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getPaymentsTableList();
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  await getPaymentsTableList();
});
</script>
