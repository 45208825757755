import type { Notification } from "@/_helpers/interfaces/notifications/notification.interface";
import type { NotificationsListOptions } from "@/_helpers/interfaces/notifications/notifications-list-options.interface";
import type { NotificationsListResponse } from "@/_helpers/interfaces/notifications/notifications-list-response.interface";
import i18n from "@/_helpers/plugins/i18n";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";

export const useNotificationsStore = defineStore("notifications", () => {
  const $toast = useToast();
  // @ts-ignore
  const { t } = i18n.global;

  const notReadNotificationsCount = ref(0);
  const notificationsListInDropdown = ref<Notification[]>([]);
  const notificationsListInPage = ref<Notification[]>([]);

  const getNotificationsList = async (
    options: NotificationsListOptions
  ): Promise<NotificationsListResponse> => {
    try {
      const { data } = await axios.get(`/notifications/`, {
        params: options,
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const markAsRead = async (
    notificationId: number,
    showToast = true
  ): Promise<void> => {
    try {
      await axios.post(`/notifications/${notificationId}/update_read/`, {
        read: true,
      });
      await refreshNotificationsState(false);

      if (showToast) {
        $toast.open({
          message: t("Notifications.NotificationIsMarkedAsReadSuccessfully_5"),
          type: "success",
        });
      }
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const markAllAsRead = async (): Promise<void> => {
    try {
      await axios.post(`/notifications/update_all_read/`);
      await refreshNotificationsState(false);

      $toast.open({
        message: t(
          "Notifications.AllNotificationsAreMarkedAsReadSuccessfully_6"
        ),
        type: "success",
      });
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const refreshNotificationsState = async (read: boolean) => {
    const [
      { count: notReadCount },
      { results: pageList },
      { results: dropdownList },
    ] = await Promise.all([
      getNotificationsList({
        read: false,
      }),

      getNotificationsList({
        page: 1,
        page_size: -1,
        ordering: "-created-on",
        read: read,
      }),

      getNotificationsList({
        page: 1,
        page_size: 10,
        ordering: "-created-on",
        read: false,
      }),
    ]);

    notReadNotificationsCount.value = notReadCount;
    notificationsListInPage.value = [...pageList];
    notificationsListInDropdown.value = [...dropdownList];
  };

  return {
    notReadNotificationsCount,
    notificationsListInDropdown,
    notificationsListInPage,
    getNotificationsList,
    markAsRead,
    markAllAsRead,
    refreshNotificationsState,
  };
});
