<template>
  <div class="customer">
    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ t("Order.DeletePopupTitle_81") }}
          </h5>
          <div class="options">
            <button class="save" @click="onChangePaymentDelete">
              {{ t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Notes -->
    <div class="generic-popup" v-if="isNoteDeletePopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>{{ t("Order.DeletePopupTitle_81") }} ?</h5>
          <div class="options">
            <button class="save" @click="onChangeNoteDelete">
              {{ t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Self Notes Popup -->
    <div class="generic-popup" v-if="isNotesPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.AddSelfNote_129") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <form @submit.prevent="isEditNote ? editNote() : addSelfNote()">
            <div class="form-input m-0">
              <div class="textarea-wrapper">
                <textarea
                  v-model="selfNote"
                  class="border w-100 px-2 py-3 rounded"
                  :placeholder="t('Order.WriteYourNote_130')"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <button type="submit" class="btn btn-primary mt-3 ms-auto">
              {{ isEditNote ? t("Order.EditNote_131") : t("Order.AddNote_70") }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- Attachment Popup -->
    <div class="generic-popup" v-if="isAttachmentPopup">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.Attachment_67") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <div class="row">
            <div
              :class="
                payment.files.length === 1 || index < payment.files.length - 1
                  ? 'col-12'
                  : 'col-6 col-12'
              "
              v-for="(attachment, index) in payment.files"
              :key="index"
            >
              <embed
                v-if="attachment.file.includes('.pdf')"
                :src="attachment.file"
                type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                width="100%"
                height="700px"
              />
              <img
                v-else
                :src="attachment.file"
                alt="preview"
                style="width: 100%"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- View Attachment Popup -->
    <div class="generic-popup" v-if="isViewAttachmentPopup">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.Attachment_67") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body limited-height-popup-body">
          <div class="row">
            <div class="col-12">
              <embed
                v-if="viewAttachmentFile.file.includes('.pdf')"
                :src="viewAttachmentFile.file"
                type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                width="100%"
                height="700px"
              />
              <img
                v-else
                :src="viewAttachmentFile.file"
                alt="preview"
                style="width: 100%"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <h1 class="page-title">{{ t("Order.PaymentDetail_96") }}</h1>
    <!--  -->
    <div class="row">
      <!-- Left Side -->
      <div class="col-lg-7 col-12">
        <!-- Detail -->
        <div class="form-wrapper mb-4">
          <div class="generic-form">
            <div class="row mb-4">
              <div class="col-12">
                <!-- Order Bagde -->
                <span
                  class="order-badge mx-3"
                  :class="
                    payment.status_display === 'APPROVED' ||
                    payment.status_display === 'CLEARING'
                      ? 'approved-badge'
                      : payment.status_display === 'REJECTED'
                      ? 'reject-badge'
                      : payment.status_display === 'DRAFT'
                      ? 'draft-badge'
                      : payment.status_display === 'PENDING_APPROVAL'
                      ? 'pending-badge'
                      : ''
                  "
                >
                  {{ getPaymentStatusDisplay() }}
                </span>
                <!-- Action Buttons -->
                <button
                  class="mx-2 action-btn"
                  v-if="showEditPaymentBtn()"
                  @click="editPayment()"
                >
                  <i class="icon-edit me-2"></i>
                  <span> {{ t("CustomerManagement.Edit_66") }} </span>
                </button>
                <button
                  class="mx-3 action-btn"
                  @click="openDeletePopup"
                  v-if="
                    payment.status_display === 'DRAFT' &&
                    payment.released_by?.id === currentUser?.id
                  "
                >
                  <i class="icon-delete me-2"></i>
                  <span> {{ t("Order.Delete_63") }} </span>
                </button>
                <!-- Approve -->
                <button
                  class="mx-2 action-btn action-approve"
                  v-if="showApproveOrRejectAction()"
                  @click="approvedPayment(payment.id)"
                >
                  <i class="icon-document-approve me-2"></i>
                  <span> Approve </span>
                </button>
                <!-- Request Clarification -->
                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRequestClarificationAction()"
                  @click="RequestClarification(payment.id)"
                >
                  <i class="icon-document-clarification me-2"></i>
                  <span> Request Clarification </span>
                </button>

                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRespondToClarificationAction()"
                  @click="respondToClarification(payment.id)"
                >
                  <MazIcon
                    name="check-circle"
                    size="1.2rem"
                    path="/icons"
                    class="check-icon"
                  />
                  <span>
                    {{ t("Order.MarkClarificationCompleted_145") }}
                  </span>
                </button>

                <!-- Reject -->
                <button
                  class="ms-2 action-btn action-reject"
                  v-if="showApproveOrRejectAction()"
                  @click="rejectPayment(payment.id)"
                >
                  <i class="icon-document-reject me-2"></i>
                  <span> Reject </span>
                </button>
              </div>
            </div>
            <!-- Table -->
            <div class="row">
              <div class="col-12">
                <table class="order-detail-table">
                  <thead>
                    <tr>
                      <th>{{ t("Order.Description_108") }}</th>
                      <th>{{ t("Order.Value_109") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{{ t("Payments.PaymentNo_2") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.payment_number }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ t("Suppliers.Customer_53") }}</span>
                      </td>
                      <td>
                        <span>
                          {{ payment.customer ? payment.customer.name : "-" }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ t("Order.Project_4") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.project && payment.project.name !== ""
                              ? payment.project.name
                              : "-"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ t("Order.ProjectNo_146") }}</span>
                      </td>
                      <td>
                        <span>
                          {{ payment.project_no || "-" }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          t("Payments.RemainingProjectBalance_44")
                        }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.remaining_project_balance
                              ? new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(payment.remaining_project_balance)
                              : "-"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span> {{ t("Order.Supplier_31") }} </span>
                      </td>
                      <td>
                        <span> {{ payment.supplier?.name }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span> {{ t("Order.SupplierNo_35") }} </span>
                      </td>
                      <td>
                        <span>
                          {{ payment.supplier?.supplier_number || "-" }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.net_eur">
                      <td>
                        <span>{{ t("Order.NetEuro_37") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.net_eur
                              ? n(+payment.net_eur, "currency")
                              : "-"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ t("Order.GrossEuro_38") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.amount
                              ? n(+payment.amount, "currency")
                              : "-"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.name !== '' && payment.is_recurring">
                      <td>
                        <span>{{ t("Payments.PaymentName_12") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.name }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.frequency !== '' && payment.is_recurring">
                      <td>
                        <span>{{ t("Order.Frequency_17") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.frequency }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.end_after !== '' && payment.is_recurring">
                      <td>
                        <span>{{ t("Order.EndsAfter_22") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.end_after !== null ? payment.end_after : "0"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ t("Order.ServiceDate_30") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            moment(payment.service_date).format("DD.MM.YYYY")
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span> {{ t("Order.CreatedBy_140") }} </span>
                      </td>
                      <td>
                        <span>
                          {{
                            payment.created_by
                              ? payment.created_by.display_name
                              : "-"
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span> {{ t("Order.CreatedOn_139") }} </span>
                      </td>
                      <td>
                        <span>
                          {{ moment(payment.created_on).format("DD.MM.YYYY") }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.payment_date_list">
                      <td>
                        <span>{{ t("Payments.PaymentDate_4") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            moment(payment.payment_date_list).format(
                              "DD.MM.YYYY"
                            )
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.sequence_order_no">
                      <td>
                        <span>{{
                          t("Payments.PaymentSequenceOrderNo_14")
                        }}</span>
                      </td>
                      <td>
                        <span> {{ payment.sequence_order_no }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.payment_term">
                      <td>
                        <span>{{ t("Payments.PaymentTerm_25") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.payment_term }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.invoice_no">
                      <td>
                        <span>{{ t("Payments.PaymentInvoiceNo_15") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.invoice_no }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.invoice_date">
                      <td>
                        <span>{{ t("Payments.PaymentInvoiceDate_16") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            moment(payment.invoice_date).format("DD.MM.YYYY")
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.due_date">
                      <td>
                        <span>{{ t("Payments.PaymentDueDate_3") }}</span>
                      </td>
                      <td>
                        <span>
                          {{ moment(payment.due_date).format("DD.MM.YYYY") }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.goods_receipt">
                      <td>
                        <span>{{ t("Payments.PaymentGoodsReceipt_26") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            moment(payment.goods_receipt).format("DD.MM.YYYY")
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.comparison_gross_eur_order_list">
                      <td>
                        <span>{{
                          t("Payments.PaymentComparisonGrossEur_17")
                        }}</span>
                      </td>
                      <td>
                        <span>
                          {{ payment.comparison_gross_eur_order_list }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.discount">
                      <td>
                        <span>{{ t("Payments.PaymentDiscount_18") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.discount }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.gross_eur_less_discount">
                      <td>
                        <span>{{
                          t("Payments.PaymentDiscountGrossEur_19")
                        }}</span>
                      </td>
                      <td>
                        <span> {{ payment.gross_eur_less_discount }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.comment">
                      <td>
                        <span>{{ t("Order.Comment_120") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.comment }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.payment_date">
                      <td>
                        <span>{{ t("Payments.PaymentTerm_25") }}</span>
                      </td>
                      <td>
                        <span>
                          {{
                            moment(payment.PaymentDate_4).format("DD.MM.YYYY")
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="payment.comment_allea">
                      <td>
                        <span>{{ t("Order.CommentAllea_121") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.comment_allea }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.comment_management">
                      <td>
                        <span>{{ t("Order.CommentManagement_122") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.comment_management }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.comment_insv">
                      <td>
                        <span>{{ t("Order.CommentINSV_123") }}</span>
                      </td>
                      <td>
                        <span> {{ payment.comment_insv }} </span>
                      </td>
                    </tr>
                    <tr v-if="payment.masse_liability">
                      <td>
                        <span>
                          {{ t("Payments.PaymentMasseLiability_27") }}
                        </span>
                      </td>
                      <td>
                        <span> {{ payment.masse_liability }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {{ t("Payments.RemainingOrdersBalance_45") }}
                        </span>
                      </td>
                      <td>
                        {{
                          payment.remaining_order_balance
                            ? new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(payment.remaining_order_balance)
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr v-if="payment?.is_restrict">
                      <td>
                        <span> {{ t("Order.Cleared_by_149") }} </span>
                      </td>
                      <td>
                        <ul class="clearers-list">
                          <li
                            v-for="(clearer, index) in payment?.cleared_by"
                            :key="index"
                          >
                            <span class="clearer-badge mx-3" :key="clearer">
                              {{ clearer }}
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr v-if="payment.status == 'APPROVED'">
                      <td>
                        <span> {{ t("Order.ReleasedBy_148") }} </span>
                      </td>
                      <td>
                        <span> {{ payment?.released_by?.display_name }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Table -->
          </div>
        </div>
        <!-- Attachments -->
        <div class="form-wrapper mb-4">
          <div class="generic-form">
            <div class="row mb-4">
              <div class="col-lg-6">
                {{ t("Order.Attachment_67") }}
              </div>
              <div class="col-lg-6 text-end">
                <button class="button-gray" @click="openAttachmentPopup()">
                  <i class="icon-download"></i>
                  <span>{{ t("Order.CompareAttachments_68") }}</span>
                </button>
              </div>
            </div>
            <ul
              class="row"
              v-for="(attachment, index) in payment.files"
              :key="index"
            >
              <li
                class="col-lg-6 col-12"
                :class="index < payment.files.length - 1 ? '' : 'mb-2'"
              >
                <div class="attachment-item">
                  <div class="image">&#128206;</div>
                  <div class="content">
                    <div class="title">{{ attachment.file_name }}</div>
                    <div class="size">{{ attachment.file_size }}</div>
                  </div>
                  <div class="actions">
                    <button @click="viewAttachment(attachment)">
                      <i class="icon-eye"></i>
                    </button>
                    <a
                      :href="attachment.file"
                      target="_blank"
                      download
                      title="Download File"
                    >
                      <i class="icon-download"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Self Notes -->
        <div class="form-wrapper mb-4">
          <div class="generic-form">
            <div class="row mb-4">
              <div class="col-md-6">
                {{ t("Order.SelfNotes_69") }}
              </div>
              <div class="col-md-6 text-end">
                <button class="button-gray" @click="openSelfNotePopup()">
                  <i class="icon-print"></i>
                  <span> {{ t("Order.AddNote_70") }} </span>
                </button>
              </div>
            </div>
            <ul class="self-notes" v-if="selfNotes?.length > 0">
              <li v-for="(item, index) in selfNotes" :key="index">
                <div class="note">
                  <div class="header">
                    <div class="user">
                      <div class="image">
                        <img
                          :src="item.author.profile_picture"
                          alt="avatar"
                          class="rounded-circle"
                          v-if="item.author.profile_picture"
                        />
                        <img
                          src="/src/assets/images/user-avatar.jpg"
                          alt="avatar"
                          class="rounded-circle"
                          v-else
                        />
                      </div>
                      <div class="detail">
                        <div class="name">{{ item.author?.display_name }}</div>
                        <div class="date">
                          {{ moment(item.created_on).format("DD.MM.YYYY") }}
                        </div>
                      </div>
                    </div>
                    <div class="note-actions">
                      <button>
                        <i
                          class="icon-edit"
                          @click="OpenEditNotePopup(item)"
                        ></i>
                      </button>
                      <button>
                        <i class="icon-delete" @click="deleteNote(item)"></i>
                      </button>
                    </div>
                  </div>
                  <div class="body">
                    <p>
                      {{ item.text }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="text-center">
              <h5>No self note present</h5>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Side -->
      <div class="col-lg-5 col-12">
        <div class="form-wrapper">
          <div class="generic-form comment-box">
            <span> {{ t("Order.Comments_71") }} </span>
            <!-- Comments -->
            <ul class="comments" id="commentBox">
              <li
                class="comment"
                v-for="(item, index) in paymentComments"
                :key="index"
              >
                <div class="header">
                  <div class="user">
                    <div class="image">
                      <img
                        :src="item.author.profile_picture"
                        alt="avatar"
                        class="rounded-circle"
                        v-if="item.author.profile_picture"
                      />
                      <img
                        src="/src/assets/images/user-avatar.jpg"
                        alt="avatar"
                        class="rounded-circle"
                        v-else
                      />
                    </div>
                    <div class="name">{{ item.author.display_name }}</div>
                  </div>
                  <div class="date">
                    {{ moment(item.created_on).format("DD.MM.YYYY") }}
                  </div>
                </div>
                <div class="body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </li>
            </ul>
            <!-- Comments -->
            <!-- Post Comment -->
            <div
              class="post-comment-box"
              title="The comments is allowed in Pending and Request Clarification Status"
            >
              <div>
                <input
                  type="text"
                  v-model="comment"
                  @keypress.enter="addComment()"
                  :placeholder="t('Order.TypeYourMessageHere_128')"
                  :disabled="isCommentingDisabled(payment)"
                  :style="
                    isCommentingDisabled(payment) ? 'cursor: not-allowed;' : ''
                  "
                />
              </div>
              <button
                @click="addComment()"
                :disabled="isCommentingDisabled(payment)"
                :style="
                  isCommentingDisabled(payment) ? 'cursor: not-allowed;' : ''
                "
              >
                <i class="icon-arrow-right"></i>
                <span> {{ t("Global.Send_18") }} </span>
              </button>
            </div>
          </div>
        </div>
        <!--        related orders section-->
        <div class="related-orders-container" v-if="payment.orders.length">
          <div class="related-orders-title">
            {{ t("Order.RelatedOrders_150") }}
          </div>
          <div class="related-orders-container--divider"></div>
          <div class="accordion" id="relatedOrdersAccordion">
            <div
              class="accordion-item"
              v-for="(paymentOrder, index) in payment.orders"
              :key="index"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button bg-light text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse${index}`"
                  aria-expanded="true"
                  :aria-controls="`#collapse${index}`"
                >
                  <p class="mb-0">
                    {{ paymentOrder.order_number }}
                  </p>
                </button>
              </h2>
              <div
                :id="`collapse${index}`"
                :class="getItemClasses(index)"
                aria-labelledby="headingOne"
                data-bs-parent="#relatedOrdersAccordion"
              >
                <div class="accordion-body">
                  <div class="align-items-center justify-content-around">
                    <div class="row">
                      <div class="col-12">
                        <table class="order-detail-table">
                          <tbody>
                            <tr>
                              <td>
                                <span>{{ t("Payments.PaymentAmount_5") }}</span>
                              </td>
                              <td>
                                <span>
                                  {{
                                    new Intl.NumberFormat("de-DE", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(paymentOrder?.payment_amount || 0)
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>{{ t("Order.CurrentBalance_153") }}</span>
                              </td>
                              <td>
                                <span>
                                  {{
                                    new Intl.NumberFormat("de-DE", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(paymentOrder?.balance || 0)
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span>{{ t("Order.OrderAmount_161") }}</span>
                              </td>
                              <td>
                                <span>
                                  {{
                                    new Intl.NumberFormat("de-DE", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(paymentOrder?.order_amount || 0)
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="paymentOrder.status !== 'APPROVED'">
                              <td>
                                <span>{{
                                  t("Order.OrderAmountAfterPaymentApproval_162")
                                }}</span>
                              </td>
                              <td>
                                <span>
                                  {{
                                    new Intl.NumberFormat("de-DE", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(paymentOrder.amount_after_payment)
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="paymentOrder.status !== 'APPROVED'">
                              <td>
                                <span>{{
                                  t(
                                    "Order.OrderBalanceAfterPaymentApproval_163"
                                  )
                                }}</span>
                              </td>
                              <td>
                                <span>
                                  {{
                                    new Intl.NumberFormat("de-DE", {
                                      style: "currency",
                                      currency: "EUR",
                                    }).format(
                                      paymentOrder.balance_after_payment
                                    )
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        end related orders section-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import moment from "moment";
import { ref, onMounted, onUpdated, watch } from "vue";
import { useToast } from "vue-toast-notification";
import { usePaymentStore } from "@/stores/payment.store";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import MazIcon from "maz-ui/components/MazIcon";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();
const {
  getPaymentById,
  getPaymentNotes,
  createPaymentNotes,
  editPaymentNotes,
  deletePaymentNotes,
  getPaymentComments,
  createPaymentComments,
  deletePayment,
  paymentsBulkAction,
  respondToClarificationRequest,
} = usePaymentStore();

const toast = useToast();

const { currentUser } = storeToRefs(useUserStore());

let payment: any = ref({
  project: null,
  customer: null,
  amount: null,
  status: "PENDING_APPROVAL",
  status_display: "",
  payment_date_list: null,
  sequence_order_no: null,
  pay_no: null,
  order_type: null,
  internal_order_no: null,
  vendor_no: null,
  vendor: null,
  payment_term: null,
  invoice_no: null,
  invoice_date: null,
  due_date: null,
  goods_receipt: null,
  net_eur: null,
  gross_eur: null,
  comparison_gross_eur_order_list: null,
  discount: null,
  gross_eur_less_discount: null,
  comment: null,
  payment_date: null,
  comment_allea: null,
  comment_management: null,
  comment_insv: null,
  masse_liability: false,
  submit: false,
  document: null,
  url: null,
  orders: [],
  end_after: null,
  frequency: "",
  name: "",
  is_recurring: false,
  is_clarification_requested: false,
  requested_clarification_by_id: null,
  created_by_id: null,
});

const paymentId: any = ref(null);

const isPopup = ref(false);

const isAttachmentPopup = ref(false);

const isNoteDeletePopup = ref(false);

const isNotesPopup = ref(false);

const isEditNote = ref(false);

let selfNote: any = ref("");

let selfNotes: any = ref([]);

const noteId = ref(null);

let paymentComments: any = ref([]);

let comment: any = ref("");

const isLoadingNotes = ref(false);

const isViewAttachmentPopup = ref(false);

const viewAttachmentFile: any = ref({});

const getSelfNotes = async () => {
  isLoadingNotes.value = true;
  await getPaymentNotes(paymentId.value).then((response) => {
    isLoadingNotes.value = false;
    selfNotes.value = response.results;
  });
};

const getComments = async () => {
  const response = await getPaymentComments(paymentId.value);
  paymentComments.value = response.results;
};

const isCommentingDisabled = (payment: any) => {
  return ["DRAFT", "APPROVED", "REJECTED"].includes(payment.status);
};

const approvedPayment = async (id: any) => {
  let ids = [id];
  await paymentsBulkAction("bulk_approve", ids);
  payment.value.status = "APPROVED";
  toast.open({
    message: "Payment status has been changed to Approved",
    type: "success",
  });
};

const RequestClarification = async (id: any) => {
  let ids = [id];
  await paymentsBulkAction("bulk_request_clarification", ids);
  payment.value.is_clarification_requested = true;
  toast.open({
    message: "Clarification has been requested for the payment",
    type: "success",
  });
};

const respondToClarification = async (id: any) => {
  await respondToClarificationRequest(id);
  payment.value.is_clarification_requested = false;
  toast.open({
    message: "Clarification has been completed for the payment",
    type: "success",
  });
};

const rejectPayment = async (id: any) => {
  let ids = [id];
  await paymentsBulkAction("bulk_reject", ids);
  payment.value.status = "REJECTED";
  toast.open({
    message: "Payment has been changed to Rejected",
    type: "success",
  });
};

const closePopup = () => {
  isPopup.value = false;
  isNotesPopup.value = false;
  isNoteDeletePopup.value = false;
  isAttachmentPopup.value = false;
  isViewAttachmentPopup.value = false;
};

const openDeletePopup = () => {
  isPopup.value = true;
};

const openAttachmentPopup = () => {
  isAttachmentPopup.value = true;
};

const onChangePaymentDelete = async () => {
  closePopup();
  await deletePayment(payment.value.id);
  toast.open({
    message: `Payment deleted successfully.`,
    type: "success",
  });

  router.push({ name: "payments" });
};

const editPayment = () => {
  localStorage.setItem("isEditPayment", "true");
  router.push({ name: "create-payment", query: { payment: payment.value.id } });
};

const openSelfNotePopup = () => {
  isNotesPopup.value = true;
};
const addSelfNote = async () => {
  if (selfNote.value !== "") {
    let obj = {
      payment: paymentId.value,
      text: selfNote.value,
    };
    await createPaymentNotes(obj)
      .then(async () => {
        toast.open({
          message: `Self note created successfully.`,
          type: "success",
        });
        await getSelfNotes();
      })
      .catch((error) => console.log(error));
  }
  selfNote.value = "";
  isNotesPopup.value = false;
};

const OpenEditNotePopup = (note: any) => {
  isEditNote.value = true;
  isNotesPopup.value = true;
  selfNote.value = note.note;
  noteId.value = note.id;
};

const editNote = async () => {
  await editPaymentNotes(noteId.value, {
    payment: paymentId.value,
    text: selfNote.value,
  }).then(async () => {
    toast.open({
      message: `Self note edit successful.`,
      type: "success",
    });
    await getSelfNotes();
  });
  isNotesPopup.value = false;
  isEditNote.value = false;
  selfNote.value = "";
};

const onChangeNoteDelete = async () => {
  await deletePaymentNotes(noteId.value)
    .then(async () => {
      toast.open({
        message: `Self note deleted.`,
        type: "success",
      });
      closePopup();
      await getSelfNotes();
    })
    .catch((error) => console.log(error));
};

const deleteNote = (note: any) => {
  isNoteDeletePopup.value = true;
  noteId.value = note.id;
};

const addComment = async () => {
  if (comment.value !== "") {
    let obj = {
      payment: paymentId.value,
      text: comment.value,
    };
    await createPaymentComments(obj)
      .then(async () => {
        toast.open({
          message: `Comment Added.`,
          type: "success",
        });
        await getComments();
        comment.value = "";
        payment.value.status = "PENDING_APPROVAL";
      })
      .catch((error) => console.log(error));
  }
};

onUpdated(() => {
  const container = document.querySelector("#commentBox");
  if (container) {
    container.scrollIntoView({ behavior: "smooth" });
    container.scrollTop = container.scrollHeight;
  }
});

onMounted(async () => {
  paymentId.value = +router.currentRoute.value.params.id;
  /* Get Order from ID */
  payment.value = await getPaymentById(paymentId.value);
  /* Get Order Comments */
  await getComments();

  /* Get Order Self Notes */
  await getSelfNotes();
});

watch(
  () => router.currentRoute.value.params.id,
  async (newVal) => {
    if (newVal) payment.value = await getPaymentById(newVal);
  }
);

const viewAttachment = (attachment: any) => {
  isViewAttachmentPopup.value = true;
  viewAttachmentFile.value = attachment;
};

const getPaymentStatusDisplay = () => {
  if (payment.value.status_display === "APPROVED") {
    return "Approved";
  } else if (payment.value.status_display === "REJECTED") {
    return "Rejected";
  } else if (payment.value.status_display === "DRAFT") {
    return "Draft";
  } else if (payment.value.status_display === "PENDING_APPROVAL") {
    return "Pending";
  } else if (
    payment.value.status_display === "CLEARING" &&
    !payment.value.is_restrict
  ) {
    return "Clearing";
  } else if (
    payment.value.status_display === "CLEARING" &&
    payment.value.is_restrict
  ) {
    return `Clearing (${payment.value.total_clearer_approved || 0} / ${
      payment.value.total_clearer || 0
    })`;
  }
  return payment.value.status_display;
};

const showEditPaymentBtn = () => {
  return (
    (payment.value.status === "DRAFT" &&
      payment.value.released_by?.id === currentUser.value?.id) ||
    (payment.value.is_clarification_requested &&
      payment.value.created_by_id === currentUser.value?.id)
  );
};

const showApproveOrRejectAction = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return ["PENDING_APPROVAL", "CLEARING"].includes(
      payment.value.status_display
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return payment.value.status_display === "PENDING_APPROVAL";
  }
  if (currentUser.value?.user_role.includes("Payment Clearer")) {
    return payment.value.status_display === "PENDING_APPROVAL";
  }
  return false;
};

const showRequestClarificationAction = () => {
  if (
    currentUser.value?.user_role.includes("Admin") ||
    currentUser.value?.user_role.includes("Approver") ||
    currentUser.value?.user_role.includes("Order Clearer")
  ) {
    return (
      ["PENDING_APPROVAL", "CLEARING"].includes(payment.value.status_display) &&
      !payment.value.is_clarification_requested
    );
  }
  return false;
};

const showRespondToClarificationAction = () => {
  return (
    payment.value.is_clarification_requested &&
    (currentUser.value?.id === payment.value.created_by_id ||
      currentUser.value?.id === payment.value.requested_clarification_by_id)
  );
};

const getOrderIndicatorClasses = (order: any) => {
  return [
    "status-indicator",
    `status-indicator--${order?.status.toLowerCase()}`,
  ];
};
const getItemClasses = (index: number | string) => {
  return {
    "accordion-collapse": true,
    collapse: true,
    show: index === 0,
  };
};
</script>

<style scoped>
.order-detail-table {
  width: 100%;
}

.order-detail-table tr td:last-child,
.order-detail-table tr th:last-child {
  text-align: right;
}

.order-detail-table tr td,
.order-detail-table tr th {
  padding: 16px 12px;
}
.order-detail-table thead {
  background-color: #eff0f6;
  color: #3f4456;
}

.order-detail-table tr {
  font-size: 14px;
  line-height: 27px;
}

.order-detail-table tr {
  border-bottom: 1px solid #eee;
}

.order-detail-table tr:last-child {
  border-bottom: 0;
}

.order-badge {
  font-size: 14px;
  line-height: 28px;
  padding: 4px 8px;
  border-radius: 15px;
}

.approved-badge {
  background-color: #d7f6ec;
  color: #3dd5a5;
}

.reject-badge {
  background-color: #fde2e8;
  color: #f86584;
}

.draft-badge {
  background-color: #727cf3;
  color: #ffffff;
}

.action-btn {
  font-size: 15px;
  line-height: 28px;
  padding: 4px 8px;
  margin: 10px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.button-gray {
  font-size: 16px;
  line-height: 27px;
  color: #6d7287;
  background-color: #eef2f7;
  border-radius: 3px;
  padding: 6px 11px;
}
.button-gray i {
  margin-right: 10px;
}

.attachment-item {
  border: 1px solid #eff0f6;
  border-radius: 3px;
  width: 100%;
  padding: 12px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.attachment-item .image {
  width: 49px;
  height: 49px;
  border: 1px solid #eff0f6;
}

.attachment-item .content {
  width: calc(100% - 140px);
}

.attachment-item .content .title,
.attachment-item .content .size {
  font-size: 15px;
  line-height: 27px;
  color: #6d757d;
}

.attachment-item .actions {
  width: 60px;
  display: flex;
  justify-content: space-around;
}

.self-notes {
  margin: 0;
}
.self-notes .note {
  background-color: #eef2f7;
  border-radius: 5px;
  padding: 15px 20px;
}

.self-notes .note .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.self-notes .note .user {
  width: calc(100% - 60px);
  display: flex;
  align-items: flex-start;
}

.self-notes .note .user .image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #727cf3;
  margin-right: 10px;
}

.self-notes .note .user .detail .name {
  font-size: 16px;
  line-height: 28px;
}

.self-notes .note .user .detail .date {
  font-size: 14px;
  line-height: 1;
}

.self-notes .note .body {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.3;
}

.self-notes .note-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.self-notes li {
  margin-bottom: 15px;
}
.self-notes li:last-child {
  margin-bottom: 0px;
}

ul.comments {
  margin: 20px 0 0 0;
}

ul.comments .comment:nth-child(odd) {
  background-color: #d6ddf5;
}

ul.comments .comment:nth-child(even) {
  background-color: #eff0f6;
}

ul.comments .comment {
  background-color: #6d7287;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
ul.comments .comment:last-child {
  margin-bottom: 0;
}

ul.comments .comment .header {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

ul.comments .comment .header .user {
  display: flex;
  align-items: center;
}

ul.comments .comment .header .user .image {
  width: 40px;
  height: 40px;
  background-color: #f86584;
  border-radius: 50%;
  margin-right: 10px;
}

ul.comments .comment .header .user .name,
ul.comments .comment .header .date {
  font-size: 16px;
  line-height: 27px;
}

ul.comments .comment .body {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.post-comment-box {
  margin: 15px -20px -30px -20px;
  background-color: #d7f6ec;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-comment-box div {
  width: calc(100% - 70px);
}

.post-comment-box input {
  padding: 3px 12px;
  width: 100%;
  font-size: 16px;
}
.post-comment-box button {
  padding: 4px 10px;
  background-color: #09cf97;
  font-size: 14px;
  color: #ffffff;
}

ul.comments {
  min-height: 550px;
  max-height: 550px;
  overflow: auto;
}

.check-icon {
  color: #0dcaf0;
  margin-right: 8px;
}
</style>
