import { UserRoles } from "@/_helpers/interfaces";
import type { RolesDashboardStatistics } from "@/_helpers/interfaces/dashboards/roles-dashboard-statistics.interface";
import { useUserStore } from "@/stores/user.store";

export const useRolesDashboardCounts = () => {
  const { currentUser } = useUserStore();

  const getRolesStatisticsCounts = (
    statisticsData: RolesDashboardStatistics
  ) => {
    const statisticsCounts = {};

    currentUser?.user_role.forEach((role) => {
      switch (role) {
        case UserRoles.PURCHASER:
          statisticsCounts["totalOrders"] =
            statisticsData.order_counts.total_orders;
          statisticsCounts["pendingOrders"] =
            statisticsData.order_counts.pending_orders;
          statisticsCounts["totalAmountSpent"] =
            statisticsData.amounts.total_amount_spent;
          statisticsCounts["totalMaxLimit"] =
            statisticsData.amounts.total_max_limit;
          break;

        case UserRoles.ORDER_CLEARER:
          statisticsCounts["totalOrders"] =
            statisticsData.order_counts.total_orders;
          statisticsCounts["pendingOrders"] =
            statisticsData.order_counts.pending_orders;
          statisticsCounts["approvedOrders"] =
            statisticsData.order_counts.approved_orders;
          statisticsCounts["rejectedOrders"] =
            statisticsData.order_counts.rejected_orders;
          break;

        case UserRoles.PAYER:
          statisticsCounts["totalPayments"] =
            statisticsData.payment_counts.total_payments;
          statisticsCounts["pendingPayments"] =
            statisticsData.payment_counts.pending_payments;
          statisticsCounts["totalAmountSpent"] =
            statisticsData.amounts.total_amount_spent;
          statisticsCounts["totalMaxLimit"] =
            statisticsData.amounts.total_max_limit;
          break;

        case UserRoles.PAYMENT_CLEARER:
          statisticsCounts["totalPayments"] =
            statisticsData.payment_counts.total_payments;
          statisticsCounts["pendingPayments"] =
            statisticsData.payment_counts.pending_payments;
          statisticsCounts["approvedPayments"] =
            statisticsData.payment_counts.approved_payments;
          statisticsCounts["rejectedPayments"] =
            statisticsData.payment_counts.rejected_payments;
          break;

        case UserRoles.APPROVER:
          statisticsCounts["totalPayments"] =
            statisticsData.payment_counts.total_payments;
          statisticsCounts["pendingPayments"] =
            statisticsData.payment_counts.pending_payments;
          statisticsCounts["approvedPayments"] =
            statisticsData.payment_counts.approved_payments;
          statisticsCounts["rejectedPayments"] =
            statisticsData.payment_counts.rejected_payments;
          statisticsCounts["totalOrders"] =
            statisticsData.order_counts.total_orders;
          statisticsCounts["pendingOrders"] =
            statisticsData.order_counts.pending_orders;
          statisticsCounts["approvedOrders"] =
            statisticsData.order_counts.approved_orders;
          statisticsCounts["rejectedOrders"] =
            statisticsData.order_counts.rejected_orders;
          break;

        case UserRoles.VIEWER:
          statisticsCounts["totalPayments"] =
            statisticsData.payment_counts.total_payments;
          statisticsCounts["pendingPayments"] =
            statisticsData.payment_counts.pending_payments;
          statisticsCounts["approvedPayments"] =
            statisticsData.payment_counts.approved_payments;
          statisticsCounts["rejectedPayments"] =
            statisticsData.payment_counts.rejected_payments;
          statisticsCounts["totalOrders"] =
            statisticsData.order_counts.total_orders;
          statisticsCounts["pendingOrders"] =
            statisticsData.order_counts.pending_orders;
          statisticsCounts["approvedOrders"] =
            statisticsData.order_counts.approved_orders;
          statisticsCounts["rejectedOrders"] =
            statisticsData.order_counts.rejected_orders;
          break;
      }
    });

    return statisticsCounts;
  };

  const translationKeys = {
    totalOrders: "TotalOrders_12",
    pendingOrders: "PendingOrders_13",
    approvedOrders: "ApprovedOrders_18",
    rejectedOrders: "RejectedOrders_19",

    totalPayments: "TotalPayments_16",
    pendingPayments: "PendingPayments_17",
    approvedPayments: "ApprovedPayments_20",
    rejectedPayments: "RejectedPayments_21",

    totalAmountSpent: "TotalAmountSpent_14",
    totalMaxLimit: "TotalMaxLimit_15",
    totalOrdersAmount: "TotalOrdersAmount_22",
  };

  return { translationKeys, getRolesStatisticsCounts };
};
