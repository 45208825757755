<template>
  <div>
    <!-- View Attachment Popup -->
    <div class="generic-popup" v-if="attachmentPopupShown">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.Attachment_67") }}</h4>
          <span class="popup-close" @click="closePreviewPopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body limited-height-popup-body">
          <div class="row">
            <div class="col-12">
              <embed
                v-if="viewAttachmentFile.file.includes('.pdf')"
                :src="viewAttachmentFile.file"
                type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                width="100%"
                height="700px"
              />
              <img
                v-else
                :src="viewAttachmentFile.file"
                alt="preview"
                style="width: 100%"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrapper mb-4">
      <div class="generic-form">
        <div class="row mb-4">
          <div class="col-12">
            {{ t("Order.Attachment_67") }}
          </div>
        </div>
        <ul class="row m-0" v-if="order.files.length">
          <li
            class="col-12"
            v-for="(attachment, index) in order.files"
            :key="index"
          >
            <div class="attachment-item">
              <div
                class="image d-flex align-items-center justify-content-center"
              >
                &#128206;
                <!-- <img :src="attachment.file" alt="image"> -->
              </div>
              <div class="content">
                <div class="title">
                  {{ attachment.file_name ? attachment.file_name : "-" }}
                </div>
                <div class="size">
                  {{ attachment.file_size ? attachment.file_size : "-" }}
                </div>
              </div>
              <div class="actions">
                <button @click="viewAttachment(attachment)">
                  <i class="icon-eye"></i>
                </button>
                <a
                  :href="attachment.file"
                  target="_blank"
                  download
                  title="Download File"
                >
                  <i class="icon-download"></i>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <div class="text-center" v-else>
          <small>No Attachment Found</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps({
  order: {
    type: Object,
    default: () => {},
  },
});

const attachmentPopupShown = ref(false);

const viewAttachmentFile: any = ref({});

const viewAttachment = (attachment: any) => {
  attachmentPopupShown.value = true;
  viewAttachmentFile.value = attachment;
};

const closePreviewPopup = () => {
  attachmentPopupShown.value = false;
};
</script>
