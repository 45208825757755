<template>
  <header class="login-header">
    <h2>{{ t("Global.ResetPassword_3").toUpperCase() }}</h2>
    <p>{{ t("Login.PleaseTypeInYourNewPassword_19") }}</p>
  </header>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <Form
    class="login-form"
    :validation-schema="newPasswordSchema"
    v-slot="{ errors, isSubmitting }"
    @submit="onNewPasswordCreated"
  >
    <div class="form-group password-group">
      <label for="password" class="form-label">
        {{ t("Global.Password_2") }}
      </label>

      <div class="password-input-group">
        <Field
          id="password"
          name="password"
          :type="isPasswordShown ? 'text' : 'password'"
          class="form-control"
          :class="{ 'is-invalid': errors.password }"
          :placeholder="t('Global.EnterYourPasswordHere_4')"
        />

        <div class="invalid-feedback">{{ errors.password }}</div>

        <i
          v-if="!isPasswordShown"
          class="icon-eye password-icon"
          @click="togglePasswordVisibility"
        ></i>
        <i
          v-else
          class="icon-eye-slash password-icon"
          @click="togglePasswordVisibility"
        ></i>
      </div>
    </div>

    <div class="form-group confirm-password-group">
      <label for="confirmPassword" class="form-label">
        {{ t("Login.ConfirmPassword_20") }}
      </label>

      <div class="password-input-group">
        <Field
          id="confirmPassword"
          name="confirmPassword"
          :type="isConfirmPasswordShown ? 'text' : 'password'"
          class="form-control"
          :class="{ 'is-invalid': errors.confirmPassword }"
          :placeholder="t('Global.EnterYourPasswordHere_4')"
        />

        <div class="invalid-feedback">{{ errors.confirmPassword }}</div>

        <i
          v-if="!isConfirmPasswordShown"
          class="icon-eye password-icon"
          @click="toggleConfirmPasswordVisibility"
        >
        </i>
        <i
          v-else
          class="icon-eye-slash password-icon"
          @click="toggleConfirmPasswordVisibility"
        >
        </i>
      </div>
    </div>

    <button class="login-action-btn" :disabled="isSubmitting">
      {{ t("Global.ResetPassword_3") }}
    </button>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { storeToRefs } from "pinia";

import { Form, Field } from "vee-validate";
import { object, string, ref as YupRef } from "yup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const newPasswordSchema = object().shape({
  password: string()
    .required(t("Login.PleaseEnterYourPassword_39"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      t("Login.PasswordMustBe_40")
    ),
  confirmPassword: string()
    .required(t("Login.PleaseConfirmYourPassword_41"))
    .oneOf([YupRef("password"), null, ""], t("Login.PasswordMustMatch_42")),
});

const isPasswordShown = ref(false);
const isConfirmPasswordShown = ref(false);

const emit = defineEmits<{
  (e: "nextChallenge", nextChallengeType: string): void;
}>();

const { nextChallenge, errorMessage } = storeToRefs(useAuthStore());
const { createNewPassword } = useAuthStore();

const onNewPasswordCreated = async (values: any) => {
  await createNewPassword(values.password);

  emit("nextChallenge", nextChallenge.value);
};

const togglePasswordVisibility = () => {
  isPasswordShown.value = !isPasswordShown.value;
};
const toggleConfirmPasswordVisibility = () => {
  isConfirmPasswordShown.value = !isConfirmPasswordShown.value;
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";

.login-form {
  .form-group {
    &.password-group,
    &.confirm-password-group {
      margin-bottom: 1.5rem;

      .password-input-group {
        position: relative;

        .password-icon {
          position: absolute;
          top: 8px;
          right: 10px;

          cursor: pointer;

          &::before {
            color: #38678c;
          }
        }
      }
    }
  }
}
</style>
