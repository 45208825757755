<template>
  <div class="create-page">
    <h4 class="page-header">{{ t("Users.CreateUser_1") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ t("Users.UserData_2") }}</div>

      <form
        @submit="onSubmitUserForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="firstName" class="form-label">
              {{ t("Users.FirstName_3") }} <span class="text-danger">*</span>
            </label>
            <input
              v-model="firstName"
              id="firstName"
              name="firstName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.firstName }"
              :placeholder="t('Users.TypeNameHere_4') + '...'"
            />
            <div class="invalid-feedback">{{ errors.firstName }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="lastName" class="form-label">
              {{ t("Users.LastName_12") }} <span class="text-danger">*</span>
            </label>
            <input
              v-model="lastName"
              id="lastName"
              name="lastName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.lastName }"
              :placeholder="t('Users.TypeNameHere_4') + '...'"
            />
            <div class="invalid-feedback">{{ errors.lastName }}</div>
          </div>
        </div>

        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="userEmail" class="form-label">
              {{ t("Global.Email_8") }} <span class="text-danger">*</span>
            </label>
            <input
              v-model="userEmail"
              id="userEmail"
              name="userEmail"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.userEmail }"
              :placeholder="t('Users.TypeEmailHere_5') + '...'"
            />
            <div class="invalid-feedback">{{ errors.userEmail }}</div>
          </div>
        </div>

        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label class="form-label">{{ t("Users.Role_6") }}</label>
            <div class="form-check form-switch mb-2">
              <input
                v-model="isAdmin"
                id="isAdminCheckInput"
                class="form-check-input"
                type="checkbox"
                role="switch"
              />
              <label class="form-check-label" for="isAdminCheckInput">
                {{ t("Users.IsAdmin_46") }}
              </label>
            </div>
            <LegacyMultiSelect
              :disabled="isAdmin"
              v-model="userRoles"
              name="userRoles"
              mode="tags"
              :options="userRolesOptions"
              :placeholder="t('Users.SelectRolesRoles_8')"
              :closeOnSelect="false"
            />
          </div>
        </div>

        <div v-if="userCreatedSuccessfully != null" class="result-alert mt-4">
          <div
            v-if="userCreatedSuccessfully && !meta.dirty"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ t("Users.UserCreatedSuccessfully_9") }}
          </div>
          <div
            v-if="!userCreatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div class="create-item-footer mt-4">
          <button
            type="submit"
            class="btn btn-primary add-user me-2"
            :disabled="isSubmitting"
          >
            {{ t("Users.AddUser_10") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary discard-user"
            @click="backToUsersList"
            :disabled="isSubmitting"
          >
            {{ t("Users.Discard_11") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UserRoles } from "@/_helpers/interfaces/users/current-user.interface";
import { useUserStore } from "@/stores/user.store";
import { ref, type Ref, onBeforeMount } from "vue";
import router from "@/router";

import { useField, useForm } from "vee-validate";
import { array, boolean, object, string } from "yup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const userRolesOptions: any = { ...UserRoles };
delete userRolesOptions.ADMIN; // Admin assignation is a switcher

const validationSchema = object({
  firstName: string().required().label("First name"),
  lastName: string().required().label("Last name"),
  userEmail: string().required().email().label("User email"),
  isAdmin: boolean(),
  userRoles: array().of(string()),
});

const { errors, handleSubmit, isSubmitting, meta, resetForm } = useForm({
  validationSchema,
});
const { value: firstName } = useField("firstName");
const { value: lastName } = useField("lastName");
const { value: userEmail } = useField("userEmail");
const { value: isAdmin } = useField("isAdmin");
const { value: userRoles } = useField("userRoles", undefined, {
  initialValue: [],
});
const selectedCustomer = ref<number | null>(null);

const getAddUserPayLoad = (formValues: any) => {
  const payload: any = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    email: formValues.userEmail,
    is_admin: formValues.isAdmin,
  };

  if (formValues.userRoles.length && !formValues.isAdmin) {
    payload.roles = [...formValues.userRoles];
  }

  if (!isAdmin.value) {
    payload.customer = selectedCustomer.value;
  }
  return payload;
};

const userCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
const onSubmitUserForm = handleSubmit(async (values) => {
  try {
    const addUserPayload = getAddUserPayLoad(values);
    await addUser(addUserPayload);

    userCreatedSuccessfully.value = true;

    resetForm();
  } catch (error: any) {
    userCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

const { addUser } = useUserStore();

const backToUsersList = () => {
  router.push({ name: "list-users" });
};

onBeforeMount(() => {
  const savedCustomerId = localStorage.getItem("selectedCustomerId");
  if (savedCustomerId) {
    selectedCustomer.value = parseInt(savedCustomerId);
    console.log(selectedCustomer.value);
  }
});
</script>

<style scoped lang="scss">
.create-page {
  .create-page-content {
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .item-data-header {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .form-label {
    font-weight: 500;
  }

  .form-control,
  .form-select {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .create-item-footer {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
