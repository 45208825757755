import { useI18n } from "vue-i18n";

import { object, string } from "yup";

import { useField, useForm } from "vee-validate";
import router from "@/router";

export const useBankAccountForm = () => {
  const { t } = useI18n();

  const validationSchema = object({
    ownerName: string()
      .required()
      .max(100)
      .label(t("Suppliers.AccountOwner_44")),
    bankName: string().required().max(100).label(t("Suppliers.BankName_45")),
    swiftCode: string().required().max(50).label(t("Suppliers.SwiftCode_46")),
    iban: string().required().max(50).label(t("Suppliers.IBAN_48")),
    associatedPersona: string().required(
      t("Suppliers.YouHaveToSelectOneOfThem_57")
    ),
    customerId: string()
      .required()
      .label("Customer")
      .typeError(t("Suppliers.CustomerRequired_82")),
    supplierId: string()
      .nullable()
      .label("Supplier")
      .when("associatedPersona", (associatedPersona, field) => {
        return associatedPersona === "supplier" ? field.required() : field;
      }),
  });

  const {
    errors,
    handleSubmit,
    isSubmitting,
    meta,
    resetForm,
    setValues,
    resetField,
    setFieldValue,
  } = useForm({
    validationSchema,
  });
  const { value: ownerName } = useField("ownerName");
  const { value: bankName } = useField("bankName");
  const { value: swiftCode } = useField("swiftCode");
  const { value: iban } = useField("iban");
  const { value: associatedPersona } = useField("associatedPersona");
  const { value: customerId } = useField("customerId");
  const { value: supplierId } = useField("supplierId");

  const backToBankAccountsList = () => {
    router.push({ name: "list-bank-accounts" });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
    meta,
    resetForm,
    setValues,
    ownerName,
    bankName,
    swiftCode,
    iban,
    associatedPersona,
    customerId,
    supplierId,
    backToBankAccountsList,
    resetField,
    setFieldValue,
  };
};
