import { defineStore } from "pinia";
import axios from "axios";
import type { Supplier } from "@/_helpers/interfaces/suppliers/supplier.interface";
import type { ExportingSuppliersOptions } from "@/_helpers/interfaces/suppliers/exporting-suppliers-options.interface";
import type { SuppliersListPaginationOptions } from "@/_helpers/interfaces/suppliers/suppliers-pagination-options.interface";
import type { ItemsListResponse } from "@/_helpers/interfaces/listing/items-list-response.interface";
import type { SupplierInList } from "@/_helpers/interfaces/suppliers/supplier-in-list.interface";

export const useSupplierStore = defineStore("supplier", () => {
  const createSupplier = async (supplierData: Supplier): Promise<void> => {
    try {
      const { data } = await axios.post(`/orders/suppliers/`, supplierData);
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getSuppliersList = async (
    options: SuppliersListPaginationOptions
  ): Promise<ItemsListResponse<SupplierInList>> => {
    try {
      const { data } = await axios.get(`/orders/suppliers/`, {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const deleteSupplier = async (supplierId: number): Promise<void> => {
    try {
      await axios.delete(`/orders/suppliers/${supplierId}/`);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const bulkDeleteSuppliers = async (suppliersIds: number[]): Promise<void> => {
    try {
      await axios.post(`/orders/suppliers/bulk_delete/`, {
        suppliers: suppliersIds,
      });
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getSupplierById = async (supplierId: number): Promise<Supplier> => {
    try {
      const { data } = await axios.get(`/orders/suppliers/${supplierId}/`);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const editSupplier = async (
    supplierId: number,
    supplierData: Supplier
  ): Promise<void> => {
    try {
      await axios.patch(`/orders/suppliers/${supplierId}/`, supplierData);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const exportSuppliersList = async (
    exportedFileFormat: "csv" | "xlsx",
    options?: ExportingSuppliersOptions
  ): Promise<string> => {
    try {
      const { data } = await axios.get(`/orders/suppliers/export/`, {
        params: {
          export_format: exportedFileFormat,
          ...options,
        },
      });

      return data.file;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getImportSuppliersTemplates = async () => {
    try {
      const { data } = await axios.get(`/orders/suppliers/import_templates/`);

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const uploadSuppliersFile = async (
    suppliersFile: File,
    customerId: number | string | null
  ) => {
    const formData = new FormData();
    if (customerId) {
      formData.append("customer", customerId.toString());
    }
    formData.append("file", suppliersFile);
    try {
      const { data } = await axios.post(
        `/orders/suppliers/bulk_import/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return {
    createSupplier,
    getSuppliersList,
    deleteSupplier,
    bulkDeleteSuppliers,
    getSupplierById,
    editSupplier,
    exportSuppliersList,
    getImportSuppliersTemplates,
    uploadSuppliersFile,
  };
});
