<template>
  <div class="tw-flex tw-flex-row tw-space-x-8 tw-w-full">
    <StatisticsAreaChart
      :series="series"
      :xaxis="xaxis"
      :isLoading="isLoading"
      isMoney
    >
      <template #title>
        {{ t("Statistics.OrderCommitmentTrend_16") }}
      </template>
    </StatisticsAreaChart>

    <div class="tw-w-2/12 tw-flex tw-flex-col tw-gap-y-4">
      <StatisticsCard
        :title="t('Statistics.CurrentOrderCommitments_15')"
        value="4,84 M"
        :trendingValue="0"
      >
        <template #icon>
          <orderCommitmentIcon />
        </template>
        <template #value
          >{{ totalOrderCommitmentFormatted.value
          }}<span class="tw-text-sm">{{
            totalOrderCommitmentFormatted.unit
          }}</span>
        </template>
      </StatisticsCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import orderCommitmentIcon from "../icons/orderCommitmentIcon.svg";
import { ref, onMounted, computed, watch, type Ref } from "vue";
import StatisticsAreaChart from "../components/StatisticsAreaChart.vue";
import StatisticsCard from "../components/StatisticsCard.vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";

const { t } = useI18n();
const { getOrderCommitment } = useDashboardStore();
const { formatCurrency } = useFormatValue();

const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const isLoading = ref(true);

const totalOrderCommitment = ref(0);
const totalOrderCommitmentFormatted: Ref<any> = computed(() =>
  formatCurrency(totalOrderCommitment.value, true)
);

const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.OrderCommitmentTrend_16"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);

const fetchData = async () => {
  try {
    const data = await getOrderCommitment(
      props.startDate,
      props.endDate,
      props.customerId
    );
    series.value[0].data = data.historical_order_commitment as number[];
    xaxis.value = data.dates as string[];
    totalOrderCommitment.value = data.latest_order_commitment;
  } catch (error) {
    console.error("Error fetching order commitment:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchData);

watch(() => props, fetchData, { deep: true });
</script>
