<template>
  <div class="navbar">
    <div class="navbar__start">
      <i class="icon-menu pointer" @click="toggleSidebar"></i>
      <!-- <div class="navbar__search">
        <form>
          <input type="search" placeholder="search" />
          <button type="submit">Search</button>
        </form>
      </div> -->
    </div>
    <div class="navbar__end">
      <LegacyMultiSelect
        v-model="selectedCustomer"
        class="customer-dropdown"
        name="selectedCustomer"
        mode="single"
        :options="assignedCustomersList"
        :resolve-on-load="false"
        valueProp="customerId"
        label="customerName"
        :searchable="true"
        :placeholder="t('Users.ChooseCustomer_7')"
        :clearOnBlur="false"
        :can-clear="false"
        :can-deselect="false"
        @change="onSelectCustomer($event)"
      />

      <div class="dropdown language-dropdown ms-4 me-4">
        <button
          class="dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="icon-language"></i>
        </button>

        <ul class="dropdown-menu">
          <li class="dropdown-item" @click="changeLanguage('en')">en</li>

          <li class="dropdown-item" @click="changeLanguage('de')">de</li>
        </ul>
      </div>

      <div class="dropdown notifications-dropdown me-4">
        <button
          class="dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i
            class="icon-notification"
            :class="{
              'has-notifications': notReadNotificationsCount,
            }"
          ></i>
        </button>

        <ul class="dropdown-menu dropdown-menu-end">
          <li class="notifications-dropdown-header">
            <span>{{ t("Notifications.Notifications_1") }}</span>
            <a @click.stop="markAllAsRead()">
              {{ t("Notifications.MarkAllAsRead_2") }}
            </a>
          </li>

          <template v-if="notificationsListInDropdown.length">
            <li
              v-for="notification in notificationsListInDropdown.slice(0, 4)"
              :key="notification.id"
              class="dropdown-item"
              @click="performNotificationAction(notification)"
            >
              <div class="notifications-dropdown-item">
                <div
                  class="notification-icon"
                  :class="{
                    'not-read': !notification.read,
                  }"
                >
                  <i class="icon-document"></i>
                </div>

                <div class="notification-info">
                  <span class="title" :title="notification.title">
                    {{ notification.title }}
                  </span>

                  <span class="content" :title="notification.text">
                    {{ notification.text }}
                  </span>
                </div>

                <div class="notification-action">
                  <span class="time">
                    {{ moment(notification.created_on).fromNow() }}
                  </span>

                  <div v-if="!notification.read" class="mark-as-read">
                    <a @click.stop="markAsRead(notification.id)">
                      {{ t("Notifications.MarkAsRead_3") }}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </template>

          <template v-else>
            <li>
              <span class="d-flex mt-2 mb-2 justify-content-center">
                {{ t("Notifications.NoNotifications_7") }}
              </span>
            </li>
          </template>

          <li class="notifications-dropdown-footer">
            <router-link :to="{ name: 'notification-center' }">
              {{ t("Notifications.ViewAll_4") }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="dropdown user-dropdown">
        <button
          class="dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            class="user-image"
            :src="
              currentUser?.profile_picture
                ? currentUser?.profile_picture
                : defaultProfileAvatar
            "
            alt="user image"
          />

          <div class="user-info">
            <span class="user-name">{{ currentUser?.display_name }}</span>
            <span class="user-role" :title="currentUser?.user_role.join(', ')">
              {{ currentUser?.user_role.join(", ") }}
            </span>
          </div>
        </button>

        <ul class="dropdown-menu">
          <li>
            <router-link :to="{ name: 'my-profile' }" class="dropdown-item">
              <i class="icon-my-profile"></i>
              {{ t("Global.MyProfile_1") }}
            </router-link>
          </li>

          <li class="dropdown-item" @click="logout">
            <i class="icon-logout"></i>
            {{ t("UserProfile.Logout_1") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultProfileAvatar from "@/assets/images/user-avatar.jpg";

import { useAuthStore } from "@/stores/auth.store";
import { useUserStore } from "@/stores/user.store";
import { useCustomerStore } from "@/stores/customer.store";
import { onMounted, ref, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { UserRoles } from "@/_helpers/interfaces";
import router from "@/router";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/stores/notifications.store";
import moment from "moment";
import type { Notification } from "@/_helpers/interfaces/notifications/notification.interface";

const { t, locale } = useI18n();

const { logout } = useAuthStore();
const { currentUser } = storeToRefs(useUserStore());
const { getAssignedCustomersWithRolesList } = useUserStore();
const { getCustomersList } = useCustomerStore();

const emit = defineEmits(["toggle-sidebar"]);

const assignedCustomersList: Ref<Array<{
  customerId: number;
  customerName: string;
  roles: string[];
}> | null> = ref(null);
const selectedCustomer: Ref<number | null> = ref(null);

const { notReadNotificationsCount, notificationsListInDropdown } = storeToRefs(
  useNotificationsStore()
);
const { markAsRead, markAllAsRead, getNotificationsList } =
  useNotificationsStore();

onMounted(async () => {
  if (
    currentUser.value?.user_role.length &&
    !currentUser.value?.user_role.includes(UserRoles.ADMIN)
  ) {
    assignedCustomersList.value = getAssignedCustomersWithRolesList(
      currentUser.value
    );
    selectedCustomer.value = +localStorage.getItem("selectedCustomerId")!;
  } else if (currentUser.value?.user_role.includes(UserRoles.ADMIN)) {
    const resp = await getCustomersList({
      page_size: 100,
    });
    assignedCustomersList.value = resp.results.map((customer) => ({
      customerId: customer.id,
      customerName: customer.name,
      roles: ["ADMIN"],
    }));
  }
  if (localStorage.getItem("selectedCustomerId")) {
    selectedCustomer.value = +localStorage.getItem("selectedCustomerId")!;
  } else if (assignedCustomersList.value) {
    selectedCustomer.value = assignedCustomersList.value[0].customerId;
    localStorage.setItem("selectedCustomerId", `${selectedCustomer.value}`);
    location.reload();
  }

  await getNotificationsNavbarList();

  await getNotReadNotificationsCount();
});

const getNotificationsNavbarList = async () => {
  const response = await getNotificationsList({
    page: 1,
    page_size: 5,
    ordering: "-created_on",
  });

  notificationsListInDropdown.value = [...response.results];
};

const getNotReadNotificationsCount = async () => {
  const { count } = await getNotificationsList({
    read: false,
  });

  notReadNotificationsCount.value = count;
};

const performNotificationAction = async (notification: Notification) => {
  markAsRead(notification.id, false);

  const routeName =
    notification.object_ct === "purchaseorder"
      ? "order"
      : notification.object_ct === "payment"
      ? "payment"
      : "";

  if (routeName)
    router.push({
      name: routeName,
      params: {
        id: notification.object_id,
      },
    });
  else
    router.push({
      name: "notification-center",
    });
};

const onSelectCustomer = (customerId: any) => {
  if (customerId) {
    localStorage.setItem("selectedCustomerId", `${customerId}`);
    location.reload();
  }
};

const changeLanguage = (lang: string) => {
  if (locale.value === lang) return;

  const currentRoute = router.currentRoute.value;

  router.push({
    ...currentRoute,
    params: {
      ...currentRoute.params,
      locale: lang,
    },
  });

  // Refresh the page to get current data from backend with current language
  setTimeout(() => {
    location.reload();
  }, 0);
};

const toggleSidebar = () => {
  emit("toggle-sidebar");
};
</script>

<style scoped lang="scss">
@import "./navbar.scss";
</style>
