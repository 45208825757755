import { defineStore } from "pinia";
import axios from "axios";

export const useSepaStore = defineStore("sepa", () => {
  const getSepaPayments = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get("/payments/sepa/", {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  /* Create Sepa Payments */
  const createSepaPayment = async (sepa: any): Promise<any> => {
    try {
      const { data } = await axios.post(`/payments/sepa/`, sepa);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  /* Export Sepa Payment */
  const exportSepaPayments = async (
    ids: any,
    format: "pdf" | "xml"
  ): Promise<any> => {
    try {
      const response = await axios.post(`/payments/sepa/export/`, {
        sepa_transfer_ids: ids,
        export_format: format,
      });
      return response.data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };
  return {
    getSepaPayments,
    createSepaPayment,
    exportSepaPayments,
  };
});
