import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";

export const useOrderStore = defineStore("order", () => {
  const OrdersOptions: any = ref({
    ordering: "",
    page: "",
    page_size: "",
    project: "",
    customer: "",
    created_after: "",
    created_before: "",
    status: "",
    order_number: "",
    clarification_requested_only: false,
    supplier_name: "",
    document_no_internal: "",
    project_no: "",
    current_balance_max: "",
    current_balance_min: "",
    amount_max: "",
    amount_min: "",
    created_on_after: "",
    created_on_before: "",
    created_by: "",
    released_by: "",
  });
  // Post an Order
  const createOrder = async (orders: any): Promise<any> => {
    try {
      const { data } = await axios.post(`/orders/purchase_orders/`, orders);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  const createOrderFile = async (payload: any): Promise<any> => {
    const formData = new FormData();
    formData.append("order", payload.order);
    formData.append("file", payload.file);
    formData.append("file_name", payload.file_name);
    formData.append("file_size", payload.file_size);
    await axios.post(`/orders/order_files/`, formData);
  };

  const deleteOrderFile = async (id: number): Promise<any> => {
    await axios.delete(`/orders/order_files/${id}/`);
  };
  // Get Main Data in Create Customer By Id
  const getOrders = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get("/orders/purchase_orders/", {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  // Get order from Orders Listing By Id
  const getOrderById = async (id: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/orders/purchase_orders/${id}/`);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const patchOrder = async (id: number, data: any): Promise<any> => {
    try {
      return await axios.patch(`/orders/purchase_orders/${id}/`, data);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Edit an Order
  const editOrder = async (id: number, order: any): Promise<any> => {
    try {
      return await axios.put(`/orders/purchase_orders/${id}/`, order);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // respond to a clarification request, to clear the clarification requested flag
  const respondToClarificationRequest = async (id: number): Promise<any> => {
    try {
      return await axios.patch(
        `/orders/purchase_orders/${id}/respond_to_clarification_request/`
      );
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  // Delete Order from Order listing
  const deleteOrder = async (id: any): Promise<any> => {
    try {
      await axios.delete(`/orders/purchase_orders/${id}`);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };
  /* Order Bulk Action => Approve, Reject, Request Clarification, Delete */
  const ordersBulkAction = async (action: string, ids: any): Promise<any> => {
    try {
      await axios.post(`/orders/purchase_orders/${action}/`, {
        ids: ids,
      });
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };
  /* Get Order Notes */
  const getOrderNotes = async (id: number): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/orders/purchase_order_notes/?order=${id}`
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Order Notes */
  const createOrderNotes = async (payload: object): Promise<any> => {
    try {
      const { data } = await axios.post(
        "/orders/purchase_order_notes/",
        payload
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Edit Order Notes */
  const editOrderNotes = async (id: any, payload: object): Promise<any> => {
    try {
      const { data } = await axios.put(
        `/orders/purchase_order_notes/${id}/`,
        payload
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Delete Order Notes */
  const deleteOrderNotes = async (id: any): Promise<any> => {
    try {
      await axios.delete(`/orders/purchase_order_notes/${id}/`);
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Order Comments */
  const getOrderComments = async (id: number): Promise<any> => {
    try {
      const { data } = await axios.get(`/orders/order_comments/?order=${id}`);
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Order Comments */
  const createOrderComments = async (payload: object): Promise<any> => {
    try {
      const { data } = await axios.post("/orders/order_comments/", payload);
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Current Balance of Customer by ID */
  const getCustomerCurrentBalance = async (
    customerId: any,
    projectId: any
  ): Promise<any> => {
    try {
      const uri =
        projectId !== null
          ? `/orders/purchase_orders/current_balance/?customer=${customerId}&project=${projectId}`
          : `/orders/purchase_orders/current_balance/?customer=${customerId}`;
      const { data } = await axios.get(uri);
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Export Orders */
  const exportOrders = async (
    ids: any,
    exportFormat: string,
    fields: Array<string>
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `/orders/purchase_orders/bulk_export/`,
        {
          order_ids: ids,
          export_format: exportFormat,
          fields: fields,
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };

  // non api related
  const resetOptions = () => {
    OrdersOptions.value = {
      ordering: "",
      page: "",
      page_size: "",
      project: "",
      customer: "",
      created_after: "",
      created_before: "",
      status: "",
      order_number: "",
      clarification_requested_only: false,
      supplier_name: "",
      document_no_internal: "",
      project_no: "",
      current_balance_max: "",
      current_balance_min: "",
      amount_max: "",
      amount_min: "",
      created_by: "",
      released_by: "",
    };
    return OrdersOptions.value;
  };
  const setOptions = (options: any) => {
    OrdersOptions.value = options;
  };

  const filterCount = (): number => {
    let count = 0;
    for (const key in OrdersOptions.value) {
      // if the key is a date range, count as 1
      if (key.includes("before")) {
        // the after will be counted
        continue;
      }
      if (
        OrdersOptions.value[key] !== "" &&
        OrdersOptions.value[key] !== false
      ) {
        count += 1;
      }
    }
    return count;
  };
  return {
    createOrder,
    createOrderFile,
    deleteOrderFile,
    getOrders,
    getOrderById,
    editOrder,
    patchOrder,
    deleteOrder,
    ordersBulkAction,
    getOrderNotes,
    createOrderNotes,
    editOrderNotes,
    deleteOrderNotes,
    getOrderComments,
    createOrderComments,
    getCustomerCurrentBalance,
    exportOrders,
    respondToClarificationRequest,
    OrdersOptions,
    resetOptions,
    setOptions,
    filterCount,
  };
});
