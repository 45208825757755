<template>
  <div class="app">
    <SideBar :isCollapsed="isSidebarCollapsed" />
    <div class="main" :class="{ 'sidebar-collapsed': isSidebarCollapsed }">
      <HijackBanner v-if="isHijackSession" />
      <NavBar @toggle-sidebar="toggleSidebar" class="sticky-nav" />
      <div class="scrollable-content">
        <RouterView class="main-content" />
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";

import SideBar from "@/components/sidebar/SideBar.vue";
import NavBar from "@/components/navbar/NavBar.vue";
import AppFooter from "@/components/app-footer/AppFooter.vue";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import tokenService from "@/_helpers/services/token.service";
import { useNotificationsStore } from "@/stores/notifications.store";
import HijackBanner from "@/components/hijack-banner/HijackBanner.vue";

const { t } = useI18n();
const $toast = useToast();
const { refreshNotificationsState } = useNotificationsStore();

const isSidebarCollapsed = ref(false);
const isHijackSession = !!sessionStorage.getItem("is_hijack_session");

onMounted(() => {
  openNotificationsConnection();
});

const openNotificationsConnection = () => {
  const wsUrl = import.meta.env.VITE_INOCU_WS_URL;
  if (!wsUrl) {
    $toast.open({
      message: t("Notifications.ErrorWithNotifications_8"),
      type: "error",
    });
    return;
  }
  const socket = new WebSocket(wsUrl);

  socket.onopen = () => {
    const tokenAsStr = JSON.stringify({
      token: tokenService.getAccessToken(),
    });
    socket.send(tokenAsStr);
  };

  socket.onmessage = async (event) => {
    const notificationData = JSON.parse(event.data);

    if (notificationData.type === "notification") {
      $toast.open({
        message: t("Notifications.YouHaveGotNewNotification_9"),
        type: "info",
      });

      await refreshNotificationsState(false);
    }
  };

  socket.onerror = () => {
    $toast.open({
      message: t("Notifications.ErrorWithNotifications_8"),
      type: "error",
    });
  };
};

const toggleSidebar = () => {
  isSidebarCollapsed.value = !isSidebarCollapsed.value;
};
</script>

<style scoped lang="scss">
.app {
  display: flex;
  height: 100vh; // Ensure the app takes full viewport height
  overflow: hidden; // Prevent scrolling on the app container

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f9fafd;
    margin-left: 260px;
    width: calc(100% - 260px);
    transition: margin-left 0.3s ease, width 0.3s ease;
    overflow: hidden; // Prevent scrolling on the main container

    &.sidebar-collapsed {
      margin-left: 80px;
      width: calc(100% - 80px);
    }

    .sticky-nav {
      position: sticky;
      top: 0;
      z-index: 1000;
      background-color: #ffffff;
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto; // Enable scrolling for the content
      display: flex;
      flex-direction: column;
    }

    .main-content {
      flex: 1;
      padding: 1.5rem;

      h4.page-header {
        color: #6d7287;
        font-size: 0.9rem;
        margin-bottom: 1.4rem;
      }
    }
  }
}
</style>
