<template>
  <div class="roles-dashboard-page">
    <h4 class="page-header">
      <span>{{ t("Dashboard.Dashboard_1") }}</span>

      <div class="date-range">
        <DateRange
          :initial-date="[startOfWeek(subWeeks(new Date(), 1)), new Date()]"
          @selectDate="changeDateRange($event)"
        />
      </div>
    </h4>

    <div class="statistics-card card mb-5">
      <div class="statistics-card-header">
        <div class="switching-statistics">
          <i
            class="icon-menu"
            :class="{
              selected: statisticsView === 'totalCounts',
            }"
            @click="statisticsView = 'totalCounts'"
          ></i>

          <i
            class="icon-statistics"
            :class="{
              selected: statisticsView === 'charts',
            }"
            @click="statisticsView = 'charts'"
          ></i>
        </div>
      </div>

      <div class="statistics-card-content">
        <template v-if="statisticsView === 'totalCounts'">
          <div class="statistical-counts">
            <template
              v-for="(statisticCount, property) in statisticsCountsProperties"
              :key="property"
            >
              <div
                v-if="statisticCount || statisticCount === 0"
                class="item-count"
              >
                <span class="title">
                  {{ t(`Dashboard.${translationKeys[property]}`) }}
                </span>

                <span class="count">
                  <template
                    v-if="(property as any).toLowerCase().includes('amount') ||(property as any).toLowerCase().includes('limit')"
                  >
                    {{ n(statisticCount, "currency") }}
                  </template>

                  <template v-else>
                    {{ statisticCount }}
                  </template>
                </span>
              </div>
            </template>
          </div>
        </template>

        <template v-if="statisticsView === 'charts'">
          <div class="statistical-charts row">
            <div
              class="orders-chart col-xl-5"
              v-if="
                currentUser?.user_role.includes(UserRoles.PURCHASER) ||
                currentUser?.user_role.includes(UserRoles.ORDER_CLEARER) ||
                currentUser?.user_role.includes(UserRoles.APPROVER) ||
                currentUser?.user_role.includes(UserRoles.VIEWER)
              "
            >
              <OrdersDonutChart
                v-if="Object.values(rolesDashboardStatistics?.order_counts as any).reduce(
                    (acc: any, curr: any) => acc + curr,
                    0
                  )"
                :ordersChartStatistics="rolesDashboardStatistics?.order_counts!"
              />

              <span v-else class="chart-placeholder">
                {{
                  t("Dashboard.NotEnoughDataToShowChart_24", {
                    chartName: t("Dashboard.Orders_8").toLowerCase(),
                  })
                }}
              </span>
            </div>

            <div
              v-if="
                !(
                  currentUser?.user_role.includes(UserRoles.APPROVER) ||
                  currentUser?.user_role.includes(UserRoles.VIEWER)
                )
              "
              class="amounts-chart col-xl-5"
            >
              <AmountsDonutChart
                v-if="
                  Object.values(rolesDashboardStatistics?.amounts as any).reduce(
                    (acc: any, curr: any) => acc + curr,
                    0
                  )
                "
                :amountsChartStatistics="rolesDashboardStatistics?.amounts!"
              />

              <span v-else class="chart-placeholder">
                {{
                  t("Dashboard.NotEnoughDataToShowChart_24", {
                    chartName: t("Dashboard.Amounts_25").toLowerCase(),
                  })
                }}
              </span>
            </div>

            <div
              class="payments-chart col-xl-5"
              v-if="
                currentUser?.user_role.includes(UserRoles.PAYER) ||
                currentUser?.user_role.includes(UserRoles.PAYMENT_CLEARER) ||
                currentUser?.user_role.includes(UserRoles.APPROVER) ||
                currentUser?.user_role.includes(UserRoles.VIEWER)
              "
            >
              <PaymentsDonutChart
                v-if="
                 Object.values(rolesDashboardStatistics?.payment_counts as any).reduce(
                    (acc: any, curr: any) => acc + curr,
                    0
                  )
                "
                :paymentsChartStatistics="rolesDashboardStatistics?.payment_counts!"
              />

              <span v-else class="chart-placeholder">
                {{
                  t("Dashboard.NotEnoughDataToShowChart_24", {
                    chartName: t("Dashboard.Payments_9").toLowerCase(),
                  })
                }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="
        currentUser?.user_role.includes(UserRoles.PURCHASER) ||
        currentUser?.user_role.includes(UserRoles.ORDER_CLEARER) ||
        currentUser?.user_role.includes(UserRoles.APPROVER) ||
        currentUser?.user_role.includes(UserRoles.VIEWER)
      "
      class="orders-tables mb-4"
    >
      <div class="list-table">
        <!-- Tabs -->
        <ul class="orders-tabs">
          <li :class="!route.query.status ? 'active' : ''">
            <router-link :to="{ name: 'dashboard' }" class="dropdown-item">
              {{ t("Order.AllTypes_89") }}
            </router-link>
          </li>
          <li
            :class="route.query.status === 'PENDING_APPROVAL' ? 'active' : ''"
          >
            <router-link
              :to="{ name: 'dashboard', query: { status: 'PENDING_APPROVAL' } }"
              class="dropdown-item"
            >
              {{ t("Order.Pending_90") }}
            </router-link>
          </li>
          <!-- Hide this tab for clearers -->
          <li
            v-if="!currentUser?.user_role?.includes('Order Clearer')"
            :class="route.query.status === 'CLEARING' ? 'active' : ''"
          >
            <router-link
              :to="{ name: 'dashboard', query: { status: 'CLEARING' } }"
              class="dropdown-item"
            >
              {{ t("Order.Clearing_141") }}
            </router-link>
          </li>
          <li :class="route.query.status === 'APPROVED' ? 'active' : ''">
            <router-link
              :to="{ name: 'dashboard', query: { status: 'APPROVED' } }"
              class="dropdown-item"
            >
              {{ t("Order.Approved_91") }}
            </router-link>
          </li>
          <li :class="route.query.status === 'REJECTED' ? 'active' : ''">
            <router-link
              :to="{ name: 'dashboard', query: { status: 'REJECTED' } }"
              class="dropdown-item"
            >
              {{ t("Order.Rejected_92") }}
            </router-link>
          </li>
          <li :class="route.query.status === 'DRAFT' ? 'active' : ''">
            <router-link
              :to="{ name: 'dashboard', query: { status: 'DRAFT' } }"
              class="dropdown-item"
            >
              {{ t("Order.Drafts_93") }}
            </router-link>
          </li>
        </ul>
        <div>
          <OrderTable
            :status="
              typeof route.query.status === 'string' ? route.query.status : ''
            "
            :customer="selectedCustomerId !== null ? selectedCustomerId : -1"
            :isdashbaord="true"
          />
        </div>
        <!-- Tabs -->
      </div>
    </div>

    <div
      v-if="
        currentUser?.user_role.includes(UserRoles.PAYER) ||
        currentUser?.user_role.includes(UserRoles.PAYMENT_CLEARER) ||
        currentUser?.user_role.includes(UserRoles.APPROVER) ||
        currentUser?.user_role.includes(UserRoles.VIEWER)
      "
      class="payments-table"
    >
      <div class="list-table">
        <!-- Tabs -->
        <ul class="orders-tabs">
          <li :class="!route.query.payment_status ? 'active' : ''">
            <router-link :to="{ name: 'dashboard' }" class="dropdown-item">
              {{ t("Order.AllTypes_89") }}
            </router-link>
          </li>
          <li
            :class="
              route.query.payment_status === 'PENDING_APPROVAL' ? 'active' : ''
            "
          >
            <router-link
              :to="{
                name: 'dashboard',
                query: { payment_status: 'PENDING_APPROVAL' },
              }"
              class="dropdown-item"
            >
              {{ t("Order.Pending_90") }}</router-link
            >
          </li>
          <!-- Hide this tab for clearers -->
          <li
            v-if="!currentUser?.user_role?.includes('Payment Clearer')"
            :class="route.query.payment_status === 'CLEARING' ? 'active' : ''"
          >
            <router-link
              :to="{ name: 'dashboard', query: { payment_status: 'CLEARING' } }"
              class="dropdown-item"
            >
              {{ t("Order.Clearing_141") }}</router-link
            >
          </li>
          <li
            :class="route.query.payment_status === 'APPROVED' ? 'active' : ''"
          >
            <router-link
              :to="{ name: 'dashboard', query: { payment_status: 'APPROVED' } }"
              class="dropdown-item"
            >
              {{ t("Order.Approved_91") }}</router-link
            >
          </li>
          <li
            :class="route.query.payment_status === 'REJECTED' ? 'active' : ''"
          >
            <router-link
              :to="{ name: 'dashboard', query: { payment_status: 'REJECTED' } }"
              class="dropdown-item"
            >
              {{ t("Order.Rejected_92") }}</router-link
            >
          </li>
          <li :class="route.query.payment_status === 'DRAFT' ? 'active' : ''">
            <router-link
              :to="{ name: 'dashboard', query: { payment_status: 'DRAFT' } }"
              class="dropdown-item"
            >
              {{ t("Order.Drafts_93") }}</router-link
            >
          </li>
        </ul>
        <div>
          <PaymentTable
            :status="
              typeof route.query.payment_status === 'string'
                ? route.query.payment_status
                : ''
            "
            :customer="selectedCustomerId !== null ? selectedCustomerId : -1"
            :isdashbaord="true"
          />
        </div>
        <!-- Tabs -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRolesDashboardCounts } from "@/_helpers/composables/dashboard/roles-dashboard-counts.composable";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useUserStore } from "@/stores/user.store";
import moment from "moment";
import { onMounted, ref } from "vue";
import OrderTable from "@/components/shared/tables/orders-table/OrdersTable.vue";
import PaymentTable from "@/components/shared/tables/payments-table/PaymentsTable.vue";
import { UserRoles } from "@/_helpers/interfaces";
import OrdersDonutChart from "@/components/dashboards/OrdersDonutChart.vue";
import PaymentsDonutChart from "@/components/dashboards/PaymentsDonutChart.vue";
import AmountsDonutChart from "@/components/dashboards/AmountsDonutChart.vue";
import type { RolesDashboardStatistics } from "@/_helpers/interfaces/dashboards/roles-dashboard-statistics.interface";
import { startOfWeek, subWeeks } from "date-fns";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t, n } = useI18n();
const route = useRoute();

const { currentUser } = useUserStore();

const { getRolesDashboardStatistics } = useDashboardStore();
const { translationKeys, getRolesStatisticsCounts } = useRolesDashboardCounts();

const selectedCustomerId = ref<number>();
const selectedDateRange = ref<{ startDate: string; endDate: string }>({
  startDate: moment(startOfWeek(subWeeks(new Date(), 1))).format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
});
const statisticsView = ref("totalCounts");
const rolesDashboardStatistics = ref<RolesDashboardStatistics>();
const statisticsCountsProperties = ref({});

onMounted(async () => {
  await getDashboardStatistics();
});

const getDashboardStatistics = async () => {
  let customerId: any = localStorage.getItem("selectedCustomerId");
  customerId = JSON.parse(customerId);
  selectedCustomerId.value = customerId;
  const { startDate, endDate } = selectedDateRange.value;

  rolesDashboardStatistics.value = await getRolesDashboardStatistics(
    startDate,
    endDate,
    selectedCustomerId.value
  );

  statisticsCountsProperties.value = getRolesStatisticsCounts(
    rolesDashboardStatistics.value!
  );
};

const changeDateRange = async (range) => {
  selectedDateRange.value = { ...range };
  await getDashboardStatistics();
};
</script>

<style scoped lang="scss">
@import "./roles-dashboard.scss";
</style>
