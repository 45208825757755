<template>
  <div class="card">
    <form @submit="onSubmitVacationForm" class="card-body">
      <div class="card-title">
        <span>{{ t("UserProfile.VacationMode_12") }}</span>

        <button type="submit" class="btn btn-primary" :disabled="isSubmitting">
          {{ t("UserProfile.SubmitVacation_13") }}
        </button>
      </div>

      <p class="card-subtitle">
        {{ t("UserProfile.ChooseYourVacationTime_14") }}
      </p>

      <div class="row justify-content-between">
        <div class="form-group col-md-5">
          <label class="form-label">{{
            t("UserProfile.StartingDate_15")
          }}</label>

          <DatePicker
            v-model="startDate"
            name="startDate"
            :placeholder="t('UserProfile.SelectStartingDate_16')"
            :clearable="false"
            :min-date="minStartDate"
            :max-date="maxStartDate"
            :month-change-on-scroll="false"
            :format="formatVacationDate"
            :preview-format="formatVacationDate"
            :class="{ 'is-invalid': errors.startDate }"
          ></DatePicker>

          <div class="invalid-feedback">{{ errors.startDate }}</div>
        </div>

        <div class="form-group col-md-5">
          <label class="form-label">{{ t("UserProfile.ReturnDate_17") }}</label>

          <DatePicker
            v-model="endDate"
            name="endDate"
            :placeholder="t('UserProfile.SelectReturnDate_18')"
            :clearable="false"
            :min-date="minEndDate"
            :month-change-on-scroll="false"
            :format="formatVacationDate"
            :preview-format="formatVacationDate"
            :class="{ 'is-invalid': errors.endDate }"
          ></DatePicker>

          <div class="invalid-feedback">{{ errors.endDate }}</div>
        </div>
      </div>

      <!-- Show replacements if:
              - User is not Admin
              - User is not only a VIEWER
              - User has customer_assignments -->
      <!-- TODO: Remove it for VIEWERs only users too -->
      <div
        v-if="
          !currentUser?.is_admin && currentUser?.customer_assignments.length
        "
        class="replacements-container mt-3"
      >
        <h6 class="card-subtitle">{{ t("UserProfile.Replacements_19") }}</h6>

        <span
          v-if="!customersWithUsersList.length"
          class="see-replacements-link"
          :class="{
            'disable-link': isLoadingReplacements,
          }"
          @click="getAvailableReplacementsInDateRange"
        >
          <template v-if="isLoadingReplacements">
            {{ t("UserProfile.Loading_24") }}...
          </template>

          <template v-else>
            {{ t("UserProfile.SeeAvailableReplacements_20") }}...
          </template>
        </span>

        <div v-else class="container-fluid mt-2">
          <div
            v-for="(customerWithUsers, index) in customersWithUsersList"
            :key="customerWithUsers.customerId"
            class="row mb-2 user-selection-row"
          >
            <div class="col-4 customer-name-container">
              <span>{{ customerWithUsers.customerName }}</span>
            </div>

            <div class="from-group col-8">
              <LegacyMultiSelect
                v-model="replacements[index].user"
                class="customer-dropdown"
                mode="single"
                :options="customerWithUsers.availableUsers"
                :searchable="true"
                valueProp="id"
                label="display_name"
                :placeholder="t('UserProfile.ChooseUser_21')"
                :canClear="false"
                :class="{ 'is-invalid': errors[`replacements[${index}].user`] }"
              />

              <div class="invalid-feedback">
                <span v-if="!customerWithUsers.availableUsers.length">
                  {{ t("UserProfile.NoAvailableUsersToSelect_22") }}
                </span>
                <span v-else>
                  {{ errors[`replacements[${index}].user`] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { watch, ref, type Ref, computed } from "vue";
import { useField, useFieldArray, useForm } from "vee-validate";
import { array, object, string } from "yup";
import { useToast } from "vue-toast-notification";
import uniqBy from "lodash/uniqBy";
import { useI18n } from "vue-i18n";

const toast = useToast();
const { t } = useI18n();

const validationSchema = object({
  startDate: string().required().label("Start date"),
  endDate: string().required().label("End date"),
  replacements: array().of(
    object({
      customer: string(),
      user: string().required(
        t("UserProfile.PleaseSelectUserAssociatedWith_23")
      ),
    })
  ),
});

const { handleSubmit, errors, isSubmitting, validate } = useForm({
  validationSchema,
});

const { value: startDate }: { value: Ref<Date> } = useField("startDate");
const { value: endDate }: { value: Ref<Date> } = useField("endDate");
const { value: replacements }: { value: Ref<any> } = useField("replacements");
const { push } = useFieldArray("replacements");

const onSubmitVacationForm = handleSubmit(async (values) => {
  try {
    if (
      (values.replacements && currentUser.value?.customer_assignments.length) ||
      (!values.replacements && !currentUser.value?.customer_assignments.length)
    ) {
      await updateVacation({
        vacation_start: values.startDate.toISOString().split("T")[0],
        vacation_end: values.endDate.toISOString().split("T")[0],
        replacements: values.replacements,
      });

      toast.open({
        message: `Vacation dates are submitted successfully.`,
        type: "success",
      });
    } else {
      toast.open({
        message: `Please select replacements first.`,
        type: "error",
      });
    }
  } catch (error) {
    toast.open({
      message: `Something went wrong. Please try again later.`,
      type: "error",
    });
  }
});

const isLoadingReplacements: Ref<boolean> = ref(false);
const customersWithUsersList: Ref<
  Array<{ customerId: number; customerName: string; availableUsers: any[] }>
> = ref([]);
const { currentUser } = storeToRefs(useUserStore());
const { getAvailableReplacements, updateVacation } = useUserStore();

const getAvailableReplacementsInDateRange = async () => {
  const { valid } = await validate();

  if (valid) {
    isLoadingReplacements.value = true;
    customersWithUsersList.value = await getCustomerWithUsersList();
    customersWithUsersList.value.forEach((customerWithUsers) => {
      push({
        customer: customerWithUsers.customerId,
        user: "",
      });
    });
    isLoadingReplacements.value = false;
  }
};

const getCustomerWithUsersList = async () => {
  const uniqueCustomerAssignments = uniqBy(
    currentUser.value!.customer_assignments,
    "customer"
  );

  const allAvailableUsersList = await Promise.all(
    uniqueCustomerAssignments!.map((customerAssignment) => {
      return getAvailableReplacements({
        customer_id: customerAssignment.customer,
        vacation_start: startDate.value.toISOString().split("T")[0], // Use appropriate endpoint format (YYYY-MM-DD)
        vacation_end: endDate.value.toISOString().split("T")[0],
      });
    })
  );

  const customersWithUsers = uniqueCustomerAssignments.map(
    (customerAssignment, index) => {
      return {
        customerId: customerAssignment.customer,
        customerName: customerAssignment.customer_name,
        availableUsers: allAvailableUsersList[index], // it is guaranteed that the order of Promise.all is the same order of uniqueCustomerAssignments
      };
    }
  );

  return customersWithUsers;
};

const formatVacationDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

watch(currentUser, async (user) => {
  if (user) {
    if (currentUser.value?.vacation_start)
      startDate.value = new Date(currentUser.value?.vacation_start!);

    if (currentUser.value?.vacation_end)
      endDate.value = new Date(currentUser.value?.vacation_end!);
  }
});

const minStartDate = computed(() => {
  return new Date();
});

const maxStartDate = computed(() => {
  return endDate.value;
});

const minEndDate = computed(() => {
  return startDate.value || new Date();
});
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
.card {
  .card-body {
    .card-title {
      display: flex;
      justify-content: space-between;

      .btn.btn-primary {
        height: 38px;
      }
    }

    .card-subtitle {
      margin-top: 0;
      margin-bottom: 0.2rem;
    }
  }
}

.invalid-feedback {
  font-size: 0.7rem;
}

.replacements-container {
  .see-replacements-link {
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
    color: $primary;

    &.disable-link {
      pointer-events: none;
      color: #9299e0;
    }
  }

  .user-selection-row {
    .customer-name-container {
      display: flex;
      align-items: center;
      background-color: #eef2f7;
      border: 1px solid #dee2e6;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      span {
        font-size: 1rem;
        color: #969696;
      }
    }
  }
}
</style>
