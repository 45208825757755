import { UserRoles } from "@/_helpers/interfaces";

export const SideBarLinks = [
  {
    nameKey: "Sidebar.Dashboard_2",
    iconClass: "icon-dashboard",
    routerName: "dashboard",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.PAYER,
      UserRoles.ORDER_CLEARER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.Customers_3",
    iconClass: "icon-customers",
    routerName: "customers",
    allowedRoles: [UserRoles.ADMIN, UserRoles.APPROVER, UserRoles.VIEWER],
    subLinks: [
      {
        nameKey: "Sidebar.AddCustomer_4",
        routerName: "create-customer",
        allowedRoles: [UserRoles.ADMIN],
      },
      {
        nameKey: "Sidebar.Projects_12",
        routerName: "projects",
        allowedRoles: [UserRoles.ADMIN, UserRoles.APPROVER],
      },
      {
        nameKey: "Sidebar.AssignTeam_6",
        routerName: "assign-team",
        allowedRoles: [UserRoles.ADMIN],
      },
      {
        nameKey: "Sidebar.EditLimits_7",
        routerName: "edit-limits",
        allowedRoles: [UserRoles.ADMIN],
      },
      {
        nameKey: "Sidebar.EditForms_8",
        routerName: "edit-forms",
        allowedRoles: [UserRoles.ADMIN],
      },
    ],
  },
  {
    nameKey: "Sidebar.Orders_20",
    iconClass: "icon-documents-outline",
    routerName: "orders",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.APPROVER,
      UserRoles.ORDER_CLEARER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.RecurringOrders_21",
    iconClass: "icon-repeat",
    routerName: "recurring-orders",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.APPROVER,
      UserRoles.ORDER_CLEARER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.Payments_22",
    iconClass: "icon-purchase",
    routerName: "payments",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PAYER,
      UserRoles.APPROVER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.Statistics_10",
    iconClass: "icon-statistics",
    routerName: "statistics",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
      UserRoles.ORDER_CLEARER,
      UserRoles.PAYMENT_CLEARER,
    ],
  },
  {
    nameKey: "Sidebar.Users_11",
    iconClass: "icon-users",
    routerName: "list-users",
    allowedRoles: [UserRoles.ADMIN],
  },
  {
    nameKey: "Global.MyProfile_1",
    iconClass: "icon-profile",
    routerName: "my-profile",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.PAYER,
      UserRoles.ORDER_CLEARER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.NotificationCenter_13",
    iconClass: "icon-notifications-center",
    routerName: "notification-center",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.PAYER,
      UserRoles.ORDER_CLEARER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
    ],
  },
  {
    nameKey: "Sidebar.HistoryLog_14",
    iconClass: "icon-history-log",
    routerName: "history-log",
    allowedRoles: [UserRoles.ADMIN],
  },
  {
    nameKey: "Sidebar.MasterData_15",
    iconClass: "icon-document",
    routerName: "master-data",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PURCHASER,
      UserRoles.PAYER,
      UserRoles.ORDER_CLEARER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
    ],
    subLinks: [
      {
        nameKey: "Sidebar.Suppliers_16",
        routerName: "list-suppliers",
        allowedRoles: [
          UserRoles.ADMIN,
          UserRoles.PURCHASER,
          UserRoles.PAYER,
          UserRoles.ORDER_CLEARER,
          UserRoles.PAYMENT_CLEARER,
          UserRoles.APPROVER,
          UserRoles.VIEWER,
        ],
      },
      {
        nameKey: "Sidebar.BankAccounts_17",
        routerName: "list-bank-accounts",
        allowedRoles: [
          UserRoles.ADMIN,
          UserRoles.PAYER,
          UserRoles.PAYMENT_CLEARER,
          UserRoles.APPROVER,
        ],
      },
    ],
  },
  {
    nameKey: "Sidebar.SepaPayments_18",
    iconClass: "icon-purchase",
    routerName: "sepa-payments",
    allowedRoles: [
      UserRoles.ADMIN,
      UserRoles.PAYER,
      UserRoles.PAYMENT_CLEARER,
      UserRoles.APPROVER,
      UserRoles.VIEWER,
    ],
  },
];
