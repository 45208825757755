<template>
  <div class="card">
    <div class="card-body card-content">
      <div class="title-and-upload">
        <div class="card-title">
          {{ t("UserProfile.NotificationPreferences_25") }}
        </div>
      </div>

      <div class="signature-container">
        <select
          v-if="currentUser?.email_notification_preference"
          class="form-select"
          v-model="currentUser.email_notification_preference"
        >
          <option value="daily">
            {{ t("UserProfile.Daily_26") }}
          </option>
          <option value="never">
            {{ t("UserProfile.Never_27") }}
          </option>
          <option value="immediate">
            {{ t("UserProfile.Immediate_28") }}
          </option>
        </select>
      </div>
      <button class="btn action-btn" @click="updateNotificationPref()">
        {{ t("UserProfile.UpdatePreferences_29") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
const toast = useToast();
const { t } = useI18n();

const { currentUser } = storeToRefs(useUserStore());
const { editUserProfile } = useUserStore();

const updateNotificationPref = async () => {
  if (!currentUser?.value) return;
  try {
    await editUserProfile({
      email_notification_preference:
        currentUser.value.email_notification_preference,
    });
    toast.open({
      message: t("UserProfile.NotificationPreferencesUpdatedSuccessfully_30"),
      type: "success",
    });
  } catch (error) {
    toast.open({
      message: t("UserProfile.FailedToUpdateNotificationPreferences_31"),
      type: "error",
    });
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";

.card {
  height: 100%;
  max-height: 290px;

  .card-content {
    .form-group {
      margin-bottom: 1rem;
    }

    .password-reset-result {
      span {
        font-size: 0.9rem;
      }
    }

    .action-btn {
      background-color: $primary;
      color: text-color-primary;
      border-radius: 0.2rem;
      width: 100%;
      margin-top: 1rem;
    }
  }
}
</style>
