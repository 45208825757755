<template>
  <div class="customer">
    <h1 class="page-title">{{ t("Order.OrderDetail_60") }}</h1>
    <!-- Delete Popup -->
    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>{{ t("Order.DeletePopupTitle_81") }} ?</h5>
          <div class="options">
            <button class="save" @click="onChangeOrderDelete">
              {{ t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row">
      <!-- Left Side -->
      <div class="col-lg-7 col-12">
        <!-- Detail -->
        <div class="form-wrapper mb-4">
          <div class="generic-form">
            <div class="row mb-4">
              <div class="col-12">
                <!-- Order Type -->
                <span>
                  {{
                    order.is_recurring
                      ? t("Order.RecurringOrder_107")
                      : t("Order.OneTimeOrder_106")
                  }}
                </span>
                <!-- Order Badge -->
                <span
                  class="order-badge mx-3"
                  :class="
                    order.status_display === 'APPROVED' ||
                    order.status_display === 'CLEARING'
                      ? 'approved-badge'
                      : order.status_display === 'REJECTED'
                      ? 'reject-badge'
                      : order.status_display === 'DRAFT'
                      ? 'draft-badge'
                      : order.status_display === 'PENDING_APPROVAL'
                      ? 'pending-badge'
                      : ''
                  "
                >
                  {{ getOrderStatusDisplay() }}
                </span>
                <span
                  class="order-badge mx-1 pending-badge"
                  v-if="order.is_clarification_requested"
                >
                  Clarification Requested
                </span>
                <!-- Action Buttons -->
                <button
                  class="mx-2 action-btn"
                  v-if="showEditOrderBtn()"
                  @click="editOrder(order.id)"
                >
                  <i class="icon-edit me-2"></i>
                  <span> Edit </span>
                </button>
                <button
                  class="mx-2 action-btn"
                  v-if="
                    order.status === 'DRAFT' &&
                    order.released_by?.id === currentUser?.id
                  "
                  @click="openDeletePopup()"
                >
                  <i class="icon-delete me-2"></i>
                  <span> {{ t("Order.Delete_63") }} </span>
                </button>
                <!-- Approve -->
                <button
                  class="mx-2 action-btn action-approve"
                  v-if="showApproveOrRejectAction()"
                  @click="approvedOrder(order.id)"
                >
                  <i class="icon-document-approve me-2"></i>
                  <span> Approve </span>
                </button>
                <!-- Request Clarification -->
                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRequestClarificationAction()"
                  @click="clarificationOrder(order.id)"
                >
                  <i class="icon-document-clarification me-2"></i>
                  <span> Request Clarification </span>
                </button>
                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRespondToClarificationAction()"
                  @click="respondToClarification(order.id)"
                >
                  <MazIcon
                    name="check-circle"
                    size="1.2rem"
                    path="/icons"
                    class="check-icon"
                  />
                  <span>
                    {{ t("Order.MarkClarificationCompleted_145") }}
                  </span>
                </button>
                <!-- Reject -->
                <button
                  class="ms-2 action-btn action-reject"
                  v-if="showApproveOrRejectAction()"
                  @click="rejectOrder(order.id)"
                >
                  <i class="icon-document-reject me-2"></i>
                  <span> Reject </span>
                </button>
              </div>
            </div>
            <!-- Table -->
            <div class="row">
              <div class="col-lg-12">
                <OneTimePurchase :order="order" />
              </div>
            </div>
            <!-- Table -->
          </div>
        </div>
        <!-- Attachments -->
        <Attachments :order="order" />
        <!-- Self Notes -->
        <SelfNotes :order="order" />
      </div>
      <!-- Right Side -->
      <div class="col-lg-5 col-12">
        <Comments :order="order" />
        <RelatedOrders v-if="order?.recurring_instruction" :order="order">
        </RelatedOrders>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useOrderStore } from "@/stores/order.store";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import OneTimePurchase from "@/components/order-details/one-time-purchase/OneTimePurchase.vue";
import Attachments from "@/components/order-details/attachments/Attachments.vue";
import SelfNotes from "@/components/order-details/self-notes/SelfNotes.vue";
import Comments from "@/components/order-details/comments/Comments.vue";
import MazIcon from "maz-ui/components/MazIcon";
import RelatedOrders from "@/components/order-details/related-orders/RelatedOrders.vue";
import { useI18n } from "vue-i18n";

const { getOrderById } = useOrderStore();
const router = useRouter();
const { deleteOrder, ordersBulkAction, respondToClarificationRequest } =
  useOrderStore();

const { currentUser } = storeToRefs(useUserStore());

const toast = useToast();
const { t } = useI18n();

let order: any = ref({
  no: "",
  date_order_list: null,
  document_no_internal: null,
  order_type: null,
  delivery_date: null,
  order_triggered: false,
  order_triggered_on: null,
  vendor_no: "",
  supplier: null,
  order_no_supplier: "",
  range_in_months: null,
  order_related_purchase_order: null,
  project_no: null,
  expected_date_processing: null,
  comment: null,
  net_eur: null,
  gross_eur: null,
  release_effected: null,
  comment_allea: null,
  comment_management: null,
  comment_insv: null,
  preliminary_insolvency_proceedings: true,
  service_date: "",
  status: "",
  status_display: "",
  project: null,
  is_recurring: false,
  files: [],
  is_clarification_requested: false,
  requested_clarification_by_id: null,
  created_by_id: null,
});

const orderId: any = ref(null);

const isPopup = ref(false);

const editOrder = (id: any) => {
  localStorage.setItem("isEditOrder", "true");
  localStorage.setItem("orderId", id);
  router.push({ name: "create-order", query: { order: id } });
};

const approvedOrder = async (id: any) => {
  let ids = [id];
  await ordersBulkAction("bulk_approve", ids);
  order.value.status = "APPROVED";
  toast.open({
    message: "Order status has been changed to Approve",
    type: "success",
  });
};

const clarificationOrder = async (id: any) => {
  let ids = [id];
  await ordersBulkAction("bulk_request_clarification", ids);
  order.value.is_clarification_requested = true;
  toast.open({
    message: "Clarification has been requested for the order",
    type: "success",
  });
};

const respondToClarification = async (id: any) => {
  await respondToClarificationRequest(id);
  order.value.is_clarification_requested = false;
  toast.open({
    message: "Clarification has been completed for the order",
    type: "success",
  });
};

const rejectOrder = async (id: any) => {
  let ids = [id];
  await ordersBulkAction("bulk_reject", ids);
  order.value.status = "REJECTED";
  toast.open({
    message: "Order status has been changed to Rejected",
    type: "success",
  });
};

const closePopup = () => {
  isPopup.value = false;
};

const openDeletePopup = () => {
  isPopup.value = true;
};

const onChangeOrderDelete = async () => {
  closePopup();
  await deleteOrder(order.value.id);
  router.push({ name: "orders" });
};

const getOrderStatusDisplay = () => {
  if (order.value.status_display === "APPROVED") {
    // use translation
    return t("Order.Approved_62");
  } else if (order.value.status_display === "REJECTED") {
    return t("Order.Rejected_65");
  } else if (order.value.status_display === "DRAFT") {
    return t("Order.Draft_66");
  } else if (order.value.status_display === "PENDING_APPROVAL") {
    return t("Order.Pending_90");
  } else if (
    order.value.status_display === "CLEARING" &&
    !order.value.is_restrict
  ) {
    return t("Order.Clearing_141");
  } else if (
    order.value.status_display === "CLEARING" &&
    order.value.is_restrict
  ) {
    return `${t("Order.Clearing_141")} (${
      order.value.total_clearer_approved || 0
    } / ${order.value.total_clearer || 0})`;
  }
  return "";
};

const showEditOrderBtn = () => {
  if (order.value?.recurring_instruction) {
    return false;
  }
  return (
    (order.value.status === "DRAFT" &&
      order.value.released_by?.id === currentUser.value?.id) ||
    (order.value.is_clarification_requested &&
      order.value.created_by_id === currentUser.value?.id)
  );
};

const showApproveOrRejectAction = () => {
  if (order.value?.recurring_instruction) {
    return false;
  }
  if (currentUser.value?.user_role.includes("Admin")) {
    return ["PENDING_APPROVAL", "CLEARING"].includes(
      order.value.status_display
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return order.value.status_display === "PENDING_APPROVAL";
  }
  if (currentUser.value?.user_role.includes("Order Clearer")) {
    return order.value.status_display === "PENDING_APPROVAL";
  }
  return false;
};

const showRequestClarificationAction = () => {
  if (order.value?.recurring_instruction) {
    return false;
  }
  if (
    currentUser.value?.user_role.includes("Admin") ||
    currentUser.value?.user_role.includes("Approver") ||
    currentUser.value?.user_role.includes("Order Clearer")
  ) {
    return (
      ["PENDING_APPROVAL", "CLEARING"].includes(order.value.status_display) &&
      !order.value.is_clarification_requested
    );
  }
  return false;
};

const showRespondToClarificationAction = () => {
  if (order.value?.recurring_instruction) {
    return false;
  }
  return (
    order.value.is_clarification_requested &&
    (currentUser.value?.id === order.value.created_by_id ||
      currentUser.value?.id === order.value.requested_clarification_by_id)
  );
};

onMounted(async () => {
  orderId.value = +router.currentRoute.value.params.id;
  /* Get Order from ID */
  if (router.currentRoute.value.name === "order" && isNaN(+orderId.value)) {
    return router.push({ name: "orders" });
  }
  order.value = await getOrderById(orderId.value);
});

watch(
  () => router.currentRoute.value.params.id,
  async (newVal) => {
    if (router.currentRoute.value.name === "order" && isNaN(+newVal)) {
      return router.push({ name: "orders" });
    } else if (router.currentRoute.value.name === "order") {
      order.value = await getOrderById(newVal);
    }
  }
);
</script>

<style scoped>
.order-badge {
  font-size: 14px;
  line-height: 28px;
  padding: 4px 8px;
  border-radius: 15px;
}

.approved-badge {
  background-color: #d7f6ec;
  color: #3dd5a5;
}

.reject-badge {
  background-color: #fde2e8;
  color: #f86584;
}

.draft-badge {
  background-color: #727cf3;
  color: #ffffff;
}

.action-btn {
  font-size: 15px;
  line-height: 28px;
  padding: 4px 8px;
  margin: 10px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.check-icon {
  color: #0dcaf0;
  margin-right: 8px;
}
</style>
