export const useFormatValue = () => {
  const formatCurrency = (
    value: number,
    returnObject: boolean = false
  ): string | { value: string; unit?: string; currency?: string } => {
    let formattedValue: { value: string; unit?: string; currency?: string };
    if (value >= 1_000_000_000) {
      formattedValue = {
        value: (value / 1_000_000_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "B",
        currency: "€",
      };
    } else if (value >= 1_000_000) {
      formattedValue = {
        value: (value / 1_000_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "M",
        currency: "€",
      };
    } else if (value >= 1_000) {
      formattedValue = {
        value: (value / 1_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "K",
        currency: "€",
      };
    } else {
      formattedValue = {
        value: value.toLocaleString("de-DE", { maximumFractionDigits: 2 }),
        currency: "€",
      };
    }

    if (returnObject) {
      return formattedValue;
    } else {
      return `${formattedValue.currency}${formattedValue.value}${
        formattedValue.unit || ""
      }`;
    }
  };

  const formatLargeNumber = (
    value: number,
    returnObject: boolean = false
  ): string | { value: string; unit?: string } => {
    let formattedValue: { value: string; unit?: string };
    if (value >= 1_000_000_000) {
      formattedValue = {
        value: (value / 1_000_000_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "B",
      };
    } else if (value >= 1_000_000) {
      formattedValue = {
        value: (value / 1_000_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "M",
      };
    } else if (value >= 1_000) {
      formattedValue = {
        value: (value / 1_000).toLocaleString("de-DE", {
          maximumFractionDigits: 1,
        }),
        unit: "K",
      };
    } else {
      formattedValue = {
        value: value.toLocaleString("de-DE", { maximumFractionDigits: 2 }),
      };
    }

    if (returnObject) {
      return formattedValue;
    } else {
      return `${formattedValue.value}${formattedValue.unit || ""}`;
    }
  };

  return { formatCurrency, formatLargeNumber };
};
