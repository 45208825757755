<template>
  <div class="my-profile-page">
    <h4 class="page-header">{{ t("Global.MyProfile_1") }}</h4>

    <div class="my-profile-content container-fluid">
      <div class="row justify-content-center">
        <div
          :class="{
            'col-lg-5': currentUser?.show_signature,
            col: !currentUser?.show_signature,
          }"
        >
          <MainInfo />
        </div>
        <div class="col-lg-7" v-if="currentUser?.show_signature">
          <UserSignature />
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-lg-5">
          <ResetPassword />
        </div>
        <div class="col-lg-7">
          <SetVacationMode />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-lg-5">
          <NotificationPreference />
        </div>
        <div class="col-lg-7"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ResetPassword } from "@/components/my-profile";
import { MainInfo } from "@/components/my-profile";
import { UserSignature } from "@/components/my-profile/";
import { SetVacationMode } from "@/components/my-profile/";
import { useUserStore } from "@/stores/user.store";
import NotificationPreference from "@/components/my-profile/NotificationPreference.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { currentUser } = useUserStore();
</script>

<style scoped lang="scss"></style>
