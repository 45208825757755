<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="generic-popup" v-if="isPopup">
    <div class="popup-wrapper">
      <div class="popup-header">
        <h4 class="popup-title">{{ t("CustomerManagement.Delete_67") }}</h4>
        <span class="popup-close" @click="closePopup">
          <i class="icon-close"></i>
        </span>
      </div>
      <div class="popup-body">
        <h5>
          {{ t("CustomerManagement.DeletePopupTitle_114") }}
          {{ customerName }} ?
        </h5>
        <div class="options">
          <button class="save" @click="onChangeCustomerDelete">
            {{ t("CustomerManagement.Delete_67") }}
          </button>
          <button class="cancel" @click="closePopup">
            {{ t("CustomerManagement.Cancel_113") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="list-header">
    <div class="list-header__text">
      <h5>{{ t("CustomerManagement.Customers_2") }}</h5>
    </div>

    <div class="list-header__actions">
      <div class="bulk-actions" v-if="selectedCustomers.length">
        <button
          class="btn btn-primary"
          v-if="currentUser?.is_admin"
          @click="onChangeBulkCustomersDelete()"
        >
          {{ t("CustomerManagement.DeleteCustomers_115") }}
        </button>
      </div>

      <a @click="changeEditMode(false)" class="btn btn-primary">
        <i class="icon-add-new"></i>
        <span>{{ t("CustomerManagement.NewCustomer_3") }}</span>
      </a>

      <!-- <input
        type="text"
        name="searchCustomersList"
        id="searchCustomersList"
        :placeholder="t('CustomerManagement.Search_4')"
        class="form-control"
        @input="debounceSearchCustomers"
      /> -->
    </div>
  </div>

  <InocuDataTable
    v-model:items-selected="selectedCustomers"
    v-model:server-options="serverOptions"
    :server-items-length="customersListTotalCount"
    :loading="loading"
    :headers="customersTableHeaders"
    :items="customersList"
    :rows-items="[10, 25, 50, 100]"
    theme-color="#CEEC34"
    table-class-name="listing-custom-table"
    alternating
    buttons-pagination
    no-hover
    must-sort
    fixed-checkbox
    :checkbox-column-width="60"
    :table-min-height="500"
  >
    <!-- Filtrable header cells -->
    <template #header-name="header">
      <div class="filter-column" @click.stop>
        <i
          class="icon-filter filter-icon"
          @click.stop="showNameFilter = !showNameFilter"
        ></i>
        {{ header.text }}
        <div class="filter-menu" v-if="showNameFilter">
          <input
            v-model="customersListSearchTerm"
            type="text"
            class="form-control"
            @input="debounceSearchCustomers"
          />
        </div>
      </div>
    </template>

    <template #item-name="customer">
      <router-link
        :to="{ name: 'view-customer', params: { id: customer.id } }"
        class="dropdown-item"
      >
        {{ customer.name }}</router-link
      >
    </template>

    <template #item-legal_name="customer">
      {{ customer.legal_name }}
    </template>

    <template #item-customer_limit="customer">
      {{ customer.customer_limit }}
    </template>

    <template #item-auto_approval_limit="customer">
      {{ customer.auto_approval_limit }}
    </template>

    <template #item-financial_limit_frequency="customer">
      {{ toCapitalize(customer.financial_limit_frequency) }}
    </template>

    <template #item-actions="customer">
      <div class="actions">
        <span @click="editCustomer(customer.id, true)">
          <i class="icon-edit"></i>
        </span>
        <span @click="openDeletePopup(customer.name, customer.id)">
          <i class="icon-delete"></i>
        </span>
      </div>
    </template>

    <!-- No Data View -->
    <template #empty-message>
      {{ t("CustomerManagement.NoProjectsToShow_145") }}...
    </template>
  </InocuDataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";
import router from "@/router";
import debounce from "lodash/debounce";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useToast } from "vue-toast-notification";
import { useFilters } from "@/_helpers/composables/global-filters.composable";
import { useCustomerStore } from "@/stores/customer.store";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const props = defineProps<{
  customerId?: number | null;
}>();

watch(props, async () => {
  await getCustomersTableList();
});

const { t, n } = useI18n();

const { toCapitalize } = useFilters();
const { getCustomers, deleteCustomer, deleteBulkCustomer } = useCustomerStore();

const { currentUser } = storeToRefs(useUserStore());

const toast = useToast();

const customersTableHeaders: Ref<Header[]> = ref([
  {
    text: t("CustomerManagement.Name_61"),
    value: "name",
    width: 230,
    sortable: true,
  },
  {
    text: t("CustomerManagement.LegalName_12"),
    value: "legal_name",
    width: 230,
  },
  {
    text: t("CustomerManagement.CustomerLimit_13"),
    value: "customer_limit",
    width: 150,
  },
  {
    text: t("CustomerManagement.AutomaticApprovalLimit_14"),
    value: "auto_approval_limit",
    width: 180,
  },
  {
    text: t("CustomerManagement.FinancialLimitFrequency_14"),
    value: "financial_limit_frequency",
    width: 150,
  },
  { text: t("CustomerManagement.Actions_117"), value: "actions" },
]);

const showNameFilter = ref(false);
const customersList: any = ref([]);
const selectedCustomers: any = ref([]);
const loading = ref(false);
const customersListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "name",
  sortType: "asc",
});

const customersListSearchTerm: Ref<string> = ref("");

let customerId = "";
let customerName = "";

const isPopup = ref(false);

const selectedCustomersIds = ref([]);

const isBulk = ref(false);

onMounted(async () => {
  await getCustomersTableList();
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getCustomersTableList();
  },
  {
    deep: true,
  }
);

const getCustomersTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getCustomers({
    id: props.customerId,
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    search: customersListSearchTerm.value!,
    ordering: sortDirection + sortBy,
    is_active: true,
  });

  customersList.value = [...response.results];
  customersList.value.map((item: any) => {
    item.customer_limit = n(+item.customer_limit, "currency");
    item.auto_approval_limit = n(+item.auto_approval_limit, "currency");
  });
  customersListTotalCount.value = response.count;

  loading.value = false;
};

const onChangeBulkCustomersDelete = async () => {
  isPopup.value = true;
  customerName = "";
  selectedCustomersIds.value = selectedCustomers.value.map(
    (user: any) => user.id
  );
  isBulk.value = true;
};

const onChangeCustomerDelete = async () => {
  closePopup();
  if (isBulk.value === true) {
    await deleteBulkCustomer(selectedCustomersIds.value);

    toast.open({
      message: `All selected customers have been deleted successfully.`,
      type: "success",
    });

    isBulk.value = false;
    selectedCustomers.value = [];
  } else {
    await deleteCustomer([customerId]);

    toast.open({
      message: `Customer (${customerName}) deleted successfully.`,
      type: "success",
    });
  }
  await getCustomersTableList();
};

const debounceSearchCustomers = debounce(async function (event) {
  customersListSearchTerm.value = event.target.value;
  await getCustomersTableList();
}, 300);

const changeEditMode = (boolean: any) => {
  localStorage.setItem("isEditCustomer", boolean);
  router.push({ name: "create-customer" });
};

const openDeletePopup = (name: any, id: any) => {
  isPopup.value = true;
  customerId = id;
  customerName = name;
};

const closePopup = () => {
  isPopup.value = false;
};

const editCustomer = (id: any, boolean: any) => {
  localStorage.setItem("isEditCustomer", boolean);
  localStorage.setItem("customerId", id);
  router.push({ name: "create-customer" });
};
</script>
