<template>
  <InocuDataTable
    v-model:server-options="serverOptions"
    :server-items-length="projectsListTotalCount"
    :loading="loading"
    :headers="projectsTableHeaders"
    :items="projectsList"
    :rows-items="[10, 25, 50, 100]"
    theme-color="#727cf3"
    table-class-name="listing-custom-table"
    alternating
    buttons-pagination
    no-hover
    must-sort
  >
    <template #item-name="project">
      {{ project.name }}
    </template>

    <template #item-project_limit="project">
      {{ project.project_limit }}
    </template>

    <template #item-auto_approval_limit="project">
      {{ project.auto_approval_limit }}
    </template>

    <template #item-project_limit_frequency="project">
      {{ toCapitalize(project.project_limit_frequency) }}
    </template>

    <!-- No Data View -->
    <template #empty-message>
      {{ t("CustomerManagement.NoProjectsToShow_145") }}...
    </template>
  </InocuDataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useFilters } from "@/_helpers/composables/global-filters.composable";
import { useProjectStore } from "@/stores/project.store";
import { useI18n } from "vue-i18n";

const { toCapitalize } = useFilters();
const { getProjectsByCustomer } = useProjectStore();

const { n, t } = useI18n();

const props = defineProps({
  CustomerId: Number,
  ProjectData: Object,
});

const projectsTableHeaders: Ref<Header[]> = ref([
  { text: "Name", value: "name", width: 300, sortable: true },
  { text: "Project Limit", value: "project_limit", width: 200 },
  {
    text: "Automatic Approval Limit",
    value: "auto_approval_limit",
    width: 200,
  },
  {
    text: "Project Limit Frequency",
    value: "project_limit_frequency",
    width: 200,
  },
]);

const projectsList: any = ref([]);
const loading = ref(false);
const projectsListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "name",
  sortType: "asc",
});

const getProjectsTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getProjectsByCustomer({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    customer: props.CustomerId!,
    ordering: sortDirection + sortBy,
    is_active: true,
  });

  projectsList.value = [...response.results];
  projectsList.value.map((item: any) => {
    item.project_limit = n(+item.project_limit, "currency");
    item.auto_approval_limit = n(+item.auto_approval_limit, "currency");
  });
  projectsListTotalCount.value = response.count;

  loading.value = false;
};

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getProjectsTableList();
  },
  {
    deep: true,
  }
);

watch(
  () => props.ProjectData,
  async () => {
    await getProjectsTableList();
  }
);

onMounted(async () => {
  await getProjectsTableList();
});
</script>
