<template>
  <div class="admin-dashboard-page">
    <h4 class="page-header">
      <span>{{ t("Dashboard.Dashboard_1") }}</span>

      <LegacyMultiSelect
        v-if="selectedCustomer"
        v-model="selectedProject"
        mode="single"
        :options="filterProjectsList"
        :filter-results="false"
        :resolve-on-load="true"
        :delay="200"
        :min-chars="0"
        :searchable="true"
        valueProp="id"
        label="name"
        :placeholder="t('Dashboard.SelectProject_3')"
        :clearOnBlur="false"
        @change="filterByProject($event)"
      />

      <div class="date-range">
        <DateRange
          :initial-date="[startOfWeek(subWeeks(new Date(), 1)), new Date()]"
          @selectDate="changeChartDateRange($event)"
        />
      </div>
    </h4>

    <div class="dashboard-content container-fluid d-flex flex-column">
      <div class="row justify-content-between">
        <div class="dashboard-cards col-lg-5">
          <div class="dashboard-card card">
            <div class="card-info">
              <div class="title">
                {{ t("Dashboard.Customers_4") }}
              </div>
              <div class="count">{{ reportsNumbers?.customers }}</div>
            </div>

            <router-link :to="{ name: 'customers' }" class="card-icon">
              <i class="icon-customers"></i>
            </router-link>
          </div>

          <div class="dashboard-card card">
            <div class="card-info">
              <div class="title">
                {{ t("Dashboard.TotalRequests_5") }}
              </div>
              <div class="count">{{ reportsNumbers?.orders }}</div>
            </div>

            <router-link :to="{ name: 'orders' }" class="card-icon">
              <i class="icon-documents-outline"></i>
            </router-link>
          </div>

          <div class="dashboard-card card">
            <div class="card-info">
              <div class="title">
                {{ t("Dashboard.ActiveUsers_6") }}
              </div>
              <div class="count">{{ reportsNumbers?.users }}</div>
            </div>

            <router-link :to="{ name: 'list-users' }" class="card-icon">
              <i class="icon-users"></i>
            </router-link>
          </div>

          <div class="dashboard-card card">
            <div class="card-info">
              <div class="title">
                {{ t("Dashboard.TotalProjects_7") }}
              </div>
              <div class="count">{{ reportsNumbers?.projects }}</div>
            </div>

            <router-link :to="{ name: 'projects' }" class="card-icon">
              <i class="icon-versions"></i>
            </router-link>
          </div>
        </div>

        <div class="dashboard-chart col-lg-7 card p-2">
          <apexchart :options="chartOptions" :series="chartSeries"></apexchart>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="users-list col-xl-6 mt-5">
          <ListUsersTable :customerId="selectedCustomer" />
        </div>

        <div class="customers-list col-xl-6 mt-5">
          <div class="list-table">
            <Table :customerId="selectedCustomer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminDashboardChart } from "@/_helpers/composables/dashboard/admin-dashboard-chart.composable";
import ListUsersTable from "@/views/Users/ListUsersTable.vue";
import Table from "@/components/shared/tables/customers-table/Table.vue";
import { useCustomerStore } from "@/stores/customer.store";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useProjectStore } from "@/stores/project.store";
import { onMounted, ref, watch } from "vue";
import moment from "moment";
import { startOfWeek, endOfWeek, subWeeks } from "date-fns";

import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { getCustomersList } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { getCountsOnReports } = useDashboardStore();

const { chartSeries, chartOptions, getChartData } = useAdminDashboardChart();

const selectedCustomer = ref<any>(null);
const selectedProject = ref<number | null | undefined>(null);
const selectedDateRange = ref<{ startDate: string; endDate: string }>({
  startDate: moment(startOfWeek(subWeeks(new Date(), 1))).format("YYYY-MM-DD"),
  endDate: moment(endOfWeek(subWeeks(new Date(), 1))).format("YYYY-MM-DD"),
});
const reportsNumbers = ref();
const filtrationOptions = ref<{
  customerId?: number | null;
  projectId?: number | null;
}>({});

watch(
  filtrationOptions,
  async (options) => {
    reportsNumbers.value = await getCountsOnReports(
      options.customerId,
      options.projectId
    );

    const { startDate, endDate } = selectedDateRange.value;
    await getChartData(
      startDate,
      endDate,
      options.customerId,
      options.projectId
    );
  },
  { deep: true }
);

onMounted(async () => {
  reportsNumbers.value = await getCountsOnReports();

  const { startDate, endDate } = selectedDateRange.value;
  await getChartData(startDate, endDate);
});

const changeChartDateRange = async (range) => {
  selectedDateRange.value = { ...range };

  const { startDate, endDate } = selectedDateRange.value;
  await getChartData(
    startDate,
    endDate,
    filtrationOptions.value.customerId,
    filtrationOptions.value.projectId
  );
};

const filterProjectsList = async (query: string) => {
  const { results } = await getProjectsByCustomer({
    customer: selectedCustomer.value,
    name: query,
    page_size: 100,
  });

  return results as any[];
};

const filterByProject = (projectId: number | null) => {
  filtrationOptions.value.projectId = projectId;
};

onMounted(async () => {
  const selectedCustomerId = localStorage.getItem("selectedCustomerId");
  if (selectedCustomerId) {
    selectedCustomer.value = parseInt(selectedCustomerId);
    filtrationOptions.value.customerId = selectedCustomer.value;
  }
});
</script>

<style lang="scss" scoped>
@import "./admin-dashboard.scss";
</style>
