import moment from "moment";

export const useDateRange = () => {
  const dateFormat = "YYYY-MM-DD";

  const getLastMonthDates = (date) => {
    const startDate = moment(
      new Date(date.getFullYear(), date.getMonth() - 1, 1)
    ).format(dateFormat);
    const endDate = moment(
      new Date(date.getFullYear(), date.getMonth(), 0)
    ).format(dateFormat);
    return { startDate, endDate };
  };

  const getThisMonthDates = (date) => {
    const startDate = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format(dateFormat);
    const endDate = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ).format(dateFormat);
    return { startDate, endDate };
  };

  const getLastWeekDates = (date) => {
    const startDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
    ).format(dateFormat);
    const endDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    ).format(dateFormat);
    return { startDate, endDate };
  };

  const getLast30DaysDates = (date) => {
    const startDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
    ).format(dateFormat);
    const endDate = moment(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    ).format(dateFormat);
    return { startDate, endDate };
  };

  const getLastYearDates = (date) => {
    const startDate = moment(new Date(date.getFullYear() - 1, 0, 1)).format(
      dateFormat
    );
    const endDate = moment(new Date(date.getFullYear() - 1, 11, 31)).format(
      dateFormat
    );
    return { startDate, endDate };
  };

  const getThisYearDates = (date) => {
    const startDate = moment(new Date(date.getFullYear(), 0, 1)).format(
      dateFormat
    );
    const endDate = moment(new Date(date.getFullYear(), 11, 31)).format(
      dateFormat
    );
    return { startDate, endDate };
  };

  const today = new Date();
  const lastMonthDates = getLastMonthDates(today);
  const thisMonthDates = getThisMonthDates(today);
  const lastWeekDates = getLastWeekDates(today);
  const last30DaysDates = getLast30DaysDates(today);
  const lastYearDates = getLastYearDates(today);
  const thisYearDates = getThisYearDates(today);

  return {
    lastWeekDates,
    lastMonthDates,
    last30DaysDates,
    thisMonthDates,
    lastYearDates,
    thisYearDates,
  };
};
