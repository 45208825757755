<template>
  <div class="create-page">
    <h4 class="page-header">{{ t("Suppliers.NewMasterPage_1") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ t("Suppliers.BankAccount_43") }}</div>

      <form
        @submit="onSubmitBankAccountForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <!-- Owner and Bank Name -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="ownerName" class="form-label">
              {{ t("Suppliers.AccountOwner_44") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="ownerName"
              id="ownerName"
              name="ownerName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.ownerName }"
              :placeholder="t('Suppliers.TypeNameHere_4') + '...'"
            />
            <div class="invalid-feedback">{{ errors.ownerName }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="bankName" class="form-label">
              {{ t("Suppliers.BankName_45") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="bankName"
              id="bankName"
              name="bankName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.bankName }"
              :placeholder="t('Suppliers.TypeNameHere_4') + '...'"
            />
            <div class="invalid-feedback">{{ errors.bankName }}</div>
          </div>
        </div>

        <!-- SWIFT Code and IBAN -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="swiftCode" class="form-label">
              {{ t("Suppliers.SwiftCode_46") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="swiftCode"
              id="swiftCode"
              name="swiftCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.swiftCode }"
              :placeholder="t('Suppliers.TypeSwiftCodeHere_47') + '...'"
            />
            <div class="invalid-feedback">{{ errors.swiftCode }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="iban" class="form-label">
              {{ t("Suppliers.IBAN_48") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="iban"
              id="iban"
              name="iban"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.iban }"
              :placeholder="t('Suppliers.TypeIbanHere_49') + '...'"
            />
            <div class="invalid-feedback">{{ errors.iban }}</div>
          </div>
        </div>

        <!-- Supplier -->
        <div class="row justify-content-between mb-4">
          <div
            class="form-group col-lg-5"
            v-if="associatedPersona === 'supplier'"
          >
            <label for="supplierId" class="form-label">
              {{ t("Suppliers.Supplier_54") }}
              <span class="text-danger">*</span>
            </label>
            <LegacyMultiSelect
              :key="supplierId"
              :disabled="!customerId"
              :loading="isSupplierLoading"
              v-model="supplierId"
              id="supplierId"
              name="supplierId"
              ref="supplierSelectRef"
              mode="single"
              :options="supplierList"
              :searchable="true"
              valueProp="id"
              label="display_name"
              :placeholder="t('Suppliers.ChooseSupplier_56')"
              :clearOnBlur="false"
              :clearable="false"
              :class="{ 'is-invalid': errors.supplierId }"
              @search-change="debounceFilterSuppliersList"
            />
            <div class="invalid-feedback" v-if="errors.supplierId">
              {{ errors.supplierId }}
            </div>
          </div>
        </div>

        <div
          v-if="bankAccountCreatedSuccessfully != null"
          class="result-alert mt-4"
        >
          <div
            v-if="bankAccountCreatedSuccessfully && !meta.dirty"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ t("Suppliers.BankAccountCreatedSuccessfully_50") }}
          </div>
          <div
            v-if="!bankAccountCreatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div class="create-item-footer mt-4">
          <button
            type="submit"
            class="btn btn-primary me-2"
            :disabled="isSubmitting"
          >
            {{ t("Suppliers.AddBankAccount_51") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="backToBankAccountsList"
            :disabled="isSubmitting"
          >
            {{ t("Suppliers.Discard_19") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref } from "vue";

import { useBankAccountForm } from "@/_helpers/composables/master-data/bank-account-changing.composable";
import { useSupplierStore } from "@/stores/supplier.store";
import { useBankAccountStore } from "@/stores/bank-account.store";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";
import { useToast } from "vue-toast-notification";
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";

const { currentUser } = storeToRefs(useUserStore());
const toast = useToast();
const { t } = useI18n();

const {
  errors,
  handleSubmit,
  isSubmitting,
  meta,
  resetForm,
  ownerName,
  bankName,
  swiftCode,
  iban,
  associatedPersona,
  customerId,
  supplierId,
  backToBankAccountsList,
} = useBankAccountForm();

const router = useRouter();

const bankAccountCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");

const { createBankAccount } = useBankAccountStore();

const onSubmitBankAccountForm = handleSubmit(async (values) => {
  try {
    await createBankAccount({
      owner_name: values.ownerName,
      bank_name: values.bankName,
      swift_code: values.swiftCode,
      iban: values.iban,
      customer: values.customerId,
      supplier:
        associatedPersona.value === "supplier" ? values.supplierId : null,
    });

    let oldPersona = associatedPersona.value;
    let oldCustomerId = customerId.value;
    bankAccountCreatedSuccessfully.value = true;
    resetForm();
    associatedPersona.value = oldPersona;
    const { value: user } = currentUser;
    if (!user?.user_role.includes("Admin")) {
      customerId.value = oldCustomerId;
    }
    supplierId.value = null;
    toast.open({
      message: t("Suppliers.BankAccountCreatedSuccessfully_83"),
      type: "success",
    });
  } catch (error: any) {
    bankAccountCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

const { getSuppliersList } = useSupplierStore();

const supplierList: Ref<Array<any>> = ref([]);
const supplierSelectRef: Ref<any> = ref(null);
const isSupplierLoading = ref(false);

const filterSuppliersList = async (query: string, _element: any = null) => {
  let options = {
    search: query,
    page_size: 200,
  };
  if (!customerId.value) {
    return [];
  }
  options["customer"] = customerId.value;
  isSupplierLoading.value = true;
  const { results } = await getSuppliersList(options);
  isSupplierLoading.value = false;

  supplierList.value = results.map((supplier: any) => {
    return {
      ...supplier,
      display_name: `${supplier.name} - ${supplier.supplier_number}`,
    };
  });
};

const debounceFilterSuppliersList = debounce(filterSuppliersList, 500);

onMounted(async () => {
  const query = router.currentRoute.value.query;
  const queryType = Array.isArray(query.type) ? query.type[0] : query.type;

  associatedPersona.value = queryType;

  customerId.value = +localStorage.getItem("selectedCustomerId")!;
  await filterSuppliersList("");
});
</script>

<style scoped lang="scss">
.create-page {
  .create-page-content {
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .item-data-header {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .form-label {
    font-weight: 500;
  }

  .form-control,
  .form-select {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .create-item-footer {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
