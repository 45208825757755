<template>
  <form class="generic-form" @submit.prevent="onSubmit">
    <div class="row justify-content-between">
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label">
            {{ t("CustomerManagement.AssignPurchasers_55") }}</label
          >
          <LegacyMultiSelect
            v-model="formData.purchasersValue"
            name="purchasers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="t('CustomerManagement.AssignPurchasers_55')"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label">Assign Order Clearers</label>
          <LegacyMultiSelect
            v-model="formData.orderClearersValue"
            name="clearers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            placeholder="Assign Order Clearers"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label">Assign Payment Clearers</label>
          <LegacyMultiSelect
            v-model="formData.paymentClearersValue"
            name="clearers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            placeholder="Assign Payment Clearers"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label">{{
            t("CustomerManagement.AssignPayers_57")
          }}</label>
          <LegacyMultiSelect
            v-model="formData.payersValue"
            name="payers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="t('CustomerManagement.AssignPayers_57')"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label">
            {{ t("CustomerManagement.AssignApprovers_58") }}</label
          >
          <LegacyMultiSelect
            v-model="formData.approversValue"
            name="approvers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="t('CustomerManagement.AssignApprovers_58')"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-input">
          <label class="form-label"> Assign Viewers</label>
          <LegacyMultiSelect
            v-model="formData.viewersValue"
            name="approvers"
            mode="tags"
            :object="true"
            :options="usersList"
            :resolve-on-load="true"
            :min-chars="0"
            placeholder="Assign Viewers"
            :closeOnSelect="false"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="display_name"
          />
        </div>
      </div>
      <div class="form-input m-0">
        <span v-if="$v.usersValue.$error" class="form__error-msg">
          {{ t("CustomerManagement.UserRequired_77") }}</span
        >
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <h4 class="group-title">
            {{ t("CustomerManagement.InviteUsersByEmail_72") }}
          </h4>
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.FirstName_61")
            }}</label>
            <input
              v-model="userData.first_name"
              @keyup="setDisplayName"
              type="text"
              :placeholder="t('CustomerManagement.TypeName_15')"
            />
          </div>
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.LastName_61")
            }}</label>
            <input
              v-model="userData.last_name"
              @keyup="setDisplayName"
              type="text"
              :placeholder="t('CustomerManagement.TypeName_15')"
            />
          </div>
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.UserEmail_76")
            }}</label>
            <input
              v-model="userData.email"
              type="email"
              :placeholder="t('CustomerManagement.TypeEmail_78')"
            />
          </div>
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.ChooseRole_74")
            }}</label>
            <LegacyMultiSelect
              v-model="rolesValue"
              name="approvers"
              mode="tags"
              :object="true"
              :options="RolesList"
              :resolve-on-load="true"
              :min-chars="0"
              :placeholder="t('CustomerManagement.ChooseRole_74')"
              :closeOnSelect="false"
              :clearOnBlur="false"
              :searchable="true"
              valueProp="role"
              label="name"
            />
          </div>
          <div class="form-input" v-if="isInvitationBtn">
            <button @click.prevent="inviteUser" class="invitation-btn">
              {{ t("CustomerManagement.SendInvitation_73") }}
            </button>
          </div>
        </div>
        <div v-if="isUserInvitedSuccessfully != null">
          <div
            v-if="isUserInvitedSuccessfully"
            class="alert alert-success"
            role="alert"
          >
            {{ t("CustomerManagement.InvitationSentSuccessfully_84") }}
          </div>
          <div v-else class="alert alert-danger" role="alert">
            {{ errorMessageInvitation }}
          </div>
        </div>
        <div class="form-group" v-if="formData.approversValue.length > 0">
          <h4 class="group-title">
            {{ t("CustomerManagement.ApproversLimits_59") }}
          </h4>
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.TypeLimits_60")
            }}</label>
            <div
              class="form-input-limits mb-3"
              v-for="(approver, index) in formData.approversValue"
              :key="approver"
            >
              <span>{{ approver.display_name }}</span>
              <!-- <input
                v-model="formData.approversValue[index].approver_limit"
                type="text"
                :placeholder="t('CustomerManagement.TypeLimit_16')"
              /> -->
              <CurrencyInput
                v-model.trim="formData.approversValue[index].approver_limit"
                :placeholder="t('CustomerManagement.TypeLimit_16')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5"></div>
      <div v-if="isCreatedSuccessfully != null">
        <div
          v-if="isCreatedSuccessfully"
          class="col-md-5 alert alert-success"
          role="alert"
        >
          {{
            isEditMode
              ? t("CustomerManagement.AssignTeamEditedSuccessfully_98")
              : t("CustomerManagement.AssignTeamCreatedSuccessfully_80")
          }}
        </div>
        <div v-else class="col-md-5 alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <div class="col-12">
        <div class="form-options">
          <router-link :to="{ name: 'customers' }" class="cancel-btn">{{
            t("CustomerManagement.CancelDiscard_69")
          }}</router-link>
          <button
            v-if="isPrevious && isEditMode"
            @click="onPrevious"
            class="previous"
            type="submit"
          >
            {{ t("CustomerManagement.Previous_88") }}
          </button>
          <button class="submit-btn" type="submit">
            {{
              isPrevious
                ? t("CustomerManagement.SaveContinue_70")
                : t("CustomerManagement.Save_89")
            }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";
import { ref, onMounted, watch, type Ref } from "vue";
import { reactive } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useCustomerStore } from "@/stores/customer.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { createAssignTeam, getAssignTeamById, addUser, getUsers } =
  useCustomerStore();
const props = defineProps<{
  customerId: number | string;
  isEditMode: boolean;
  isPrevious: boolean;
  isFormWizard: boolean;
}>();
let usersList = ref([]);
let userData: any = reactive({
  first_name: "",
  last_name: "",
  display_name: "",
  email: "",
  roles: [],
  customer: "",
});
let rolesValue = ref([]);
let RolesList = ref([
  { role: "ADMIN", name: "Admin" },
  { role: "PURCHASER", name: "Purchaser" },
  { role: "PAYER", name: "Payer" },
  { role: "ORDER_CLEARER", name: "Clearer" },
  { role: "APPROVER", name: "Approver" },
  { role: "VIEWER", name: "Viewer" },
]);
let assignTeamData: any = ref([]);
const formData: any = reactive({
  usersValue: [],
  purchasersValue: [],
  orderClearersValue: [],
  paymentClearersValue: [],
  payersValue: [],
  approversValue: [],
  viewersValue: [],
});
let cachedReceivedItemsValues: any = ref([]);
const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
const isUserInvitedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessageInvitation = ref("");
let isInvitationBtn = ref(false);
const rules = {
  usersValue: {
    required,
  },
};
const $v = useVuelidate(rules, formData);
let purchasers: any = [];
let clearers: any = [];
let paymentClearers: any = [];
let payers: any = [];
let approvers: any = [];
let viewers: any = [];
const addRoleToSelectedUsers = (selectedUsers: any, role: any) => {
  selectedUsers.forEach((item: any) => {
    if (role == "PURCHASER") {
      purchasers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: null,
      });
    } else if (role == "ORDER_CLEARER") {
      clearers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: null,
      });
    } else if (role == "PAYMENT_CLEARER") {
      paymentClearers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: null,
      });
    } else if (role == "PAYER") {
      payers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: null,
      });
    } else if (role == "APPROVER") {
      approvers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: item.approver_limit,
      });
    } else if (role == "VIEWER") {
      viewers.push({
        id: item.id,
        display_name: item.display_name,
        user: item.id,
        customer: props.customerId,
        user_role: role,
        is_role_default: true,
        approver_limit: item.approver_limit,
      });
    }
  });
};
const mergeSelectedData = () => {
  addRoleToSelectedUsers(formData.purchasersValue, "PURCHASER");
  addRoleToSelectedUsers(formData.orderClearersValue, "ORDER_CLEARER");
  addRoleToSelectedUsers(formData.paymentClearersValue, "PAYMENT_CLEARER");
  addRoleToSelectedUsers(formData.payersValue, "PAYER");
  addRoleToSelectedUsers(formData.approversValue, "APPROVER");
  addRoleToSelectedUsers(formData.viewersValue, "VIEWER");
  formData.purchasersValue = purchasers;
  formData.orderClearersValue = clearers;
  formData.paymentClearersValue = paymentClearers;
  formData.payersValue = payers;
  formData.approversValue = approvers;
  formData.viewersValue = viewers;
  formData.usersValue = [
    ...formData.purchasersValue,
    ...formData.orderClearersValue,
    ...formData.paymentClearersValue,
    ...formData.payersValue,
    ...formData.approversValue,
    ...formData.viewersValue,
  ];
  assignTeamData.value = [];
  formData.usersValue.forEach((item: any) => {
    if (typeof item.id == "number") {
      assignTeamData.value.push({
        user: item.user,
        customer: item.customer,
        user_role: item.user_role,
        is_role_default: item.is_role_default,
        approver_limit: item.approver_limit,
      });
    } else {
      assignTeamData.value.push({
        user: item.user.id,
        customer: item.customer,
        user_role: item.user_role,
        is_role_default: item.is_role_default,
        approver_limit: item.approver_limit,
      });
    }
  });
};
const addIdToEditDataObjects = async () => {
  await cachedReceivedItemsValues.value.results.forEach((receivedItem: any) => {
    assignTeamData.value.map((item: any) => {
      if (
        receivedItem.user.id === item.user &&
        receivedItem.user_role === item.user_role
      ) {
        item.id = receivedItem.id;
      }
    });
  });
};
const onSubmit = async () => {
  mergeSelectedData();
  if (props.isEditMode) {
    addIdToEditDataObjects();
  }
  $v.value.$touch();
  if (!$v.value.$invalid) {
    try {
      await createAssignTeam(assignTeamData.value);
      isCreatedSuccessfully.value = true;
      if (props.isFormWizard) {
        let nextBtn: any = document.querySelector(".fw-footer-right button");
        nextBtn.click();
      }
    } catch (error: any) {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};
watch(
  () => props.customerId,
  async () => {
    if (props.isEditMode) {
      resetAllValues();
      await getCustomerAssignTeamById();
      await getCachedData();
    }
  }
);
watch(
  () => formData.purchasersValue,
  async () => {
    // console.log(formData.purchasersValue);
  }
);
watch(
  () => [
    userData.first_name,
    userData.last_name,
    userData.email,
    rolesValue.value,
  ],
  async () => {
    if (!isInvitationBtn.value) {
      isInvitationBtn.value = true;
    }
  }
);
const resetAllValues = () => {
  formData.purchasersValue = [];
  formData.orderClearersValue = [];
  formData.paymentClearersValue = [];
  formData.payersValue = [];
  formData.approversValue = [];
  formData.viewersValue = [];
  isUserInvitedSuccessfully.value = null;
};
const getAllUsers = async () => {
  const users = await getUsers(null);
  usersList.value = users.filter((user) => user.active);
};
const inviteUser = async () => {
  let newRoles: any = [];
  rolesValue.value.forEach((item: any) => {
    newRoles.push(item.role);
  });
  userData.customer = props.customerId;
  userData.roles = newRoles;
  try {
    await addUser(userData);
    await getAllUsers();
    await setUserRoleToListValue();
    isUserInvitedSuccessfully.value = true;
  } catch (error: any) {
    isUserInvitedSuccessfully.value = false;
    errorMessageInvitation.value = error.response.data.errors[0].detail;
  }
};
const setUserRoleToListValue = async () => {
  if (userData.roles.length > 0) {
    userData.roles.forEach((userRole: any) => {
      usersList.value.forEach((user: any) => {
        if (userData.display_name == user.display_name) {
          if (userRole == "PURCHASER") {
            formData.purchasersValue.push(user);
          } else if (userRole == "ORDER_CLEARER") {
            formData.orderClearersValue.push(user);
          } else if (userRole == "PAYMENT_CLEARER") {
            formData.paymentClearersValue.push(user);
          } else if (userRole == "PAYER") {
            formData.payersValue.push(user);
          } else if (userRole == "APPROVER") {
            formData.approversValue.push(user);
          } else if (userRole == "VIEWER") {
            formData.viewersValue.push(user);
          }
        }
      });
    });
  }
};
const setDisplayName = async () => {
  userData.display_name = userData.first_name + " " + userData.last_name;
};
const getCachedData = async () => {
  cachedReceivedItemsValues.value = await getAssignTeamById({
    customer: props.customerId,
  });
};
const getCustomerAssignTeamById = async () => {
  try {
    let data = await getAssignTeamById({
      customer: props.customerId,
    });
    appendReceivedEditData(data);
  } catch (e) {
    console.log(e);
  }
};
const appendReceivedEditData = async (data: any) => {
  appendReceivedEditItem(data, formData.purchasersValue, "PURCHASER");
  appendReceivedEditItem(data, formData.orderClearersValue, "ORDER_CLEARER");
  appendReceivedEditItem(
    data,
    formData.paymentClearersValue,
    "PAYMENT_CLEARER"
  );
  appendReceivedEditItem(data, formData.payersValue, "PAYER");
  appendReceivedEditItem(data, formData.approversValue, "APPROVER");
  appendReceivedEditItem(data, formData.viewersValue, "VIEWER");
};
const appendReceivedEditItem = async (
  data: any,
  selectedUsers: any,
  role: any
) => {
  data.results.forEach((item: any) => {
    if (item.user_role == role) {
      usersList.value.forEach((user: any) => {
        if (item.user.id == user.id) {
          selectedUsers.push({
            id: item.user,
            display_name: user.display_name,
            email: user.email,
            is_role_default: true,
            user_role: role,
            approver_limit: item.approver_limit,
          });
        }
      });
    }
  });
};
const onPrevious = () => {
  let prevBtn: any = document.querySelector(".fw-footer-left button");
  prevBtn.click();
};
onMounted(async () => {
  await getAllUsers();
  if (props.isEditMode && props.customerId != "") {
    await getCustomerAssignTeamById();
    await getCachedData();
  }
});
</script>
