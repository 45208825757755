import type {
  StatisticsOverviewData,
  NumberOfRequestsData,
  OrdersVolumeData,
  PaymentsVolumeData,
  OrderBalanceData,
  OrderCommitmentData,
} from "@/_helpers/interfaces/statistics/statistics-data.interface";
import axios from "axios";
import { defineStore } from "pinia";

export const useDashboardStore = defineStore("dashboard", () => {
  const getAverageReleaseDurationPerMonth = async (
    startDate: any,
    endDate: any,
    customerId?: number | null,
    projectId?: number | null
  ) => {
    try {
      const { data } = await axios.get(
        `/payments/average_release_duration_per_month_chart/`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            customer: customerId,
            project: projectId,
          },
        }
      );

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getCountsOnReports = async (
    customerId?: number | null,
    projectId?: number | null
  ) => {
    try {
      const apisUrls = [
        "/customers/customers_count/",
        "/orders/purchase_orders/orders_count/",
        "/users/active_users_count/",
        "customers/customer_projects/projects_count/",
      ];

      const [
        {
          data: { count: customers },
        },
        {
          data: { count: orders },
        },
        {
          data: { count: users },
        },
        {
          data: { count: projects },
        },
      ] = await Promise.all(
        apisUrls.map((url) => {
          return axios.get(url, {
            params: {
              customer: customerId,
              project: projectId,
            },
          });
        })
      );

      return { customers, orders, users, projects };
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getRolesDashboardStatistics = async (
    startDate: any,
    endDate: any,
    customerId?: number | null,
    projectId?: number | null
  ) => {
    try {
      const { data } = await axios.get(`/common/dashboard/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
          project: projectId,
        },
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getStatisticsOverview = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<StatisticsOverviewData> => {
    try {
      const { data } = await axios.get(`/common/statistics/overview/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
        },
      });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getNumberOfRequests = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<NumberOfRequestsData> => {
    try {
      const { data } = await axios.get(
        `/common/statistics/number_of_requests/`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            customer: customerId,
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getOrdersVolume = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<OrdersVolumeData> => {
    try {
      const { data } = await axios.get(`/common/statistics/orders_volume/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
        },
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getPaymentVolume = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<PaymentsVolumeData> => {
    try {
      const { data } = await axios.get(`/common/statistics/payments_volume/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
        },
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getOrderBalance = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<OrderBalanceData> => {
    try {
      const { data } = await axios.get(`/common/statistics/order_balance/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
        },
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getOrderCommitment = async (
    startDate: any,
    endDate: any,
    customerId?: number
  ): Promise<OrderCommitmentData> => {
    try {
      const { data } = await axios.get(`/common/statistics/order_commitment/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer: customerId,
        },
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  return {
    getCountsOnReports,
    getAverageReleaseDurationPerMonth,
    getRolesDashboardStatistics,
    getStatisticsOverview,
    getNumberOfRequests,
    getOrdersVolume,
    getPaymentVolume,
    getOrderBalance,
    getOrderCommitment,
  };
});
