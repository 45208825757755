import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";

export const usePaymentStore = defineStore("payment", () => {
  const PaymentsOptions: any = ref({
    ordering: "",
    page: "",
    page_size: "",
    project: "",
    customer: "",
    created_after: "",
    created_before: "",
    status: "",
    payed: "",
    clarification_requested_only: false,
    supplier: "",
    invoice_number: "",
    internal_order_number: "",
    created_by: "",
    released_by: "",
    due_date_after: "",
    due_date_before: "",
    amount_max: "",
    amount_min: "",
    payment_number: "",
  });

  // Post an Payment
  const createPayment = async (payments: any): Promise<any> => {
    try {
      const { data } = await axios.post(`/payments/`, payments);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  const createPaymentFile = async (payload: any): Promise<any> => {
    const formData = new FormData();
    formData.append("payment", payload.payment);
    formData.append("file", payload.file);
    await axios.post(`/payments/payment_files/`, formData);
  };
  const deletePaymentFile = async (id: number): Promise<any> => {
    await axios.delete(`/payments/payment_files/${id}`);
  };
  // Get All Payments
  const getPayments = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get("/payments/", {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  // Get payment from Payments Listing By Id
  const getPaymentById = async (id: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/payments/${id}/`);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  // Edit a payment
  const editPayment = async (id: number, payment: any): Promise<any> => {
    try {
      return await axios.put(`/payments/${id}/`, payment);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // respond to a clarification request, to clear the clarification requested flag
  const respondToClarificationRequest = async (id: number): Promise<any> => {
    try {
      return await axios.patch(
        `/payments/${id}/respond_to_clarification_request/`
      );
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const editPaymentPatch = async (id: number, payment: any): Promise<any> => {
    try {
      return await axios.patch(`/payments/${id}/`, payment);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
  // Delete Payment from Payments listing
  const deletePayment = async (id: any): Promise<any> => {
    try {
      await axios.delete(`/payments/${id}/`);
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };
  /* Order Bulk Action => Approve, Reject, Request Clarification, Delete */
  const paymentsBulkAction = async (action: string, ids: any): Promise<any> => {
    try {
      await axios.post(`/payments/${action}/`, {
        ids: ids,
      });
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };
  /* Get Payment Notes */
  const getPaymentNotes = async (id: number): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/payments/payment_notes/?payment=${id}`
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* VCreate Payment Notes */
  const createPaymentNotes = async (payload: object): Promise<any> => {
    try {
      const { data } = await axios.post("/payments/payment_notes/", payload);
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Edit Payment Notes */
  const editPaymentNotes = async (id: any, payload: object): Promise<any> => {
    try {
      const { data } = await axios.put(
        `/payments/payment_notes/${id}/`,
        payload
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Delete Payment Notes */
  const deletePaymentNotes = async (id: any): Promise<any> => {
    try {
      await axios.delete(`/payments/payment_notes/${id}/`);
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Payment Comments */
  const getPaymentComments = async (id: number): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/payments/payment_comments/?payment=${id}`
      );
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Get Payment Comments */
  const createPaymentComments = async (payload: object): Promise<any> => {
    try {
      const { data } = await axios.post("/payments/payment_comments/", payload);
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
  /* Export Payments */
  const exportPayments = async (
    ids: any,
    format: "pdf" | "xlsx",
    fields: Array<string>
  ): Promise<any> => {
    try {
      const response = await axios.post(`/payments/bulk_export/`, {
        payment_ids: ids,
        export_format: format,
        fields: fields,
      });
      return response.data;
    } catch (error: any) {
      console.log(error);

      throw error;
    }
  };

  // non api related
  const resetOptions = () => {
    PaymentsOptions.value = {
      ordering: "",
      page: "",
      page_size: "",
      project: "",
      customer: "",
      created_after: "",
      created_before: "",
      status: "",
      payed: "",
      clarification_requested_only: false,
      supplier: "",
      invoice_number: "",
      internal_order_number: "",
      created_by: "",
      released_by: "",
      due_date_after: "",
      due_date_before: "",
      amount_max: "",
      amount_min: "",
      payment_number: "",
    };
    return PaymentsOptions.value;
  };
  const setOptions = (options: any) => {
    PaymentsOptions.value = options;
  };

  const filterCount = (): number => {
    let count = 0;
    for (const key in PaymentsOptions.value) {
      // if the key is a date range, count as 1
      if (key.includes("before")) {
        // the after will be counted
        continue;
      }
      if (
        PaymentsOptions.value[key] !== "" &&
        PaymentsOptions.value[key] !== false
      ) {
        count += 1;
      }
    }
    return count;
  };

  return {
    createPayment,
    createPaymentFile,
    deletePaymentFile,
    getPayments,
    getPaymentById,
    editPayment,
    deletePayment,
    paymentsBulkAction,
    getPaymentNotes,
    createPaymentNotes,
    editPaymentNotes,
    deletePaymentNotes,
    getPaymentComments,
    createPaymentComments,
    exportPayments,
    editPaymentPatch,
    respondToClarificationRequest,
    PaymentsOptions,
    resetOptions,
    setOptions,
    filterCount,
  };
});
