import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";
import i18n from "@/_helpers/plugins/i18n";
import tokenService from "@/_helpers/services/token.service";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  const publicPagesRouteNames = ["login", "hijack"];
  const authRequired = !publicPagesRouteNames.includes(to.name as string);
  const accessToken = tokenService.getAccessToken();

  if (!to.params.locale && to.name !== "hijack") {
    next({
      ...to,
      // @ts-ignore
      params: { ...to.params, locale: (i18n.global.locale as any).value },
    });
  } else {
    if (authRequired && !accessToken) {
      next({ name: "login" }); // redirect to login page if not logged in and trying to access a restricted page
    } else if (to.name === "login" && accessToken) {
      next({ name: "home" }); // redirect to home page if logged in and trying to access login page
    } else if (accessToken) {
      const { currentUser } = storeToRefs(useUserStore());
      const { fetchCurrentUserAndAssociatedRoles } = useUserStore();

      if (!currentUser.value) {
        await fetchCurrentUserAndAssociatedRoles();
      }

      const allowedRolesForRoute = to.meta.allowedRoles as Array<string>;
      const userRoles = currentUser.value?.user_role;

      if (!userRoles?.length && to.name === "dashboard") {
        next({ name: "my-profile" });
      } else if (
        (userRoles?.length &&
          !allowedRolesForRoute.some((allowedRole) =>
            userRoles!.includes(allowedRole)
          )) ||
        (!userRoles?.length &&
          to.name !== "dashboard" &&
          to.name !== "my-profile")
      ) {
        next({ name: "home" });
      } else {
        next(); // proceed with the navigation
      }
    } else {
      next(); // proceed with the navigation
    }
  }
});

export default router;
