<template>
  <div class="date-picker-range">
    <VueDatePicker
      v-model="date"
      placeholder="Select date"
      :max-date="new Date()"
      :format="formatDate"
      :select-text="t('Global.Apply_16')"
      :cancel-text="t('Global.Cancel_17')"
      auto-apply
      range
      :preset-ranges="presetRanges"
    >
      <template #yearly="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(thisYearDates);
          "
          >{{ label }}</span
        >
      </template>
      <template #last_year="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(lastYearDates);
          "
          >{{ label }}</span
        >
      </template>
      <template #last_month="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(lastMonthDates);
          "
          >{{ label }}</span
        >
      </template>
      <template #this_month="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(thisMonthDates);
          "
          >{{ label }}</span
        >
      </template>
      <template #last_week="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(lastWeekDates);
          "
          >{{ label }}</span
        >
      </template>
      <template #last_30_days="{ label, range, presetDateRange }">
        <span
          style="width: 120px; display: block"
          @click="
            presetDateRange(range);
            props.getChartData(last30DaysDates);
          "
          >{{ label }}</span
        >
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { useDateRange } from "@/_helpers/composables/date-range.composable";
import {
  endOfMonth,
  endOfYear,
  endOfWeek,
  startOfMonth,
  startOfYear,
  startOfWeek,
  subMonths,
  subYears,
  subWeeks,
  subDays,
} from "date-fns";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const {
  lastWeekDates,
  thisMonthDates,
  lastMonthDates,
  last30DaysDates,
  lastYearDates,
  thisYearDates,
} = useDateRange();

const props = defineProps<{
  getChartData: Function;
}>();

const emit = defineEmits(["selectDate"]);

let date = ref();

const formatDate = (date: Date) => {
  const date1 = moment(date[0]).format("DD.MM.YYYY");
  let date2 = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    date2 = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${date1} To ${date2}`;
};
const presetRanges = ref([
  {
    label: "Last Week",
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
    slot: "last_week",
  },
  {
    label: "This month",
    range: [startOfMonth(new Date()), endOfMonth(new Date())],
    slot: "this_month",
  },
  {
    label: "Last month",
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
    slot: "last_month",
  },
  {
    label: "Last 30 days",
    range: [subDays(new Date(), 30), new Date()],
    slot: "last_30_days",
  },
  {
    label: "Last Year",
    range: [
      startOfYear(subYears(new Date(), 1)),
      endOfYear(subYears(new Date(), 1)),
    ],
    slot: "last_year",
  },
  {
    label: "This year",
    range: [startOfYear(new Date()), endOfYear(new Date())],
    slot: "yearly",
  },
]);

watch(
  () => date.value,
  () => {
    let sdate = date.value === null ? "" : date.value;
    let dateRange = {
      startDate: moment(sdate[0]).format("YYYY-MM-DD"),
      endDate: moment(sdate[1]).format("YYYY-MM-DD"),
    };
    emit("selectDate", dateRange);
  }
);
</script>
