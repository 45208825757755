<template>
  <header class="login-header">
    <h2>{{ t("Login.EnterWorkspaceUsername_45").toUpperCase() }}</h2>
  </header>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <Form
    class="login-form"
    :validation-schema="workspaceFormSchema"
    v-slot="{ errors, isSubmitting }"
    @submit="onSubmit"
  >
    <div class="form-group workspace-group">
      <label for="workspaceUsername" class="form-label">
        {{ t("Login.WorkspaceUsername_44") }}
      </label>

      <Field
        id="workspaceUsername"
        name="workspaceUsername"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.workspaceUsername }"
        :placeholder="t('Login.EnterYourWorkspaceUsername_43')"
      />

      <div class="invalid-feedback">{{ errors.workspaceUsername }}</div>
    </div>

    <button class="login-action-btn" :disabled="isSubmitting">
      {{ t("Login.Next_48") }}
    </button>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { useI18n } from "vue-i18n";
import { configureApi } from "@/_helpers/plugins/api-configuration";

const errorMessage = ref("");
const { t } = useI18n();
const workspaceFormSchema = object().shape({
  workspaceUsername: string().required(
    t("Login.WorkspaceUsernameIsRequired_46")
  ),
});

const emit = defineEmits<{
  (e: "backToLogin"): void;
}>();

const onSubmit = async ({ workspaceUsername }: any) => {
  localStorage.setItem("tenant", workspaceUsername);
  configureApi();

  emit("backToLogin");
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";
</style>
