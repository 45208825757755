<template>
  <div
    class="tw-flex md:tw-flex-col sm:tw-flex-col lg:tw-flex-row xl:tw-flex-row"
  >
    <div
      class="tw-flex tw-flex-col lg:tw-flex-row xl:tw-flex-row tw-leading-4 tw-justify-between tw-grow tw-mr-16"
    >
      <div class="tw-text-accent-500 tw-uppercase tw-text-sm tw-font-extrabold">
        {{ t("Statistics.Overview_1") }}
      </div>
      <div
        v-for="stat in stats"
        :key="stat.title"
        class="tw-group tw-flex tw-flex-row tw-space-x-2 tw-text-gray-500 tw-transition-colors tw-duration-300 tw-ease-in-out tw-text-nowrap"
      >
        <component
          :is="stat.icon"
          class="tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-text-primary-300"
        />
        <router-link
          v-if="!isLoading"
          class="tw-font-semibold tw-text-sm tw-text-gray-500 tw-border-b-2 tw-border-transparent tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-border-primary-300 group-hover:tw-text-primary-300"
          :to="stat.url"
        >
          {{ stat.title }} : {{ stat.value }}
        </router-link>
        <div
          v-else
          class="tw-w-24 tw-h-4 tw-bg-gray-200 tw-animate-pulse tw-rounded"
        ></div>
      </div>
    </div>
    <div class="tw-w-44 tw-hidden lg:tw-block xl:tw-block"></div>
  </div>
</template>
<script setup lang="ts">
import CustomersIcon from "../icons/customersIcon.svg";
import RequestsCountIcon from "../icons/requestsCountIcon.svg";
import ActiveUsersIcon from "../icons/activeUsersIcon.svg";
import projectsIcon from "../icons/projectsIcon.svg";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { ref, onMounted, watch, computed } from "vue";

const { getStatisticsOverview } = useDashboardStore();

const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const router = useRouter();

const { t } = useI18n();

const values = ref({
  customers: 0,
  requests: 0,
  activeUsers: 0,
  projects: 0,
});

const isLoading = ref(true);

const stats = computed(() => [
  {
    icon: CustomersIcon,
    title: t("Statistics.Customers_2"),
    value: values.value.customers,
    url: router.resolve({ name: "customers" }),
  },
  {
    icon: RequestsCountIcon,
    title: t("Statistics.Requests_3"),
    value: values.value.requests,
    url: router.resolve({ name: "orders" }),
  },
  {
    icon: ActiveUsersIcon,
    title: t("Statistics.ActiveUsers_4"),
    value: values.value.activeUsers,
    url: router.resolve({ name: "list-users" }),
  },
  {
    icon: projectsIcon,
    title: t("Statistics.Projects_5"),
    value: values.value.projects,
    url: router.resolve({ name: "projects" }),
  },
]);

const fetchData = async () => {
  isLoading.value = true;
  try {
    const data = await getStatisticsOverview(
      props.startDate,
      props.endDate,
      props.customerId
    );
    values.value = {
      customers: data.total_customers,
      requests: data.total_requests,
      activeUsers: data.total_users,
      projects: data.total_projects,
    };
  } catch (error) {
    console.error("Error fetching statistics overview:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchData);

watch(() => props, fetchData, { deep: true });
</script>
