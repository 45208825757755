import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import de from "@/locales/de.json";
import {
  getBrowserLocale,
  supportedLocalesInclude,
} from "./i18n/config/browser-locale";
import { numberFormats } from "./i18n/config/number-formats";

type MessageSchema = typeof en;

const getStartingLocale = (): string => {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale as string;
  } else {
    return "de";
  }
};

const i18n = createI18n<[MessageSchema], "en" | "de">({
  legacy: false,
  locale: getStartingLocale(),
  fallbackLocale: "de",
  messages: {
    en,
    // @ts-ignore
    de,
  },
  numberFormats,
});

export default i18n;
