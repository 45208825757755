<template>
  <div class="list-users-page">
    <h4 class="page-header">
      <span>{{ t("Users.UsersList_13") }}</span>
    </h4>

    <ListUsersTable :customerId="selectedCustomer" v-if="selectedCustomer" />
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount } from "vue";
import ListUsersTable from "./ListUsersTable.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const selectedCustomer = ref<number | null>(null);
onBeforeMount(() => {
  selectedCustomer.value = +localStorage.getItem("selectedCustomerId")!;
});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;

  .multiselect {
    width: 350px;
    margin: 0;
  }
}
</style>
