<template>
  <div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-2">
      <!-- Order Number -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="order_number"
          :placeholder="t('Order.OrderNo_75')"
          v-model="currentFilter.recurring_number"
        />
      </div>

      <!-- Supplier -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="supplier"
          :placeholder="t('Order.Supplier_31')"
          v-model="currentFilter.supplier"
        />
      </div>

      <!-- Document No Internal -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="document_no_internal"
          :placeholder="t('Order.DocumentNo_112')"
          v-model="currentFilter.document_no_internal"
        />
      </div>

      <!-- Order Name -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="order_name"
          :placeholder="t('Order.OrderName_13')"
          v-model="currentFilter.name"
        />
      </div>

      <!-- Frequency -->
      <div>
        <LegacyMultiSelect
          v-model="currentFilter.frequency"
          name="projects"
          :object="false"
          :options="frequencyOptions"
          :resolve-on-load="true"
          :min-chars="0"
          :placeholder="t('Order.Frequency_17')"
          :closeOnSelect="true"
          :clearOnBlur="false"
          :searchable="true"
          valueProp="value"
          label="name"
          class="tw-w-full tw-text-sm"
        />
      </div>

      <!-- Start Date -->
      <div>
        <VueDatePicker
          :placeholder="t('Order.StartDate_15')"
          :enable-time-picker="false"
          range
          :select-text="t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="startDateRange"
          class="tw-w-full tw-text-sm"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <!-- Ends After -->
      <div>
        <input
          type="number"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="ends_after"
          :placeholder="t('Order.EndsAfter_22')"
          v-model="currentFilter.end_after"
        />
      </div>

      <!-- Released By -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="released_by"
          :placeholder="t('Order.ReleasedBy_78')"
          v-model="currentFilter.released_by"
        />
      </div>

      <!-- Created By -->
      <div>
        <input
          type="text"
          class="tw-w-full tw-px-2 tw-py-1 tw-border tw-rounded tw-text-sm"
          id="created_by"
          :placeholder="t('Order.CreatedBy_140')"
          v-model="currentFilter.created_by"
        />
      </div>

      <!-- Created On -->
      <div>
        <VueDatePicker
          :placeholder="t('Order.CreatedOn_139')"
          :enable-time-picker="false"
          range
          :select-text="t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="createdOnRange"
          class="tw-w-full tw-text-sm"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <!-- Project -->
      <div>
        <LegacyMultiSelect
          v-model="currentFilter.project"
          name="projects"
          :object="false"
          :options="projectsList"
          :resolve-on-load="true"
          :min-chars="0"
          :placeholder="t('Order.SelectProject_5')"
          :closeOnSelect="true"
          :clearOnBlur="false"
          :searchable="true"
          valueProp="id"
          label="name"
          class="tw-w-full tw-text-sm"
        />
      </div>

      <!-- Current Balance -->
      <div>
        <div class="tw-flex">
          <input
            type="number"
            v-model.number="currentFilter.current_balance_min"
            class="tw-w-1/2 tw-px-2 tw-py-1 tw-border tw-rounded-l tw-text-sm"
            :placeholder="t('Order.CurrentBalanceMin_157')"
          />
          <input
            type="number"
            v-model.number="currentFilter.current_balance_max"
            class="tw-w-1/2 tw-px-2 tw-py-1 tw-border-t tw-border-b tw-border-r tw-rounded-r tw-text-sm"
            :placeholder="t('Order.CurrentBalanceMax_158')"
          />
        </div>
      </div>

      <!-- Gross Euro -->
      <div>
        <div class="tw-flex">
          <input
            type="number"
            v-model.number="currentFilter.gross_euro_min"
            class="tw-w-1/2 tw-px-2 tw-py-1 tw-border tw-rounded-l tw-text-sm"
            :placeholder="t('Order.GrossEurMin_159')"
          />
          <input
            type="number"
            v-model.number="currentFilter.gross_euro_max"
            class="tw-w-1/2 tw-px-2 tw-py-1 tw-border-t tw-border-b tw-border-r tw-rounded-r tw-text-sm"
            :placeholder="t('Order.GrossEurMax_160')"
          />
        </div>
      </div>

      <!-- Clarification Requested Only -->
      <div>
        <MazCheckbox
          v-model="currentFilter.clarification_requested_only"
          size="mini"
          color="success"
        >
          <span class="tw-text-sm">{{
            t("Order.ShowClarificationRequestedOnly_144")
          }}</span>
        </MazCheckbox>
      </div>
    </div>
    <div class="tw-flex tw-justify-end">
      <button
        class="tw-bg-primary-300 tw-text-black tw-px-3 tw-py-3 tw-rounded tw-mt-3 tw-text-sm"
        @click="applyFilter()"
      >
        {{ t("Global.Apply_16") }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { formatDate, presetRanges } from "@/_helpers/utils/date";
import { storeToRefs } from "pinia";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";
import { useProjectStore } from "@/stores/project.store";

import moment from "moment/moment";
import { useI18n } from "vue-i18n";
const { getProjectsByCustomer } = useProjectStore();

const { t } = useI18n();

const emit = defineEmits(["apply"]);

const { RecurringFilterOptions } = storeToRefs(useRecurringInstructionStore());
const { setOptions } = useRecurringInstructionStore();
const currentFilter = ref({
  project: "",
  project_id: "",
  customer: "",
  supplier_id: "",
  created_by: "",
  current_balance_max: "",
  current_balance_min: "",
  document_no_internal: "",
  end_after: "",
  frequency: "",
  gross_euro_max: "",
  gross_euro_min: "",
  name: "",
  ordering: "",
  page: "",
  page_size: "",
  recurring_number: "",
  released_by: "",
  search: "",
  created_on_after: "",
  created_on_before: "",
  start_date_after: "",
  start_date_before: "",
  status: "",
  supplier: "",
  clarification_requested_only: false,
});

const applyFilter = () => {
  setOptions(currentFilter.value);
  emit("apply", currentFilter.value);
};

const frequencyOptions = [
  { name: "Daily", value: "DAILY" },
  { name: "Weekly", value: "WEEKLY" },
  { name: "Monthly", value: "MONTHLY" },
  { name: "Quarterly", value: "QUARTERLY" },
  { name: "Yearly", value: "YEARLY" },
];
let projectsList: any = ref([]);

const startDateRange: any = ref(null);
const createdOnRange: any = ref(null);
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

onMounted(async () => {
  currentFilter.value = { ...RecurringFilterOptions.value };
  const selectedCustomerId = localStorage.getItem("selectedCustomerId");
  if (selectedCustomerId) {
    await getProjectsTableList(selectedCustomerId);
  }
  startDateRange.value = [
    currentFilter.value.start_date_after
      ? moment(currentFilter.value.start_date_after)
      : "",
    currentFilter.value.start_date_before
      ? moment(currentFilter.value.start_date_before)
      : "",
  ];
  createdOnRange.value = [
    currentFilter.value.created_on_after
      ? moment(currentFilter.value.created_on_after)
      : "",
    currentFilter.value.created_on_before
      ? moment(currentFilter.value.created_on_before)
      : "",
  ];
});

watch(startDateRange, (newVal) => {
  if (newVal) {
    currentFilter.value.start_date_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.start_date_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.start_date_after = "";
    currentFilter.value.start_date_before = "";
  }
});

watch(createdOnRange, (newVal) => {
  if (newVal) {
    currentFilter.value.created_on_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.created_on_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.created_on_after = "";
    currentFilter.value.created_on_before = "";
  }
});
</script>
