<template>
  <div class="create-page">
    <h4 class="page-header">{{ t("Suppliers.EditMasterPage_32") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ t("Suppliers.SupplierInfo_2") }}</div>

      <form
        @submit="onSubmitSupplierForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <!-- Number and Name -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="supplierNumber" class="form-label">
              {{ t("Suppliers.SupplierNumber_72") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="supplierNumber"
              id="supplierNumber"
              name="supplierNumber"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierNumber }"
              :placeholder="t('Suppliers.TypeNumberHere_73') + '...'"
            />
            <div class="invalid-feedback">{{ errors.supplierNumber }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="supplierName" class="form-label">
              {{ t("Suppliers.SupplierName_3") }}
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="supplierName"
              id="supplierName"
              name="supplierName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierName }"
              :placeholder="t('Suppliers.TypeNameHere_4') + '...'"
            />
            <div class="invalid-feedback">{{ errors.supplierName }}</div>
          </div>
        </div>

        <!-- Address and Zip code -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="address" class="form-label">
              {{ t("Suppliers.Address_5") }}
            </label>
            <input
              v-model="address"
              id="address"
              name="address"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.address }"
              :placeholder="t('Suppliers.TypeAddressHere_6') + '...'"
            />
            <div class="invalid-feedback">{{ errors.address }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="zipCode" class="form-label">
              {{ t("Suppliers.ZipCode_7") }}
            </label>
            <input
              v-model="zipCode"
              id="zipCode"
              name="zipCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.zipCode }"
              :placeholder="t('Suppliers.TypeZipCodeHere_8') + '...'"
            />
            <div class="invalid-feedback">{{ errors.zipCode }}</div>
          </div>
        </div>

        <!-- Country and City  -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="country" class="form-label">{{
              t("Suppliers.Country_11")
            }}</label>
            <LegacyMultiSelect
              v-model="country"
              id="country"
              name="country"
              mode="single"
              :options="countriesList"
              valueProp="name"
              label="displayed_name"
              :searchable="true"
              :placeholder="t('Suppliers.TypeCountryHere_12') + '...'"
              :clearOnBlur="false"
              :class="{ 'is-invalid': errors.country }"
            />
            <div class="invalid-feedback">{{ errors.country }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="city" class="form-label">
              {{ t("Suppliers.City_9") }}
            </label>
            <LegacyMultiSelect
              v-model="city"
              id="city"
              name="city"
              mode="single"
              :noOptionsText="
                country
                  ? t('Suppliers.NoCitiesInThisCountry_31')
                  : t('Suppliers.PleaseSelectCountryFirst_26')
              "
              :options="citiesList"
              valueProp="name"
              label="name"
              :searchable="true"
              :placeholder="t('Suppliers.TypeCityHere_10') + '...'"
              :clearOnBlur="false"
              :class="{ 'is-invalid': errors.city }"
            />
            <div class="invalid-feedback">{{ errors.city }}</div>
          </div>
        </div>

        <!-- Phone and Email -->
        <div class="row justify-content-between mb-4">
          <div class="form-group col-lg-5">
            <label for="phone" class="form-label">{{
              t("Suppliers.Phone_13")
            }}</label>
            <PhoneNumberInput
              v-model="phone"
              id="phone"
              name="phone"
              size="sm"
              show-code-on-list
              defaultCountryCode="DE"
              :preferredCountries="['DE', 'EG']"
              :class="{ 'is-invalid': errors.phone }"
            />
            <div class="invalid-feedback">{{ errors.phone }}</div>
          </div>

          <div class="form-group col-lg-5">
            <label for="supplierEmail" class="form-label">
              {{ t("Suppliers.Email_15") }}
            </label>
            <input
              v-model="supplierEmail"
              id="supplierEmail"
              name="supplierEmail"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierEmail }"
              :placeholder="t('Suppliers.TypeEmailHere_16') + '...'"
            />
            <div class="invalid-feedback">{{ errors.supplierEmail }}</div>
          </div>
        </div>

        <div
          v-if="supplierUpdatedSuccessfully != null"
          class="result-alert mt-4"
        >
          <div
            v-if="supplierUpdatedSuccessfully"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ t("Suppliers.SupplierUpdatedSuccessfully_33") }}
          </div>
          <div
            v-if="!supplierUpdatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div class="create-item-footer mt-4">
          <button
            type="submit"
            class="btn btn-primary me-2"
            :disabled="isSubmitting"
          >
            {{ t("Suppliers.EditSupplier_34") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="backToSuppliersList"
            :disabled="isSubmitting"
          >
            {{ t("Suppliers.Discard_19") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch, type Ref } from "vue";
import router from "@/router";

import { useSupplierStore } from "@/stores/supplier.store";

import { useSupplierForm } from "@/_helpers/composables/master-data/supplier-changing.composable";
import { useWorldStore } from "@/stores/world.store";
import { useCustomerStore } from "@/stores/customer.store";

import type { Supplier } from "@/_helpers/interfaces/suppliers/supplier.interface";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  supplierId: String, // Route param
});

const {
  errors,
  handleSubmit,
  isSubmitting,
  setValues,
  supplierNumber,
  supplierName,
  address,
  zipCode,
  country,
  city,
  setCityValue,
  resetCity,
  phone,
  supplierEmail,
  countriesList,
  citiesList,
  backToSuppliersList,
  setFieldValue,
} = useSupplierForm();

const { editSupplier, getSupplierById } = useSupplierStore();
const { getCitiesListByCountry } = useWorldStore();
const { getCustomersOptions } = useCustomerStore();

const currentSupplier: Ref<Supplier | null> = ref(null);

const supplierUpdatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
let customersList: any = ref([]);

const onSubmitSupplierForm = handleSubmit(async (values) => {
  try {
    await editSupplier(+props.supplierId!, {
      supplier_number: values.supplierNumber,
      name: values.supplierName,
      address: values.address,
      zip_code: values.zipCode!,
      city: values.city!,
      country: values.country!,
      phone_number: values.phone,
      email: values.supplierEmail,
      customer: values.customer!,
    });

    backToSuppliersList();
  } catch (error: any) {
    supplierUpdatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

onMounted(async () => {
  validateRouteParam();
  const selectedCustomerId = localStorage.getItem("selectedCustomerId");

  currentSupplier.value = await getSupplierById(+props.supplierId!);
  customersList.value = await getCustomersOptions();
  if (currentSupplier.value?.customer !== parseInt(selectedCustomerId!)) {
    router.push({ name: "list-suppliers" });
  }
  setValues({
    supplierNumber: currentSupplier.value?.supplier_number,
    supplierName: currentSupplier.value?.name,
    address: currentSupplier.value?.address,
    zipCode: currentSupplier.value?.zip_code,
    country: currentSupplier.value?.country,
    phone: currentSupplier.value?.phone_number || "",
    supplierEmail: currentSupplier.value?.email,
    customer: currentSupplier.value?.customer!,
  });

  await nextTick();

  setCityValue(currentSupplier.value.city as any);
});

watch(country, async (selectedCountry: string | null) => {
  resetCity();
  if (selectedCountry)
    citiesList.value = await getCitiesListByCountry(selectedCountry);
});

const validateRouteParam = () => {
  if (isNaN(+props.supplierId!)) {
    // TODO: navigate to not found page
    router.push({ name: "list-suppliers" });
  }
};
</script>

<style scoped lang="scss">
.create-page {
  .create-page-content {
    padding: 2rem;
    border-radius: 0.5rem;
  }

  .item-data-header {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .form-label {
    font-weight: 500;
  }

  .form-control,
  .form-select {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .create-item-footer {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
