<template>
  <div class="card">
    <div class="card-body">
      <div class="title-and-upload">
        <div class="card-title">{{ t("UserProfile.MainInfo_4") }}</div>

        <div class="upload-profile-picture">
          <label for="uploadProfilePicture" class="btn">
            <i class="icon-upload"></i>
            {{ t("UserProfile.ProfilePicture_5") }}
          </label>

          <input
            type="file"
            id="uploadProfilePicture"
            accept="image/png, image/jpeg"
            @change="onProfilePictureUploaded"
          />
        </div>
      </div>

      <div class="main-info-content">
        <img
          class="user-image"
          :src="
            currentUser?.profile_picture
              ? currentUser?.profile_picture
              : defaultProfilePicture
          "
          alt="User Image"
        />

        <div class="user-info">
          <span v-if="!editUserName" class="user-name">
            {{ currentUser?.first_name }} {{ currentUser?.last_name }}
            <i class="icon-edit" @click="changeEditMode"></i>
          </span>

          <div v-else class="name-editing-block">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :value="currentUser?.first_name"
                ref="firstNameInputValue"
              />

              <input
                type="text"
                class="form-control"
                :value="currentUser?.last_name"
                ref="lastNameInputValue"
              />
            </div>

            <button
              class="btn confirm-editing text-success"
              @click="confirmNameEditing"
            >
              ✓
            </button>
            <button
              class="btn cancel-editing text-danger"
              @click="changeEditMode"
            >
              ✖
            </button>
          </div>

          <span class="user-role" :title="currentUser?.user_role.join(', ')">
            {{ currentUser?.user_role.join(", ") }}
          </span>

          <div class="customers-and-projects">
            <div class="item-info">
              <span class="item-number">{{ currentUser?.projects_count }}</span>
              <span class="item-text">{{
                t("UserProfile.AssignedProjects_6")
              }}</span>
            </div>

            <div class="item-info">
              <span class="item-number">{{
                currentUser?.customers_count
              }}</span>
              <span class="item-text">{{
                t("UserProfile.TotalCustomers_7")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import defaultProfilePicture from "@/assets/images/user-avatar.jpg";

import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { useFileValidation } from "@/_helpers/composables/file-validation.composable";
import { ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { currentUser } = storeToRefs(useUserStore());
const { editUserProfile } = useUserStore();

const firstNameInputValue = ref();
const lastNameInputValue = ref();
const editUserName = ref(false);
const isPictureValid: Ref<boolean | null> = ref(null);

const onProfilePictureUploaded = async (e: any) => {
  const pictureFile: File = e.target.files[0];
  const { validateSize, validateTypeAndExtension } = useFileValidation();
  isPictureValid.value =
    validateSize(pictureFile, 0, 5) && validateTypeAndExtension(pictureFile);

  if (isPictureValid.value) {
    await editUserProfile({ profilePicture: pictureFile });
  }
};

const changeEditMode = () => {
  editUserName.value = !editUserName.value;
};

const confirmNameEditing = async () => {
  const enteredFirstName = firstNameInputValue.value.value;
  const enteredLastName = lastNameInputValue.value.value;

  await editUserProfile({
    userFirstName: enteredFirstName,
    userLastName: enteredLastName,
  });

  changeEditMode();
};
</script>

<style scoped lang="scss">
@import "@/components/my-profile/main-info.scss";
</style>
