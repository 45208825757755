<template>
  <div class="mb-4">
    <div class="form-group">
      <div class="row mb-4">
        <div class="col-6">
          <h5>{{ t("Order.DownloadAs_86") }}</h5>
          <div class="export-options flex-container">
            <div class="export-option">
              <label for="pdf">
                <input
                  type="radio"
                  id="pdf"
                  value="pdf"
                  v-model="exportFormat"
                />
                PDF
              </label>
            </div>
            <div class="export-option">
              <label for="xlsx">
                <input
                  type="radio"
                  id="xlsx"
                  value="xlsx"
                  v-model="exportFormat"
                />
                XLSX
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <h5>{{ t("Order.FieldsToExport_165") }}</h5>
        <LegacyMultiSelect
          v-model="selectedFields"
          searchable
          object
          mode="multiple"
          name="Fields"
          :options="props.fields"
          :multiple="true"
          :placeholder="t('Order.FieldsToExport_165')"
          :multipleLabel="
            (values) => `${values.length} ${t('Order.FieldsSelected_166')}`
          "
        >
        </LegacyMultiSelect>
      </div>
      <div class="col-12">
        <div class="mt-4">
          <span
            class="order-badge field-badge m-1"
            v-for="(field, index) in selectedFields"
            :key="index"
          >
            {{ field.label }}
            <button @click="removeField(index)">
              <i class="icon-close trash-icon"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="float-end mb-2 mt-5">
      <button
        class="btn btn-primary"
        @click="onExport"
        :disabled="selectedFields.length === 0"
      >
        {{ t("Order.Export_85") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const exportFormat = ref("pdf");

const { t } = useI18n();

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["export"]);

const selectedFields: any = ref([]);
const removeField = (index) => {
  selectedFields.value.splice(index, 1);
};

const onExport = () => {
  const data = {
    exportFormat: exportFormat.value,
    fields: selectedFields.value.map((field) => field.value),
  };
  emit("export", data);
};
</script>

<style scoped>
.field-badge {
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}
.trash-icon {
  cursor: pointer;
  color: red;
  font-size: 12px;
}
</style>
