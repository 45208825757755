<template>
  <div
    class="tw-flex tw-justify-center tw-items-center tw-h-[200px] tw-pb-16 tw-mx-12"
  >
    <div
      class="tw-animate-pulse tw-flex tw-justify-between tw-w-full tw-items-end"
    >
      <div class="tw-h-24 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-32 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-40 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-28 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-36 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-20 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-32 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-52 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-10 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-12 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-44 tw-bg-gray-200 tw-rounded tw-w-12"></div>
      <div class="tw-h-16 tw-bg-gray-200 tw-rounded tw-w-12"></div>
    </div>
  </div>
</template>
