import { defineStore } from "pinia";
import axios from "axios";

export const useProjectStore = defineStore("project", () => {
  // actions

  // Post Project in Create Project
  const createProject = async (formData: any): Promise<any> => {
    try {
      const { data } = await axios.post(
        `/customers/customer_projects/`,
        formData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get All Projects in Projects listing
  const getProjects = async (options: any, filter: any): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/customers/customer_projects/?${filter}`,
        {
          params: options,
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Get Projects for view customer
  const getProjectsByCustomer = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/customer_projects/`, {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Get Project in Create Project By Id
  const getProjectById = async (id: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/customer_projects/${id}/`);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Update Project in Create Project
  const updateProject = async (formData: any, id: any): Promise<any> => {
    try {
      const { data } = await axios.put(
        `/customers/customer_projects/${id}/`,
        formData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Delete Project from Projects listing
  const deleteProject = async (deleteData: any): Promise<any> => {
    try {
      const { data } = await axios.post(
        `/customers/customer_projects/deactivate/`,
        {
          projects: deleteData,
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return {
    createProject,
    getProjects,
    getProjectsByCustomer,
    getProjectById,
    updateProject,
    deleteProject,
  };
});
