<template>
  <div class="customer">
    <h1 class="page-title">{{ t("Order.OrderDetail_60") }}</h1>
    <!-- Delete Popup -->
    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>{{ t("Order.DeletePopupTitle_81") }} ?</h5>
          <div class="options">
            <button class="save" @click="onChangeOrderDelete">
              {{ t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  clarifiation request popup  -->
    <div class="generic-popup" v-if="showClarificationPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ t("Order.OrderComfirmClarification_137") }}
          </h5>
          <div class="textarea-wrapper mb-4">
            <textarea
              v-model="clarificationComment"
              class="border w-100 px-2 py-3 rounded"
              :placeholder="t('Order.TypeYourMessageHere_128')"
              rows="6"
            ></textarea>
          </div>
          <div class="options">
            <button
              class="save"
              style="max-width: 250px; width: 100%"
              @click="clarificationOrder(order.id)"
            >
              {{ t("Global.SendClarification_19") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--    -->
    <div class="row">
      <!-- Left Side -->
      <div class="col-lg-7 col-12">
        <!-- Detail -->
        <div class="form-wrapper mb-4">
          <div class="generic-form">
            <div class="row mb-4">
              <div class="col-12">
                <!-- Order Type -->
                <span>
                  {{ t("Order.RecurringOrder_107") }}
                </span>
                <!-- Order Badge -->
                <span
                  class="order-badge mx-3"
                  :class="
                    order.status_display === 'APPROVED' ||
                    order.status_display === 'CLEARING'
                      ? 'approved-badge'
                      : order.status_display === 'REJECTED'
                      ? 'reject-badge'
                      : order.status_display === 'DRAFT'
                      ? 'draft-badge'
                      : order.status_display === 'PENDING_APPROVAL'
                      ? 'pending-badge'
                      : ''
                  "
                >
                  {{ getOrderStatusDisplay() }}
                </span>
                <span
                  class="order-badge mx-1 pending-badge"
                  v-if="order.is_clarification_requested"
                >
                  Clarification Requested
                </span>
                <!-- Action Buttons -->
                <button
                  class="mx-2 action-btn"
                  v-if="showEditOrderBtn()"
                  @click="editOrder(order.id)"
                >
                  <i class="icon-edit me-2"></i>
                  <span> Edit </span>
                </button>
                <button
                  class="mx-2 action-btn"
                  v-if="
                    order.status === 'DRAFT' &&
                    order.released_by?.id === currentUser?.id
                  "
                  @click="openDeletePopup()"
                >
                  <i class="icon-delete me-2"></i>
                  <span> {{ t("Order.Delete_63") }} </span>
                </button>
                <!-- Approve -->
                <button
                  class="mx-2 action-btn action-approve"
                  v-if="showApproveOrRejectAction()"
                  @click="approveSingleOrder(order.id)"
                >
                  <i class="icon-document-approve me-2"></i>
                  <span> Approve </span>
                </button>
                <!-- Request Clarification -->
                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRequestClarificationAction()"
                  @click="showClarificationPopup = true"
                >
                  <i class="icon-document-clarification me-2"></i>
                  <span> Request Clarification </span>
                </button>
                <button
                  class="mx-2 action-btn action-request"
                  v-if="showRespondToClarificationAction()"
                  @click="respondToClarification(order.id)"
                >
                  <MazIcon
                    name="check-circle"
                    size="1.2rem"
                    path="/icons"
                    class="check-icon"
                  />
                  <span>
                    {{ t("Order.MarkClarificationCompleted_145") }}
                  </span>
                </button>
                <!-- Reject -->
                <button
                  class="ms-2 action-btn action-reject"
                  v-if="showApproveOrRejectAction()"
                  @click="rejectOrder(order.id)"
                >
                  <i class="icon-document-reject me-2"></i>
                  <span> Reject </span>
                </button>
              </div>
            </div>
            <!-- Table -->
            <div class="row">
              <div class="col-lg-12">
                <RecurringPurchase :order="order" />
              </div>
            </div>
            <!-- Table -->
          </div>
        </div>
        Attachments
        <Attachments :order="order" />
        Self Notes
        <SelfNotes :recurring-instruction="order" />
      </div>
      <!-- Right Side -->
      <div class="col-lg-5 col-12">
        <Comments :recurring-instruction="order" />
        <RelatedOrders v-if="order?.id" :recurring-instruction-id="order?.id">
        </RelatedOrders>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import SelfNotes from "@/components/order-details/self-notes/SelfNotes.vue";
import Comments from "@/components/order-details/comments/Comments.vue";
import MazIcon from "maz-ui/components/MazIcon";
import RelatedOrders from "@/components/order-details/related-orders/RelatedOrders.vue";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";
import Attachments from "@/components/order-details/attachments/Attachments.vue";
import RecurringPurchase from "@/components/order-details/recurring-purchase/RecurringPurchase.vue";
import { useI18n } from "vue-i18n";

const router = useRouter();
const {
  getRecurringInstructionById,
  approveRecurringInstruction,
  rejectRecurringInstruction,
  requestRecurringInstructionClarification,
  respondToRecurringInstructionClarification,
} = useRecurringInstructionStore();

const { currentUser } = storeToRefs(useUserStore());

const toast = useToast();
const { t } = useI18n();

const order: any = ref({
  id: "",
  recurring_number: "",
  recurring_number_without_prefix: "",
  customer_name: "",
  customer: "",
  supplier: "",
  supplier_name: "",
  project: "",
  project_name: "",
  document_no_internal: null,
  name: "",
  frequency: "",
  service_date: "",
  start_date: "",
  end_after: "",
  current_balance: "",
  amount: "",
  created_on: "",
  created_by_name: "",
  created_by: "",
  released_by_name: "",
  released_by: "",
  status: "",
  files: [],
});

const orderId: any = ref(null);

const isPopup = ref(false);

const showClarificationPopup = ref(false);

const clarificationComment = ref("");

const editOrder = (id: any) => {
  localStorage.setItem("isEditRecurringOrder", "true");
  localStorage.setItem("recurringOrderId", id);
  router.push({ name: "create-recurring-order", query: { order: id } });
};

const approveSingleOrder = async (id: any) => {
  await approveRecurringInstruction(id);
  order.value.status = "APPROVED";
  order.value.status_display = "APPROVED";
  toast.open({
    message: "Recurring Instruction and it's children have been approved",
    type: "success",
  });
};

const clarificationOrder = async (id: any) => {
  await requestRecurringInstructionClarification(
    id,
    clarificationComment.value
  );
  order.value.is_clarification_requested = true;
  closePopup();
  toast.open({
    message: "Clarification has been requested for the recurring instruction",
    type: "success",
  });
};

const respondToClarification = async (id: any) => {
  await respondToRecurringInstructionClarification(id);
  order.value.is_clarification_requested = false;
  toast.open({
    message: "Clarification has been completed for the recurring instruction",
    type: "success",
  });
};

const rejectOrder = async (id: any) => {
  await rejectRecurringInstruction(id);
  order.value.status = "REJECTED";
  order.value.status_display = "REJECTED";
  toast.open({
    message: "Recurring Instruction and it's children have been rejected",
    type: "success",
  });
};

const closePopup = () => {
  isPopup.value = false;
  showClarificationPopup.value = false;
};

const openDeletePopup = () => {
  isPopup.value = true;
};
//
const onChangeOrderDelete = async () => {
  closePopup();
  // await deleteOrder(order.value.id);
  router.push({ name: "orders" });
};

const getOrderStatusDisplay = () => {
  if (order.value.status_display === "APPROVED") {
    // use translation
    return t("Order.Approved_62");
  } else if (order.value.status_display === "REJECTED") {
    return t("Order.Rejected_65");
  } else if (order.value.status_display === "DRAFT") {
    return t("Order.Draft_66");
  } else if (order.value.status_display === "PENDING_APPROVAL") {
    return t("Order.Pending_90");
  } else if (
    order.value.status_display === "CLEARING" &&
    !order.value.is_restrict
  ) {
    return t("Order.Clearing_141");
  } else if (
    order.value.status_display === "CLEARING" &&
    order.value.is_restrict
  ) {
    return `${t("Order.Clearing_141")} (${
      order.value.total_clearer_approved || 0
    } / ${order.value.total_clearer || 0})`;
  }
  return "";
};

const showEditOrderBtn = () => {
  return (
    (order.value.status === "DRAFT" &&
      order.value.released_by?.id === currentUser.value?.id) ||
    (order.value.is_clarification_requested &&
      order.value.created_by === currentUser.value?.id)
  );
};

const showApproveOrRejectAction = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return ["PENDING_APPROVAL", "CLEARING"].includes(
      order.value.status_display
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return order.value.status_display === "PENDING_APPROVAL";
  }
  if (currentUser.value?.user_role.includes("Order Clearer")) {
    return order.value.status_display === "PENDING_APPROVAL";
  }
  return false;
};

const showRequestClarificationAction = () => {
  if (
    currentUser.value?.user_role.includes("Admin") ||
    currentUser.value?.user_role.includes("Approver") ||
    currentUser.value?.user_role.includes("Order Clearer")
  ) {
    return (
      ["PENDING_APPROVAL", "CLEARING"].includes(order.value.status_display) &&
      !order.value.is_clarification_requested
    );
  }
  return false;
};

const showRespondToClarificationAction = () => {
  return (
    order.value.is_clarification_requested &&
    (currentUser.value?.id === order.value.created_by ||
      currentUser.value?.id === order.value.requested_clarification_by_id)
  );
};

onMounted(async () => {
  orderId.value = +router.currentRoute.value.params.id;
  /* Get Order from ID */
  if (
    router.currentRoute.value.name === "recurring-order" &&
    isNaN(+orderId.value)
  ) {
    return router.push({ name: "recurring-orders" });
  }
  order.value = await getRecurringInstructionById(orderId.value);
});

watch(
  () => router.currentRoute.value.params.id,
  async (newVal) => {
    if (
      router.currentRoute.value.name === "recurring-order" &&
      isNaN(+newVal)
    ) {
      return router.push({ name: "recurring-orders" });
    } else if (router.currentRoute.value.name === "recurring-order") {
      order.value = await getRecurringInstructionById(newVal.toString());
    }
  }
);
</script>

<style scoped>
.order-badge {
  font-size: 14px;
  line-height: 28px;
  padding: 4px 8px;
  border-radius: 15px;
}

.approved-badge {
  background-color: #d7f6ec;
  color: #3dd5a5;
}

.reject-badge {
  background-color: #fde2e8;
  color: #f86584;
}

.draft-badge {
  background-color: #727cf3;
  color: #ffffff;
}

.action-btn {
  font-size: 15px;
  line-height: 28px;
  padding: 4px 8px;
  margin: 10px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.check-icon {
  color: #0dcaf0;
  margin-right: 8px;
}
</style>
