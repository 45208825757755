<template>
  <apexchart
    :series="ordersChartSeries"
    :options="ordersChartOptions"
  ></apexchart>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const ordersChartSeries = ref<number[]>([]);
const ordersChartOptions = ref({
  chart: {
    type: "donut",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#21bf6b", "#f5ba30", "#e93a57"],
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          total: {
            label: t("Dashboard.TotalOrders_12"),
            show: true,
            showAlways: true,
            fontSize: "12px",
            fontFamily: "Nunito",
            fontWeight: "normal",
            color: "#525252",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
  labels: [
    t(`Dashboard.ApprovedOrders_18`),
    t(`Dashboard.PendingOrders_13`),
    t(`Dashboard.RejectedOrders_19`),
  ],
  legend: {
    show: true,
    position: "left",
    floating: false,
    offsetY: 50,
    fontSize: "12px",
    fontFamily: "Nunito",
    fontWeight: "normal",
    formatter: function (seriesName, opts) {
      return [
        seriesName,
        " - ",
        opts.w.globals.series[opts.seriesIndex],
        " <br /> ",
      ];
    },
    width: "50px",
    height: "50px",
    labels: {
      useSeriesColors: true,
    },
  },
});

const props = defineProps<{
  ordersChartStatistics: {
    total_orders: number;
    pending_orders: number;
    approved_orders: number;
    rejected_orders: number;
  };
}>();

watch(
  () => props.ordersChartStatistics,
  (value) => {
    ordersChartSeries.value = [
      value.approved_orders ?? 0,
      value.pending_orders ?? 0,
      value.rejected_orders ?? 0,
    ];
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss"></style>
