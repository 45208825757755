import moment from "moment";
import { ref } from "vue";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";

const formatDate = (date: Date) => {
  const date1 = moment(date[0]).format("DD.MM.YYYY");
  let date2 = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    date2 = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${date1} To ${date2}`;
};

const presetRanges = ref([
  {
    label: "Last Week",
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
    slot: "last_week",
  },
  {
    label: "This month",
    range: [startOfMonth(new Date()), endOfMonth(new Date())],
    slot: "this_month",
  },
  {
    label: "Last month",
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
    slot: "last_month",
  },
  {
    label: "Last 30 days",
    range: [subDays(new Date(), 30), new Date()],
    slot: "last_30_days",
  },
  {
    label: "Last Year",
    range: [
      startOfYear(subYears(new Date(), 1)),
      endOfYear(subYears(new Date(), 1)),
    ],
    slot: "last_year",
  },
  {
    label: "This year",
    range: [startOfYear(new Date()), endOfYear(new Date())],
    slot: "yearly",
  },
]);

export { formatDate, presetRanges };
