<template>
  <form class="generic-form" @submit.prevent="onSubmit">
    <div class="form-group mt-4">
      <h4 class="group-title">
        {{ t("CustomerManagement.ChooseOrderTemplate_25") }}
      </h4>
      <div class="row justify-content-between">
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.MandatoryFieldsInForm_27")
            }}</label>
            <LegacyMultiSelect
              v-model="$v.orderMandatoryValue.$model"
              :class="{ error: $v.orderMandatoryValue.$error }"
              name="orderMandatory"
              mode="tags"
              :object="true"
              :options="orderMandatoryList"
              :resolve-on-load="true"
              :min-chars="0"
              :placeholder="t('CustomerManagement.ChooseOrderField_29')"
              :closeOnSelect="false"
              :clearOnBlur="false"
              :searchable="true"
              valueProp="id"
              label="field_name"
            />
            <span
              v-if="$v.orderMandatoryValue.$error"
              class="form__error-msg"
              >{{
                t("CustomerManagement.MandatoryOrderFieldRequired_31")
              }}</span
            >
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.OptionalFieldsInForm_28")
            }}</label>
            <LegacyMultiSelect
              v-model="$v.orderOptionalValue.$model"
              :class="{ error: $v.orderOptionalValue.$error }"
              name="orderOptional"
              mode="tags"
              :object="true"
              :options="orderOptionalList"
              :resolve-on-load="true"
              :min-chars="0"
              :placeholder="t('CustomerManagement.ChooseOrderField_29')"
              :closeOnSelect="false"
              :clearOnBlur="false"
              :searchable="true"
              valueProp="id"
              label="field_name"
            />
            <span v-if="$v.orderOptionalValue.$error" class="form__error-msg">{{
              t("CustomerManagement.OptionalOrderFieldRequired_32")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4 class="group-title">
        {{ t("CustomerManagement.ChoosePaymentTemplate_26") }}
      </h4>
      <div class="row justify-content-between">
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.MandatoryFieldsInForm_27")
            }}</label>
            <LegacyMultiSelect
              v-model="$v.paymentMandatoryValue.$model"
              :class="{ error: $v.paymentMandatoryValue.$error }"
              class="second-color-tag"
              name="paymentMandatory"
              mode="tags"
              :object="true"
              :options="paymentMandatoryList"
              :resolve-on-load="true"
              :min-chars="0"
              :placeholder="t('CustomerManagement.ChoosePaymentField_30')"
              :closeOnSelect="false"
              :clearOnBlur="false"
              :searchable="true"
              valueProp="id"
              label="field_name"
            />
            <span
              v-if="$v.paymentMandatoryValue.$error"
              class="form__error-msg"
              >{{
                t("CustomerManagement.MandatoryPaymentFieldRequired_33")
              }}</span
            >
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              t("CustomerManagement.OptionalFieldsInForm_28")
            }}</label>
            <LegacyMultiSelect
              v-model="$v.paymentOptionalValue.$model"
              :class="{ error: $v.paymentOptionalValue.$error }"
              class="second-color-tag"
              name="paymentOptional"
              mode="tags"
              :object="true"
              :options="paymentOptionalList"
              :resolve-on-load="true"
              :min-chars="0"
              :placeholder="t('CustomerManagement.ChoosePaymentField_30')"
              :closeOnSelect="false"
              :clearOnBlur="false"
              :searchable="true"
              valueProp="id"
              label="field_name"
            />
            <span
              v-if="$v.paymentOptionalValue.$error"
              class="form__error-msg"
              >{{
                t("CustomerManagement.OptionalPaymentFieldRequired_34")
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="isCreatedSuccessfully != null">
        <div
          v-if="isCreatedSuccessfully"
          class="col-md-5 alert alert-success"
          role="alert"
        >
          {{
            isEditMode
              ? t("CustomerManagement.FormsOrderEditedSuccessfully_99")
              : t("CustomerManagement.FormsOrderCreatedSuccessfully_81")
          }}
        </div>
        <div v-else class="col-md-5 alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-options">
          <router-link :to="{ name: 'customers' }" class="cancel-btn">{{
            t("CustomerManagement.CancelDiscard_69")
          }}</router-link>
          <button
            v-if="isPrevious && isEditMode"
            @click="onPrevious"
            class="previous"
            type="submit"
          >
            {{ t("CustomerManagement.Previous_88") }}
          </button>
          <button class="submit-btn" type="submit">
            {{
              isPrevious
                ? t("CustomerManagement.SaveContinue_70")
                : t("CustomerManagement.Save_89")
            }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, type Ref } from "vue";
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useCustomerStore } from "@/stores/customer.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const {
  getOrderFields,
  getPaymentsFields,
  createFormOrder,
  getFormOrderById,
  createFormPayment,
  getFormPaymentById,
} = useCustomerStore();

const props = defineProps<{
  customerId: number | string;
  isEditMode: boolean;
  isPrevious: boolean;
  isFormWizard: boolean;
}>();

let orderMandatoryList: any = ref([]);
let orderOptionalList: any = ref([]);
let paymentMandatoryList: any = ref([]);
let paymentOptionalList: any = ref([]);

let orderData: any = ref([]);
let paymentData: any = ref([]);

const formData: any = reactive({
  orderMandatoryValue: [],
  orderOptionalValue: [],
  paymentMandatoryValue: [],
  paymentOptionalValue: [],
});

let cachedReceivedOrderValues: any = ref([]);
let cachedReceivedPaymentValues: any = ref([]);

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");

const rules = {
  orderMandatoryValue: {
    required,
  },
  orderOptionalValue: {
    required,
  },
  paymentMandatoryValue: {
    required,
  },
  paymentOptionalValue: {
    required,
  },
};

const $v = useVuelidate(rules, formData);

const changeMandatoryValue = (
  mandatoryValue: any,
  optionalValue: any,
  mandatoryList: any,
  optionalList: any,
  newValue: any,
  oldValue: any,
  type: any
) => {
  // Change selected item.is_mandatory to true in mandatory list field
  mandatoryValue.forEach((item: any) => {
    item.is_mandatory = true;
  });

  // Get removed item.id from mandatory list field
  let removedItem = oldValue.filter((item: any) => !newValue.includes(item));
  removedItem = JSON.parse(JSON.stringify(removedItem));
  let removedItemID = "";
  if (removedItem.length > 0) {
    removedItemID = removedItem[0].id;
  }

  // Change removed item.is_mandatory to false in mandatory list field
  mandatoryList.forEach((item: any) => {
    if (removedItemID == item.id) {
      item.is_mandatory = false;
    }
  });

  // Remove item from optional values when select them in mandatory values
  optionalValue = optionalValue.filter((item: any) => {
    return !mandatoryValue.includes(item);
  });

  if (type == "order") {
    formData.orderOptionalValue = optionalValue;
  } else {
    formData.paymentOptionalValue = optionalValue;
  }

  // Add & remove items to optional list field
  optionalList = mandatoryList.filter(
    (item: any) => item.is_mandatory == false
  );
  return optionalList;
};

const mergeSelectedData = () => {
  let orderValues = [
    ...formData.orderMandatoryValue,
    ...formData.orderOptionalValue,
  ];
  let paymentValues = [
    ...formData.paymentMandatoryValue,
    ...formData.paymentOptionalValue,
  ];

  orderData.value = [];
  orderValues.forEach((item: any) => {
    orderData.value.push({
      customer: props.customerId,
      field: item.id,
      is_mandatory: item.is_mandatory,
    });
  });

  paymentData.value = [];
  paymentValues.forEach((item: any) => {
    paymentData.value.push({
      customer: props.customerId,
      field: item.id,
      is_mandatory: item.is_mandatory,
    });
  });
};

const addIdToEditDataObjects = async () => {
  cachedReceivedOrderValues.value.results.forEach((receivedItem: any) => {
    orderData.value.map((orderItem: any) => {
      if (receivedItem.field.id == orderItem.field) {
        orderItem.id = receivedItem.id;
      }
    });
  });

  cachedReceivedPaymentValues.value.results.forEach((receivedItem: any) => {
    paymentData.value.map((paymentItem: any) => {
      if (receivedItem.field.id == paymentItem.field) {
        paymentItem.id = receivedItem.id;
      }
    });
  });
};

const onSubmit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    mergeSelectedData();
    if (props.isEditMode) {
      addIdToEditDataObjects();
    }
    try {
      await createFormOrder(orderData.value);
      await createFormPayment(paymentData.value);
      isCreatedSuccessfully.value = true;
      if (props.isFormWizard) {
        let nextBtn: any = document.querySelector(".fw-footer-right button");
        nextBtn.click();
      }
    } catch (error: any) {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};

watch(
  () => props.customerId,
  async () => {
    // debugger;
    if (props.isEditMode) {
      isCreatedSuccessfully.value = null;
      resetAllValues();
      await getCustomerFormOrderById();
      await getCustomerFormPaymentById();
      await getCachedData();
    }
  }
);

watch(
  () => isCreatedSuccessfully.value,
  async () => {
    // debugger;
    console.log(isCreatedSuccessfully.value);
  }
);

const resetAllValues = () => {
  formData.orderMandatoryValue = [];
  formData.orderOptionalValue = [];
  formData.paymentMandatoryValue = [];
  formData.paymentOptionalValue = [];
};

watch(
  () => formData.orderMandatoryValue,
  (newValue, oldValue) => {
    orderOptionalList.value = changeMandatoryValue(
      formData.orderMandatoryValue,
      formData.orderOptionalValue,
      orderMandatoryList.value,
      orderOptionalList.value,
      newValue,
      oldValue,
      "order"
    );
  }
);

watch(
  () => formData.paymentMandatoryValue,
  (newValue, oldValue) => {
    paymentOptionalList.value = changeMandatoryValue(
      formData.paymentMandatoryValue,
      formData.paymentOptionalValue,
      paymentMandatoryList.value,
      paymentOptionalList.value,
      newValue,
      oldValue,
      "payment"
    );
  }
);

const getCachedData = async () => {
  cachedReceivedOrderValues.value = await getFormOrderById({
    customer: props.customerId,
  });
  cachedReceivedPaymentValues.value = await getFormPaymentById({
    customer: props.customerId,
  });
};

const getCustomerFormOrderById = async () => {
  try {
    let data = await getFormOrderById({
      customer: props.customerId,
    });
    appendReceivedEditData(
      data,
      formData.orderMandatoryValue,
      formData.orderOptionalValue
    );
  } catch (e) {
    console.log(e);
  }
};

const getCustomerFormPaymentById = async () => {
  try {
    let data = await getFormPaymentById({
      customer: props.customerId,
    });
    appendReceivedEditData(
      data,
      formData.paymentMandatoryValue,
      formData.paymentOptionalValue
    );
  } catch (e) {
    console.log(e);
  }
};

const appendReceivedEditData = async (
  data: any,
  mandatory: any,
  optional: any
) => {
  data.results.forEach((order: any) => {
    if (order.is_mandatory) {
      mandatory.push(order);
    } else {
      optional.push(order);
    }
    mandatory.map((item: any) => {
      item.field_name = item.field.field_name;
      item.id = item.field.id;
      // item.is_mandatory = item.is_mandatory;
    });
    optional.map((item: any) => {
      item.field_name = item.field.field_name;
      item.id = item.field.id;
      item.is_mandatory = item.field.is_mandatory;
    });
  });
};

const onPrevious = () => {
  let prevBtn: any = document.querySelector(".fw-footer-left button");
  prevBtn.click();
};

onMounted(async () => {
  orderMandatoryList.value = orderOptionalList.value = await getOrderFields();
  paymentMandatoryList.value = paymentOptionalList.value =
    await getPaymentsFields();
  if (props.isEditMode && props.customerId != "") {
    await getCustomerFormOrderById();
    await getCustomerFormPaymentById();
    await getCachedData();
  }
});
</script>
