export const useCurrencyFormatting = () => {
  const setCurrency = (amount: any) => {
    amount = parseInt(amount).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    return amount;
  };

  const removeCurrency = (amount: any) => {
    amount = amount.replace(/[.€]/g, "");
    amount = amount.replace(/[,]/g, ".");
    return amount;
  };

  return { setCurrency, removeCurrency };
};
