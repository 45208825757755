<template>
  <input
    ref="inputRef"
    v-model="formattedValue"
    :class="errorClass"
    :placeholder="placeholder"
    type="text"
  />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import router from "@/router";

const props: any = defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
  },
  options: {
    type: Object,
    default: () => ({
      locale:
        router.currentRoute.value.params.locale === "en"
          ? navigator.language
          : "de-DE",
      currency: "EUR",
      currencyDisplay:
        router.currentRoute.value.params.locale === "en" ? "code" : "symbol",
      precision: 2,
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
      separator: ".",
      decimal: router.currentRoute.value.params.locale === "en" ? "." : ",",
    }),
  },
  placeholder: {
    type: String,
    default: "",
  },
  errorClass: {
    type: Object,
    default: () => {},
  },
});

let { inputRef, formattedValue, setValue, setOptions } = useCurrencyInput(
  props.options
);

watch(
  () => props.modelValue,
  (value: any) => {
    setValue(value);
  }
);

watch(
  () => props.options,
  (options: any) => {
    setOptions(options);
  }
);
</script>
