import { defineStore } from "pinia";
import axios from "axios";
import type { Country } from "@/_helpers/interfaces/world/country.interface";
import type { City } from "@/_helpers/interfaces/world/city.interface";

export const useWorldStore = defineStore("world", () => {
  const getCountriesList = async (): Promise<Country[]> => {
    try {
      const { data } = await axios.get(`/common/country/`, {
        params: {
          page_size: -1,
        },
      });

      return data.results;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getCitiesListByCountry = async (
    countryName: string
  ): Promise<City[]> => {
    try {
      const { data } = await axios.get(`/common/city/`, {
        params: {
          country: countryName,
          page_size: -1,
        },
      });

      return data.results;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return { getCountriesList, getCitiesListByCountry };
});
