<template>
  <apexchart
    :options="paymentsChartOptions"
    :series="paymentsChartSeries"
  ></apexchart>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const paymentsChartSeries = ref<number[]>([]);
const paymentsChartOptions = ref({
  chart: {
    type: "donut",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#21bf6b", "#f5ba30", "#e93a57"],
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          total: {
            label: t("Dashboard.TotalPayments_16"),
            show: true,
            showAlways: true,
            fontSize: "12px",
            fontFamily: "Nunito",
            fontWeight: "normal",
            color: "#525252",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
  labels: [
    t(`Dashboard.ApprovedPayments_20`),
    t(`Dashboard.PendingPayments_17`),
    t(`Dashboard.RejectedPayments_21`),
  ],
  legend: {
    show: true,
    position: "left",
    floating: false,
    offsetY: 50,
    fontSize: "12px",
    fontFamily: "Nunito",
    fontWeight: "normal",
    formatter: function (seriesName, opts) {
      return [
        seriesName,
        " - ",
        opts.w.globals.series[opts.seriesIndex],
        " <br /> ",
      ];
    },
    width: "50px",
    height: "50px",
    labels: {
      useSeriesColors: true,
    },
  },
});

const props = defineProps<{
  paymentsChartStatistics: {
    total_payments: number;
    approved_payments: number;
    pending_payments: number;
    rejected_payments: number;
  };
}>();

watch(
  () => props.paymentsChartStatistics,
  (value) => {
    paymentsChartSeries.value = [
      value.approved_payments ?? 0,
      value.pending_payments ?? 0,
      value.rejected_payments ?? 0,
    ];
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss"></style>
