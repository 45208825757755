export const useFileValidation = () => {
  const validateSize = (file: File, min: number, max: number) => {
    // min and max in MB
    if (file) {
      const fileSizeInMb = file.size / 1000 / 1000; // Byte >> KB >> MB
      if (fileSizeInMb < min || fileSizeInMb > max) {
        return false;
      }
      return true;
    }
    return false;
  };

  const validateTypeAndExtension = (file: File) => {
    // Used for only PNG, JPEG images
    const [fileType, fileExtension] = file.type.split("/");
    const allowedExtensions = ["jpeg", "png", "jpg"];

    if (fileType === "image" && allowedExtensions.includes(fileExtension)) {
      return true;
    }

    return false;
  };

  return { validateSize, validateTypeAndExtension };
};
