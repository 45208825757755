<template>
  <div class="login-page container-fluid">
    <div class="row h-100">
      <div
        class="image-splash d-none d-md-flex justify-content-center align-items-center col-md-6 col-xl-4"
      >
        <RouterLink to="/">
          <InocuLogo />
        </RouterLink>
      </div>

      <div
        class="login-section d-flex justify-content-center align-items-center col-md-6 col-xl-8 bg-white"
      >
        <div class="login-form-container w-75 m-auto">
          <WorkspaceUsername
            v-if="currentLoginStep === 'workspaceUsername'"
            @backToLogin="backToLogin"
          />

          <LoginCredentials
            v-else-if="currentLoginStep === 'loginCredentials'"
            @forgotPassword="forgotPasswordClicked"
            @nextChallenge="getLoginNextChallenge"
          />

          <ResetPassword
            v-else-if="currentLoginStep === 'setNewPassword'"
            @nextChallenge="getLoginNextChallenge"
          />

          <MfaSetup
            v-else-if="currentLoginStep === 'mfaSetup'"
            @nextChallenge="getLoginNextChallenge"
          />

          <CodeVerification
            v-else-if="currentLoginStep === 'codeVerification'"
            :codeReceivedFrom="codeReceivedFrom"
          />

          <ForgotPassword
            v-else-if="currentLoginStep === 'forgotPassword'"
            @backToLogin="backToLogin"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref } from "vue";
import { RouterLink } from "vue-router";

import {
  WorkspaceUsername,
  LoginCredentials,
  ForgotPassword,
  ResetPassword,
  CodeVerification,
  MfaSetup,
} from "@/components/login";

import InocuLogo from "@/assets/images/inocu-logo.svg";

const currentLoginStep = ref("workspaceUsername");
const codeReceivedFrom: Ref<"TOTP" | "SMS" | null> = ref(null);

const getLoginNextChallenge = (nextChallenge: string) => {
  switch (nextChallenge) {
    case "NEW_PASSWORD_REQUIRED":
      currentLoginStep.value = "setNewPassword";
      break;
    case "MFA_SETUP":
      currentLoginStep.value = "mfaSetup";
      break;
    case "SOFTWARE_TOKEN_MFA":
      currentLoginStep.value = "codeVerification";
      codeReceivedFrom.value = "TOTP";
      break;
    case "SMS_MFA":
      currentLoginStep.value = "codeVerification";
      codeReceivedFrom.value = "SMS";
      break;
  }
};

const forgotPasswordClicked = () => {
  currentLoginStep.value = "forgotPassword";
};

const backToLogin = () => {
  currentLoginStep.value = "loginCredentials";
};
</script>

<style scoped lang="scss">
.login-page.container-fluid {
  height: 100vh;

  .image-splash {
    background-color: transparent;
    background-image: url(@/assets/images/login-splash.jpg);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    a {
      width: 60%;
      max-width: 280px;
    }
  }

  .login-section {
    .login-form-container {
      min-width: 350px;
      padding: 0 2rem;
    }
  }
}
</style>
