import { defineStore, storeToRefs } from "pinia";
import axios from "axios";
import { ref } from "vue";

import type {
  SetupMfaMethodPayload,
  AuthChallengeResponse,
  AuthCompletedResponse,
} from "@/_helpers/interfaces";
import router from "@/router";
import { useUserStore } from "./user.store";
import tokenService from "@/_helpers/services/token.service";

export const useAuthStore = defineStore("auth", () => {
  // state
  const email = ref("");
  const password = ref("");
  const sessionId = ref("");
  const subId = ref("");
  const nextChallenge = ref("");
  const qrCodeUrl = ref("");
  const errorMessage = ref("");

  // actions
  const extractSessionData = (data: AuthChallengeResponse): void => {
    const { session, sub, challenge, extra } = data;

    sessionId.value = session;
    subId.value = sub;
    nextChallenge.value = challenge;
    qrCodeUrl.value = extra.qr_code;
  };

  const completeLoginFlow = (data: AuthCompletedResponse): void => {
    const { access_token, refresh_token } = data;

    tokenService.setAccessToken(access_token);
    tokenService.setRefreshToken(refresh_token);

    router.push("/home");
  };

  const enterCredentials = async (
    userEmail: string,
    userPassword: string
  ): Promise<AuthChallengeResponse> => {
    try {
      email.value = userEmail;
      password.value = userPassword;

      const { data } = await axios.post(`/users/init_auth/`, {
        email: userEmail,
        password: userPassword,
      });

      errorMessage.value = "";
      if (data.challenge) {
        extractSessionData(data);
      } else {
        completeLoginFlow(data);
      }

      return data;
    } catch (error: any) {
      const errorType = error.response.data.type;

      if (errorType === "validation_error") {
        errorMessage.value =
          "Invalid credentials. Please enter a valid email or password.";
      } else {
        errorMessage.value = "Something went wrong. Please try again later.";
      }

      throw error;
    }
  };

  const createNewPassword = async (
    newPassword: string
  ): Promise<AuthChallengeResponse> => {
    try {
      password.value = newPassword;

      const { data } = await axios.post(
        `/users/respond_to_new_password_required_challenge/`,
        {
          new_password: newPassword,
          session: sessionId.value,
          sub: subId.value,
        }
      );

      errorMessage.value = "";
      completeLoginFlow(data);

      return data;
    } catch (error: any) {
      errorMessage.value = "Something went wrong. Please try again later.";

      throw error;
    }
  };

  const getSetupMfaMethodRequestPayload = (
    mfaMethod: string,
    options: { code?: string; mobileNumber?: string }
  ): SetupMfaMethodPayload => {
    const requestPayload: SetupMfaMethodPayload = {
      session: sessionId.value,
      sub: subId.value,
      mfa_method: mfaMethod,
    };

    if (mfaMethod === "TOTP") {
      requestPayload.mfa_code = options.code;
    } else {
      requestPayload.phone_number = options.mobileNumber;
      requestPayload.email = email.value;
      requestPayload.password = password.value;
    }

    return requestPayload;
  };

  const setupMfaMethod = async (
    mfaMethod: "TOTP" | "SMS",
    options: {
      code?: string;
      mobileNumber?: string;
    }
  ): Promise<AuthChallengeResponse | AuthCompletedResponse> => {
    try {
      const requestPayload: any = getSetupMfaMethodRequestPayload(
        mfaMethod,
        options
      );

      const { data } = await axios.post(
        `/users/respond_to_mfa_setup_challenge/`,
        requestPayload
      );

      errorMessage.value = "";

      if (data.challenge) {
        extractSessionData(data);
      } else if (data.access_token) {
        completeLoginFlow(data);
      }

      return data;
    } catch (error: any) {
      const errorType = error.response.data.type;

      if (errorType === "validation_error") {
        errorMessage.value = "Invalid MFA code. Please enter a valid code.";
      } else {
        errorMessage.value = "Something went wrong. Please try again later.";
      }

      throw error;
    }
  };

  const verifyCode = async (
    code: string,
    verificationMethod: "SMS" | "TOTP"
  ): Promise<AuthCompletedResponse> => {
    try {
      const { data } = await axios.post(`/users/respond_to_mfa_challenge/`, {
        session: sessionId.value,
        sub: subId.value,
        mfa_code: code,
        mfa_method: verificationMethod,
      });

      errorMessage.value = "";
      completeLoginFlow(data);

      return data;
    } catch (error: any) {
      const errorType = error.response.data.type;

      if (errorType === "validation_error") {
        errorMessage.value = "Invalid MFA code. Please enter a valid code.";
      } else {
        errorMessage.value = "Something went wrong. Please try again later.";
      }

      throw error;
    }
  };

  const logout = () => {
    const { currentUser } = storeToRefs(useUserStore());

    email.value = "";
    password.value = "";
    sessionId.value = "";
    subId.value = "";
    nextChallenge.value = "";
    qrCodeUrl.value = "";
    errorMessage.value = "";

    currentUser.value = null;
    localStorage.clear();
    sessionStorage.clear();

    router.push("/login");
  };

  const sendPasswordResetMail = async (email: string) => {
    try {
      const { data } = await axios.post(`/users/forgot_password/`, {
        email,
      });

      errorMessage.value = "";

      return data;
    } catch (error: any) {
      errorMessage.value = "Something went wrong. Please try again later.";

      throw error;
    }
  };

  return {
    sessionId,
    subId,
    nextChallenge,
    qrCodeUrl,
    errorMessage,
    enterCredentials,
    completeLoginFlow,
    createNewPassword,
    setupMfaMethod,
    verifyCode,
    logout,
    sendPasswordResetMail,
  };
});
