<template>
  <div class="create-page">
    <h4 class="page-header">{{ t("Users.EditUser_31") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ t("Users.UserData_2") }}</div>

      <div class="container-fluid d-flex flex-column">
        <div class="previewed-user-data">
          <div class="row justify-content-between">
            <div class="form-group col-lg-5">
              <div class="form-label">
                {{ t("Users.FirstName_3") }}
              </div>

              <input
                :value="currentUser?.first_name"
                type="text"
                class="form-control"
                :placeholder="t('Users.NoFirstName_32')"
                :disabled="true"
              />
            </div>

            <div class="form-group col-lg-5">
              <div class="form-label">
                {{ t("Users.LastName_12") }}
              </div>

              <input
                :value="currentUser?.last_name"
                type="text"
                class="form-control"
                :placeholder="t('Users.NoLastName_33')"
                :disabled="true"
              />
            </div>
          </div>

          <div class="row justify-content-between mt-4">
            <div class="form-group col-lg-5">
              <div class="form-label">
                {{ t("Global.Email_8") }}
              </div>

              <input
                :value="currentUser?.email"
                type="email"
                class="form-control"
                :placeholder="t('Users.NoEmail_34')"
                :disabled="true"
              />
            </div>

            <div
              class="form-group col-lg-5 d-flex flex-column justify-content-end"
            >
              <div class="form-check form-switch">
                <input
                  id="isAdminCheckInput"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  :checked="currentUser?.is_admin"
                  @change="toggleIsAdmin(($event.target as any).checked)"
                />
                <label class="form-check-label" for="isAdminCheckInput">
                  {{ t("Users.IsAdmin_46") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <form class="editable-customer-assignments mt-5">
          <div class="item-data-header">Customer Assignments</div>

          <template v-if="!customersWithUserRoles?.length">
            <div class="alert alert-warning" role="alert">
              No roles associated with this user.
            </div>
          </template>

          <template v-else>
            <div class="container-fluid">
              <div
                v-for="(customerWithUserRoles, index) in customersWithUserRoles"
                :key="customerWithUserRoles.customerId"
                class="row mb-3 customer-assignment-row align-items-start"
              >
                <div class="col-3 customer-name">
                  <span>
                    {{ customerWithUserRoles.customerName }}
                  </span>
                </div>

                <div class="col-7 form-group user-roles">
                  <LegacyMultiSelect
                    v-model="assignments[index].roles"
                    class="customer-dropdown"
                    mode="tags"
                    :searchable="true"
                    :options="userRoles"
                    :placeholder="t('UserProfile.ChooseUser_21')"
                    :canClear="false"
                    :class="{
                      'is-invalid': errors[`assignments[${index}].roles`],
                    }"
                  />

                  <div class="invalid-feedback">
                    {{ errors[`assignments[${index}].roles`] }}
                  </div>
                </div>

                <div class="col-2 d-flex gap-3">
                  <button
                    type="button"
                    class="btn btn-primary save-roles-action"
                    @click="saveUserRoles(index)"
                  >
                    {{ t("Users.Save_36") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger save-roles-action"
                    @click="deleteAssignments(index)"
                  >
                    {{ t("Users.Delete_37") }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user.store";
import { onMounted, ref, type Ref } from "vue";
import { useRouter } from "vue-router";
import { UserRoles, type User } from "@/_helpers/interfaces";
import { useField, useFieldArray, useForm } from "vee-validate";
import { array, object, string } from "yup";
import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const userRoles: any = { ...UserRoles };
delete userRoles.ADMIN; // Admin assignation is from a switcher
const { getUserById, getAssignedCustomersWithRolesList, editUserAdminStatus } =
  useUserStore();

const props = defineProps({
  userId: String, // Route param
});

const currentUser: Ref<User | null> = ref(null);
const customersWithUserRoles: Ref<Array<{
  customerId: number;
  customerName: string;
  roles: string[];
}> | null> = ref(null);

onMounted(async () => {
  validateRouteParam();

  currentUser.value = await getUserById(+props.userId!);
  customersWithUserRoles.value = getAssignedCustomersWithRolesList(
    currentUser.value
  );
  addReceivedAssignmentsToForm();
});

const validateRouteParam = () => {
  if (isNaN(+props.userId!)) {
    // TODO: navigate to not found page
    router.push({ name: "list-users" });
  }
};

const addReceivedAssignmentsToForm = () => {
  customersWithUserRoles.value?.forEach((customerWithUserRoles) => {
    push({
      customer: customerWithUserRoles.customerId,
      roles: customerWithUserRoles.roles,
    });
  });
};

const validationSchema = object({
  assignments: array().of(
    object({
      customer: string().required(),
      roles: array().of(string()).min(1, "Select at least one role").required(),
    })
  ),
});
const { errors } = useForm({
  validationSchema,
});
const { value: assignments }: { value: Ref<any> } = useField("assignments");
const { push, remove } = useFieldArray("assignments");

const { editUserAssignments } = useUserStore();

const saveUserRoles = async (assignmentRowIndex: number) => {
  const isFieldValid =
    !errors.value[`assignments[${assignmentRowIndex}].roles`];

  if (isFieldValid) {
    try {
      await editUserAssignments(
        +props.userId!,
        assignments.value[assignmentRowIndex]
      );

      toast.open({
        message: `User roles are updated successfully.`,
        type: "success",
      });
    } catch (error) {
      toast.open({
        message: `Something went wrong. Please try again later.`,
        type: "danger",
      });
    }
  }
};

const deleteAssignments = async (assignmentRowIndex: number) => {
  const isFieldValid =
    !errors.value[`assignments[${assignmentRowIndex}].roles`];
  const { customer } = assignments.value[assignmentRowIndex];

  if (isFieldValid) {
    try {
      await editUserAssignments(+props.userId!, {
        customer,
        roles: [],
      });

      remove(assignmentRowIndex);
      customersWithUserRoles.value?.splice(assignmentRowIndex, 1);

      toast.open({
        message: `User assignments are deleted successfully.`,
        type: "success",
      });
    } catch (error) {
      toast.open({
        message: `Something went wrong. Please try again later.`,
        type: "danger",
      });
    }
  }
};

const toggleIsAdmin = async (isAdmin: boolean) => {
  try {
    await editUserAdminStatus(+props.userId!, isAdmin);

    toast.open({
      message: `Admin roles is ${
        isAdmin ? "assigned to" : "removed from"
      } the user successfully.`,
      type: "success",
    });
  } catch (error) {
    toast.open({
      message: `Something went wrong. Please try again later.`,
      type: "danger",
    });
  }
};
</script>

<style scoped lang="scss">
.customer-name {
  display: flex;
  align-items: center;
  background-color: #eef2f7;
  border: 1px solid #dee2e6;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0.3rem 0.7rem;

  span {
    font-size: 1rem;
    color: #969696;
  }
}

.save-roles-action {
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
}
</style>
