<template>
  <apexchart
    :options="amountsChartOptions"
    :series="amountsChartSeries"
  ></apexchart>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

const amountsChartSeries = ref<number[]>([]);
const amountsChartOptions = ref({
  chart: {
    type: "donut",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#f5ba30", "#21bf6b", "#e93a57"],
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          total: {
            label: t("Dashboard.TotalAmountSpent_14"),
            show: true,
            showAlways: true,
            fontSize: "12px",
            fontFamily: "Nunito",
            fontWeight: "normal",
            color: "#525252",
            formatter: function (w) {
              return n(
                w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0),
                "decimal"
              );
            },
          },
        },
      },
    },
  },
  labels: [
    t(`Dashboard.TotalAmountSpent_14`),
    t(`Dashboard.TotalMaxLimit_15`),
    t(`Dashboard.TotalOrdersAmount_22`),
  ],
  legend: {
    show: true,
    position: "right",
    floating: false,
    offsetY: 50,
    fontSize: "12px",
    fontFamily: "Nunito",
    fontWeight: "normal",
    formatter: function (seriesName, opts) {
      return [
        seriesName,
        " - ",
        n(opts.w.globals.series[opts.seriesIndex], "decimal"),
        " <br /> ",
      ];
    },
    width: "50px",
    height: "50px",
    labels: {
      useSeriesColors: true,
    },
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return n(value, "decimal");
      },
    },
  },
});

const props = defineProps<{
  amountsChartStatistics: {
    total_orders_amount: number;
    total_amount_spent: number;
    total_max_limit: number;
  };
}>();

watch(
  () => props.amountsChartStatistics,
  (value) => {
    amountsChartSeries.value = [
      value.total_amount_spent ?? 0,
      value.total_max_limit ?? 0,
      value.total_orders_amount ?? 0,
    ];
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss"></style>
