import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";

import TheDashboard from "@/views/Dashboard/TheDashboard.vue";
import {
  Customers,
  CreateCustomer,
  ViewCustomer,
  AssignTeam,
  EditLimits,
  EditForms,
} from "@/views/Customers";
import Projects from "@/views/Projects/Projects.vue";
import CreateProject from "@/views/Projects/CreateProject/CreateProject.vue";
import TheStatistics from "@/views/Statistics/TheStatistics.vue";
import { ListUsers, CreateUser, EditUser } from "@/views/Users";
import MyProfile from "@/views/MyProfile/MyProfile.vue";
import NotificationCenter from "@/views/NotificationCenter/NotificationCenter.vue";
import HistoryLog from "@/views/HistoryLog/HistoryLog.vue";
import ListSuppliers from "@/views/MasterData/Suppliers/ListSuppliers.vue";
import CreateSupplier from "@/views/MasterData/Suppliers/CreateSupplier.vue";
import EditSupplier from "@/views/MasterData/Suppliers/EditSupplier.vue";
import ListBankAccounts from "@/views/MasterData/BankAccounts/ListBankAccounts.vue";
import CreateBankAccount from "@/views/MasterData/BankAccounts/CreateBankAccount.vue";
import EditBankAccount from "@/views/MasterData/BankAccounts/EditBankAccount.vue";
import OrderDetails from "@/views/OrderDetails/OrderDetails.vue";
import SepaPayments from "@/views/SepaPayments/SepaPayments.vue";
import CreateSepaPayments from "@/views/SepaPayments/CreateSepaPayments/CreateSepaPayments.vue";
import TheOrders from "@/views/Orders/TheOrders.vue";
import ViewOrder from "@/views/Orders/ViewOrder/ViewOrder.vue";
import CreateOrder from "@/views/Orders/CreateOrder/CreateOrder.vue";
import RecurringOrders from "@/views/RecurringOrders/RecurringOrders.vue";
import ThePayments from "@/views/Payments/ThePayments.vue";
import CreatePayment from "@/views/Payments/CreatePayment/CreatePayment.vue";
import ViewPayment from "@/views/Payments/ViewPayment/ViewPayment.vue";
import { UserRoles } from "@/_helpers/interfaces";
import i18n from "@/_helpers/plugins/i18n";
import CreateRecurringInstruction from "@/views/Orders/CreateRecurringInstruction/CreateRecurringInstruction.vue";
import ViewRecurringOrder from "@/views/Orders/ViewOrder/ViewRecurringOrder.vue";
import HijackUser from "@/views/Users/HijackUser.vue";

export const routes = [
  {
    path: "/",
    // @ts-ignore
    redirect: (i18n.global.locale as any).value,
  },
  {
    path: "/__hjk",
    name: "hijack",
    component: HijackUser,
  },
  {
    path: "/:locale?",
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        component: LoginPage,
      },
      {
        path: "home",
        component: HomePage,
        children: [
          {
            path: "",
            name: "home",
            redirect: { name: "dashboard" },
          },
          {
            path: "dashboard",
            name: "dashboard",
            component: TheDashboard,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.PAYER,
                UserRoles.ORDER_CLEARER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "customers",
            name: "customers",
            component: Customers,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "create-customer",
            name: "create-customer",
            component: CreateCustomer,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "view-customer/:id",
            name: "view-customer",
            component: ViewCustomer,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "assign-team",
            name: "assign-team",
            component: AssignTeam,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "edit-limits",
            name: "edit-limits",
            component: EditLimits,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "edit-forms",
            name: "edit-forms",
            component: EditForms,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "projects",
            name: "projects",
            component: Projects,
            meta: {
              allowedRoles: [UserRoles.ADMIN, UserRoles.APPROVER],
            },
          },
          {
            path: "create-project",
            name: "create-project",
            component: CreateProject,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "statistics",
            name: "statistics",
            component: TheStatistics,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
                UserRoles.ORDER_CLEARER,
                UserRoles.PAYMENT_CLEARER,
              ],
            },
          },
          {
            path: "users",
            children: [
              {
                path: "",
                name: "list-users",
                component: ListUsers,
              },
              {
                path: "create",
                name: "create-user",
                component: CreateUser,
              },
              {
                path: ":userId",
                name: "edit-user",
                component: EditUser,
                props: true,
              },
            ],
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "my-profile",
            name: "my-profile",
            component: MyProfile,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.PAYER,
                UserRoles.ORDER_CLEARER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "notification-center",
            name: "notification-center",
            component: NotificationCenter,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.PAYER,
                UserRoles.ORDER_CLEARER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "history-log",
            name: "history-log",
            component: HistoryLog,
            meta: {
              allowedRoles: [UserRoles.ADMIN],
            },
          },
          {
            path: "master-data",
            name: "master-data",
            redirect: { name: "list-suppliers" },
          },
          {
            path: "suppliers",
            children: [
              {
                path: "",
                name: "list-suppliers",
                component: ListSuppliers,
              },
              {
                path: "create",
                name: "create-supplier",
                component: CreateSupplier,
              },
              {
                path: ":supplierId",
                name: "edit-supplier",
                component: EditSupplier,
                props: true,
              },
            ],
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.PAYER,
                UserRoles.ORDER_CLEARER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "bank-accounts",
            children: [
              {
                path: "",
                name: "list-bank-accounts",
                component: ListBankAccounts,
              },
              {
                path: "create",
                name: "create-bank-account",
                component: CreateBankAccount,
              },
              {
                path: ":bankAccountId",
                name: "edit-bank-account",
                component: EditBankAccount,
                props: true,
              },
            ],
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PAYER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
              ],
            },
          },
          {
            path: "order-details",
            name: "order-details",
            component: OrderDetails,
          },
          {
            path: "sepa-payments",
            name: "sepa-payments",
            component: SepaPayments,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PAYER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "create-sepa-payments",
            name: "create-sepa-payments",
            component: CreateSepaPayments,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PAYER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.APPROVER,
              ],
            },
          },
          {
            path: "orders",
            name: "orders",
            component: TheOrders,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "create-order",
            name: "create-order",
            component: CreateOrder,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "create-recurring-order",
            name: "create-recurring-order",
            component: CreateRecurringInstruction,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "order/:id",
            name: "order",
            component: ViewOrder,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "recurring-orders",
            name: "recurring-orders",
            component: RecurringOrders,
            exact: true,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "recurring-order/:id",
            name: "recurring-order",
            component: ViewRecurringOrder,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "payments",
            name: "payments",
            component: ThePayments,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PAYER,
                UserRoles.APPROVER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          {
            path: "create-payment",
            name: "create-payment",
            component: CreatePayment,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PURCHASER,
                UserRoles.APPROVER,
                UserRoles.ORDER_CLEARER,
                UserRoles.VIEWER,
                UserRoles.PAYER,
                UserRoles.PAYMENT_CLEARER,
              ],
            },
          },
          {
            path: "payment/:id",
            name: "payment",
            component: ViewPayment,
            meta: {
              allowedRoles: [
                UserRoles.ADMIN,
                UserRoles.PAYER,
                UserRoles.APPROVER,
                UserRoles.PAYMENT_CLEARER,
                UserRoles.VIEWER,
              ],
            },
          },
          // {
          //   path: "recurring-payments",
          //   name: "recurring-payments",
          //   component: RecurringPayments,
          //   meta: {
          //     allowedRoles: [
          //       UserRoles.ADMIN,
          //       UserRoles.PAYER,
          //       UserRoles.APPROVER,
          //       UserRoles.PAYMENT_CLEARER,
          //       UserRoles.VIEWER,
          //     ],
          //   },
          // },
        ],
      },
    ],
  },
];
