import { useI18n } from "vue-i18n";
import { onMounted, ref, type Ref } from "vue";

import { object, string, number, array } from "yup";
import parseMobile from "libphonenumber-js/mobile";

import { useField, useForm } from "vee-validate";
import { useWorldStore } from "@/stores/world.store";
import type { Country } from "../../interfaces/world/country.interface";
import type { City } from "../../interfaces/world/city.interface";
import router from "@/router";

export const useSupplierForm = () => {
  const { t } = useI18n();
  const { getCountriesList } = useWorldStore();

  const validationSchema = object({
    supplierNumber: number()
      .typeError(t("Suppliers.SupplierNumberMustConsistOfNumbers_74"))
      .required()
      .label(t("Suppliers.SupplierNumber_72")),
    supplierName: string()
      .required()
      .max(255)
      .label(t("Suppliers.SupplierName_3")),
    address: string().nullable(),
    zipCode: number()
      .typeError(t("Suppliers.ZipCodeMustBeNumber_75"))
      .nullable(),
    country: string().max(20).label(t("Suppliers.Country_11")).nullable(),
    city: string().max(30).label(t("Suppliers.City_9")).nullable(),
    phone: string().test(
      "validate-phone-number",
      t("Suppliers.PhoneNumberIsNotValid_20"),
      (value) => {
        if (!value) return true;

        return (
          ((parseMobile(value as string) &&
            parseMobile(value as string)?.isValid()) as boolean) &&
          value.length <= 20
        );
      }
    ),
    supplierEmail: string().email().max(254).label("Supplier email").nullable(),
    customer: string()
      .typeError(t("Suppliers.CustomerRequired_82"))
      .required()
      .label("Suppliers.SelectCustomer_77")
      .default(""),
  });

  const {
    errors,
    handleSubmit,
    isSubmitting,
    meta,
    resetForm,
    setValues,
    setFieldValue,
  } = useForm({
    validationSchema,
  });
  const { value: supplierNumber } = useField("supplierNumber");
  const { value: supplierName } = useField("supplierName");
  const { value: address } = useField("address");
  const { value: zipCode } = useField("zipCode");
  const { value: country } = useField("country", undefined, {
    initialValue: null,
  });
  const {
    value: city,
    resetField: resetCity,
    setValue: setCityValue,
  } = useField("city", undefined, {
    initialValue: null,
  });
  const { value: phone } = useField("phone");
  const { value: supplierEmail } = useField("supplierEmail");
  const { value: customer } = useField("customer", undefined, {
    initialValue: null,
  });

  const countriesList: Ref<Country[]> = ref([]);
  const citiesList: Ref<City[]> = ref([]);

  onMounted(async () => {
    const countries = await getCountriesList();

    countriesList.value = countries.map((country) => {
      country[
        "displayed_name"
      ] = `${country.flag_emoji} ${country.name} | ${country.native_name}`;

      return country;
    });
  });

  const backToSuppliersList = () => {
    router.push({ name: "list-suppliers" });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
    meta,
    resetForm,
    setValues,
    supplierNumber,
    supplierName,
    address,
    zipCode,
    country,
    city,
    setCityValue,
    resetCity,
    phone,
    supplierEmail,
    countriesList,
    citiesList,
    backToSuppliersList,
    customer,
    setFieldValue,
  };
};
