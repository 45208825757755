<template>
  <div class="list-table">
    <!-- Tabs -->
    <ul class="orders-tabs">
      <li :class="route.query.type === 'customers' ? 'active' : ''">
        <router-link
          :to="{ name: 'list-bank-accounts', query: { type: 'customers' } }"
          class="dropdown-item"
        >
          {{ t("Suppliers.CustomerAccounts_78") }}
        </router-link>
      </li>
      <li :class="route.query.type === 'suppliers' ? 'active' : ''">
        <router-link
          :to="{ name: 'list-bank-accounts', query: { type: 'suppliers' } }"
          class="dropdown-item"
        >
          {{ t("Suppliers.SupplierAccounts_79") }}
        </router-link>
      </li>
    </ul>
    <div>
      <customer-bank-accounts
        v-if="route.query.type == 'customers'"
      ></customer-bank-accounts>
      <supplier-bank-accounts
        v-else-if="route.query.type == 'suppliers'"
      ></supplier-bank-accounts>
    </div>
  </div>
</template>
<script setup lang="ts">
import CustomerBankAccounts from "@/components/master-data/bank-accounts/CustomerBankAccounts.vue";
import SupplierBankAccounts from "@/components/master-data/bank-accounts/SupplierBankAccounts.vue";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  if (
    router.currentRoute.value.query?.type === undefined ||
    router.currentRoute.value.query.type === ""
  ) {
    router.push({
      name: "list-bank-accounts",
      query: {
        type: "customers",
      },
    });
  }
});
</script>
