<template>
  <div class="generic-list-page">
    <div class="row">
      <div class="col-lg-3">
        <h1 class="page-title">{{ t("Order.Payments_88") }}</h1>
      </div>
    </div>
    <div class="list-table">
      <!-- Tabs -->
      <ul class="orders-tabs">
        <li :class="!route.query.status ? 'active' : ''">
          <router-link :to="{ name: 'payments' }" class="dropdown-item">
            {{ t("Order.AllTypes_89") }}
          </router-link>
        </li>
        <li
          v-if="showPendingTab()"
          :class="route.query.status === 'PENDING_APPROVAL' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'payments', query: { status: 'PENDING_APPROVAL' } }"
            class="dropdown-item"
          >
            {{ t("Order.Pending_90") }}
            <div
              class="status-indicator status-indicator--pending_approval"
            ></div>
          </router-link>
        </li>
        <!-- Hide this tab for clearers -->
        <li
          v-if="!currentUser?.user_role?.includes('Payment Clearer')"
          :class="route.query.status === 'CLEARING' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'payments', query: { status: 'CLEARING' } }"
            class="dropdown-item"
          >
            {{ t("Order.Clearing_141") }}
            <div class="status-indicator status-indicator--clearing"></div>
          </router-link>
        </li>
        <li :class="route.query.status === 'APPROVED' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'APPROVED' } }"
            class="dropdown-item"
          >
            {{ t("Order.Approved_91") }}
            <div class="status-indicator status-indicator--approved"></div>
          </router-link>
        </li>
        <li :class="route.query.status === 'REJECTED' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'REJECTED' } }"
            class="dropdown-item"
          >
            {{ t("Order.Rejected_92") }}
            <div class="status-indicator status-indicator--rejected"></div>
          </router-link>
        </li>
        <li :class="route.query.status === 'DRAFT' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'DRAFT' } }"
            class="dropdown-item"
          >
            {{ t("Order.Drafts_93") }}
            <div class="status-indicator status-indicator--draft"></div>
          </router-link>
        </li>
      </ul>
      <div>
        <PaymentTable
          :status="
            typeof route.query.status === 'string' ? route.query.status : ''
          "
          :customer="paymentFilter.customer ? paymentFilter.customer : -1"
          :project="paymentFilter.project ? paymentFilter.project : -1"
          :date="paymentFilter.date"
        />
      </div>
      <!-- Tabs -->
    </div>
  </div>
</template>

<script setup lang="ts">
import "@vuepic/vue-datepicker/dist/main.css";
import { reactive, onBeforeMount } from "vue";
import PaymentTable from "@/components/shared/tables/payments-table/PaymentsTable.vue";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const route = useRoute();

const { t } = useI18n();

const { currentUser } = storeToRefs(useUserStore());

let paymentFilter: any = reactive({
  customer: null,
});

const showPendingTab = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return false;
  }
  if (
    currentUser.value?.user_role.includes("Payment Clearer") ||
    currentUser.value?.user_role.includes("Approver")
  ) {
    return true;
  }
  return !currentUser.value?.user_role.includes("Payer");
};

onBeforeMount(() => {
  let getStoreCustomerId = localStorage.getItem("selectedCustomerId");
  getStoreCustomerId ? (paymentFilter.customer = +getStoreCustomerId) : null;
});
</script>
