<template>
  <header class="login-header">
    <h2>{{ t("Login.LoginToYourAccount_1").toUpperCase() }}</h2>
    <p>{{ t("Login.PleaseTypeInYourCredentials_2") }}</p>
  </header>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <Form
    class="login-form"
    :validation-schema="loginFormSchema"
    v-slot="{ errors, isSubmitting }"
    @submit="onUserLogin"
  >
    <div class="form-group email-group">
      <label for="email" class="form-label">{{ t("Global.Email_8") }}</label>

      <Field
        id="email"
        name="email"
        type="email"
        class="form-control"
        :class="{ 'is-invalid': errors.email }"
        :placeholder="t('Login.EnterYourEmailHere_7')"
      />

      <div class="invalid-feedback">{{ errors.email }}</div>
    </div>

    <div class="form-group password-group">
      <label for="password" class="form-label">
        {{ t("Global.Password_2") }}
      </label>

      <div class="password-input-group">
        <Field
          id="password"
          name="password"
          :type="isPasswordShown ? 'text' : 'password'"
          class="form-control"
          :class="{ 'is-invalid': errors.password }"
          :placeholder="t('Global.EnterYourPasswordHere_4')"
        />

        <div class="invalid-feedback">{{ errors.password }}</div>

        <i
          v-if="!isPasswordShown"
          class="icon-eye password-icon"
          title="Show password"
          @click="togglePasswordVisibility"
        >
        </i>
        <i
          v-else
          class="icon-eye-slash password-icon"
          title="Hide password"
          @click="togglePasswordVisibility"
        ></i>
      </div>
    </div>

    <span class="login-link forgot-password" @click="emit('forgotPassword')"
      >{{ t("Login.ForgotYourPassword_5") }}?</span
    >

    <button class="login-action-btn" :disabled="isSubmitting">
      {{ t("Login.Login_6") }}
    </button>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { storeToRefs } from "pinia";

import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const loginFormSchema = object().shape({
  email: string()
    .required(t("Login.EmailIsRequired_34"))
    .email(t("Login.EmailMustBeValid_35")),
  password: string().required(t("Login.PasswordIsRequired_36")),
});

const isPasswordShown = ref(false);

const emit = defineEmits<{
  (e: "forgotPassword"): void;
  (e: "nextChallenge", nextChallengeType: string): void;
}>();

const { nextChallenge, errorMessage } = storeToRefs(useAuthStore());
const { enterCredentials } = useAuthStore();

const onUserLogin = async (values: any) => {
  await enterCredentials(values.email, values.password);

  emit("nextChallenge", nextChallenge.value);
};

const togglePasswordVisibility = () => {
  isPasswordShown.value = !isPasswordShown.value;
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";

.login-form {
  .form-group {
    &.email-group {
      margin-bottom: 1.75rem;
    }

    &.password-group {
      margin-bottom: 0.35rem;

      .password-input-group {
        position: relative;

        .password-icon {
          position: absolute;
          top: 8px;
          right: 10px;

          cursor: pointer;

          &::before {
            color: #38678c;
          }
        }
      }
    }
  }
}

.login-link.forgot-password {
  align-self: flex-end;
}
</style>
