<template>
  <div></div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import type { AuthCompletedResponse } from "@/_helpers/interfaces";
import { configureApi } from "@/_helpers/plugins/api-configuration";
const { completeLoginFlow } = useAuthStore();
const router = useRouter();

onMounted(() => {
  const workspaceUsername = router.currentRoute.value.query.tenant as string;
  const token = router.currentRoute.value.query.token as string;
  const endHijack = router.currentRoute.value.query.end as string;
  console.log(workspaceUsername, token, endHijack);
  if (token && workspaceUsername) {
    let authData = {
      access_token: router.currentRoute.value.query.token as string,
      refresh_token: "",
      id_token: "",
      expires_in: 0,
    };
    localStorage.setItem("tenant", workspaceUsername);
    sessionStorage.setItem("is_hijack_session", "true");
    configureApi();
    completeLoginFlow(authData as AuthCompletedResponse);
  }
});
</script>
