import { useAuthStore } from "@/stores/auth.store";

import axios, { AxiosHeaders } from "axios";
import tokenService from "../services/token.service";
import i18n from "./i18n";

export const configureApi = () => {
  const tenant = localStorage.getItem("tenant");
  const apiUrl = import.meta.env.VITE_INOCU_API_URL;
  const baseUrl = `${apiUrl}/${tenant}`;

  axios.defaults.baseURL = baseUrl;

  // Add JWT to each request
  axios.interceptors.request.use((config) => {
    // Add language
    // @ts-ignore
    const lang = (i18n.global.locale as any).value;
    (config.headers as AxiosHeaders).set("Accept-Language", lang);

    // Add access token
    const accessToken = tokenService.getAccessToken();

    if (accessToken)
      (config.headers as AxiosHeaders).set(
        "authorization",
        `Bearer ${accessToken}`
      );

    return config;
  });

  // Logout automatically if Unauthorized, ie. token expired
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      if (originalConfig.url !== "/users/init_auth/" && error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const { data } = await axios.post("/users/refresh_token/", {
              access_token: tokenService.getAccessToken(),
              refresh_token: tokenService.getRefreshToken(),
            });

            const { access_token } = data;

            tokenService.setAccessToken(access_token);

            return axios(originalConfig);
          } catch (_error) {
            const { logout } = useAuthStore();

            logout();

            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
};
