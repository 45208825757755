import i18n from "@/_helpers/plugins/i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { ref } from "vue";

export const useAdminDashboardChart = () => {
  // @ts-ignore
  const { t } = i18n.global;
  const { getAverageReleaseDurationPerMonth } = useDashboardStore();

  const chartSeries = ref([
    {
      name: t("Dashboard.Orders_8"),
      data: [] as Array<number>,
    },
    {
      name: t("Dashboard.Payments_9"),
      data: [] as Array<number>,
    },
  ]);

  const chartOptions = ref({
    chart: {
      id: "RequestsChart",
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: "6",
        borderRadiusApplication: "end",
        columnWidth: "40%",
      },
    },
    colors: ["#ceec34", "#e93a57"],
    xaxis: {
      categories: <string[]>[],
    },
    yaxis: {},
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  const getChartData = async (
    startDate: string,
    endDate: string,
    customerId?: number | null,
    projectId?: number | null
  ) => {
    const { "X-axis": xAxis, "Y-axis": yAxis } =
      await getAverageReleaseDurationPerMonth(
        startDate,
        endDate,
        customerId,
        projectId
      );

    // Set values of X-Axis
    chartOptions.value = {
      ...chartOptions.value,
      xaxis: {
        categories: [...xAxis],
      },
    };

    // Set values of Y-Axis
    const { orders, payments } = (yAxis as Array<[number, number]>).reduce(
      (prevValue, currValue) => {
        const [ordersValue, paymentsValue] = currValue;

        prevValue["orders"].push(ordersValue);
        prevValue["payments"].push(paymentsValue);

        return prevValue;
      },
      {
        orders: [] as Array<number>,
        payments: [] as Array<number>,
      }
    );

    chartSeries.value[0].data = [...orders];
    chartSeries.value[1].data = [...payments];
  };

  return { chartSeries, chartOptions, getChartData };
};
