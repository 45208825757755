<template>
  <div class="customer">
    <!-- New Supplier Popup -->
    <div class="generic-popup" v-if="isSupplierPopup">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">Add New Supplier</h4>
          <span class="popup-close" @click="closeNewSupplierModal">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <!-- Supplier Form -->
          <form @submit.prevent="addNewSupplier">
            <div class="row">
              <!-- NAME -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ t("Suppliers.SupplierName_3") }}
                    <span class="text-danger">*</span>
                  </div>
                  <input
                    v-model="supplierName"
                    name="supplierName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierName }"
                    :placeholder="t('Suppliers.TypeNameHere_4') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.supplierName }}</div>
                </div>
              </div>
              <!-- SUPPLIER NUMBER -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ t("Suppliers.SupplierNumber_72") }}
                    <span class="text-danger">*</span>
                  </div>
                  <input
                    v-model="supplierNumber"
                    name="supplierNumber"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierNumber }"
                    :placeholder="t('Suppliers.TypeNumberHere_73') + '...'"
                  />

                  <div class="invalid-feedback">
                    {{ errors.supplierNumber }}
                  </div>
                </div>
              </div>
              <!-- CUSTOMER since it's required-->
              <input hidden :value="formData.customer" />
              <!-- ADDRESS -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ t("Suppliers.Address_5") }}
                  </div>
                  <input
                    v-model="address"
                    name="address"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.address }"
                    :placeholder="t('Suppliers.TypeAddressHere_6') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.address }}</div>
                </div>
              </div>
              <!-- ZIP CODE -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ t("Suppliers.ZipCode_7") }}
                  </div>
                  <input
                    v-model="zipCode"
                    name="zipCode"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.zipCode }"
                    :placeholder="t('Suppliers.TypeZipCodeHere_8') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.zipCode }}</div>
                </div>
              </div>
              <!-- COUNTRY -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ t("Suppliers.Country_11") }}</div>
                  <LegacyMultiSelect
                    v-model="country"
                    name="country"
                    mode="single"
                    :options="countriesList"
                    valueProp="name"
                    label="displayed_name"
                    :searchable="true"
                    :placeholder="t('Suppliers.TypeCountryHere_12') + '...'"
                    :clearOnBlur="false"
                    :class="{
                      'is-invalid': errors.country,
                    }"
                  />
                  <div class="invalid-feedback">{{ errors.country }}</div>
                </div>
              </div>
              <!-- CITY -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ t("Suppliers.City_9") }}</div>
                  <LegacyMultiSelect
                    v-model="city"
                    name="city"
                    mode="single"
                    :noOptionsText="
                      country
                        ? t('Suppliers.NoCitiesInThisCountry_31')
                        : t('Suppliers.PleaseSelectCountryFirst_26')
                    "
                    :options="citiesList"
                    valueProp="name"
                    label="name"
                    :searchable="true"
                    :placeholder="t('Suppliers.TypeCityHere_10') + '...'"
                    :clearOnBlur="false"
                    :class="{
                      'is-invalid': errors.city,
                    }"
                  />
                  <div class="invalid-feedback">{{ errors.city }}</div>
                </div>
              </div>
              <!-- PHONE -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ t("Suppliers.Phone_13") }}</div>
                  <PhoneNumberInput
                    v-model="phone"
                    name="phone"
                    size="sm"
                    show-code-on-list
                    defaultCountryCode="DE"
                    :preferredCountries="['DE', 'EG']"
                    :class="{ 'is-invalid': errors.phone }"
                  />

                  <div class="invalid-feedback">{{ errors.phone }}</div>
                </div>
              </div>
              <!-- EMAIL -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ t("Suppliers.Email_15") }}
                  </div>

                  <input
                    v-model="supplierEmail"
                    name="supplierEmail"
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierEmail }"
                    :placeholder="t('Suppliers.TypeEmailHere_16') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.supplierEmail }}</div>
                </div>
              </div>
              <!-- ERRORS -->
              <div class="col-12">
                <div
                  v-if="supplierCreatedSuccessfully != null"
                  class="result-alert"
                >
                  <div
                    v-if="supplierCreatedSuccessfully && !meta.dirty"
                    class="col-lg-7 alert alert-success"
                    role="alert"
                  >
                    {{ t("Suppliers.SupplierCreatedSuccessfully_17") }}
                  </div>
                  <div
                    v-if="!supplierCreatedSuccessfully"
                    class="col-lg-7 alert alert-danger"
                    role="alert"
                  >
                    {{ errorSupplierMessage }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ACTION BUTTONS -->
            <div
              class="options"
              :class="{ 'mt-3': supplierCreatedSuccessfully === null }"
            >
              <button :disabled="isSubmitting" class="save" type="submit">
                Add Supplier
              </button>
              <button class="cancel" @click="closeNewSupplierModal">
                {{ t("Order.CancelPopup_82") }}
              </button>
            </div>
          </form>
          <!-- Supplier Form -->
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="isCancelPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>{{ t("Payments.PaymentDiscardMessage_28") }}</h5>
          <div class="options">
            <button class="save" @click="onChangePaymentCancel">
              {{ t("Order.CancelDiscard_55") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <h1 class="page-title">{{ t("Payments.PaymentNew_7") }}</h1>
    <!--  -->
    <div class="form-wrapper mb-4">
      <div class="generic-form">
        <div class="row">
          <!-- Project -->
          <div class="col-lg-6 col-12">
            <div class="form-input mb-lg-0">
              <label class="form-label">
                {{ t("Order.Project_4") }}
              </label>
              <LegacyMultiSelect
                v-model="formData.project"
                name="projects"
                :object="false"
                :options="projectsList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="t('Order.SelectProject_5')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
            </div>
          </div>
          <!-- Payment Count -->
          <div
            v-if="formData.requestType === 'one-time'"
            class="col-lg-2 offset-lg-4"
          >
            <div class="form-input mb-lg-0">
              <label class="form-label">{{
                t("Payments.PaymentCount_8")
              }}</label>
              <div class="order-counter">
                <button @click="subtract()">-</button>
                <div>{{ formData.paymentCount }}</div>
                <button @click="add()">+</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Payment Form -->
    <div v-if="formData.customer === null" class="text-center">
      {{ t("Payments.PaymentMessage_10") }}
    </div>
    <!-- Payment Info -->
    <div v-else>
      <!-- Payment Form -->
      <div v-for="(item, index) in formData.paymentCount" :key="index">
        <div class="row">
          <!-- Payment Data -->
          <div class="col-lg-4 col-12">
            <div class="form-wrapper">
              <div class="form-title mb-3">
                <h2>{{ t("Order.PaymentData_95") }}</h2>
              </div>
              <div class="generic-form">
                <!-- Sequence Order No. -->
                <div class="form-input mb-3" v-if="isEditPayment">
                  <label for="" class="form-label">{{
                    t("Payments.PaymentSequenceOrderNo_14")
                  }}</label>
                  <input
                    type="text"
                    readonly
                    v-model="payments[index].sequence_order_no"
                    :placeholder="t('Payments.PaymentSequenceOrderNo_14')"
                  />
                  <span
                    v-if="
                      isErrorFound &&
                      paymentErrors[index]?.sequence_order_no !== ''
                    "
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index]?.sequence_order_no }}
                  </span>
                </div>
                <!-- Supplier -->
                <div class="form-input">
                  <label
                    class="form-label d-flex align-items-center justify-content-between"
                  >
                    <label class="form-label">
                      {{ t("Order.Supplier_98") }}
                      <span class="text-danger">*</span>
                    </label>
                    <button
                      class="add-new-supplier"
                      @click="openNewSupplierModal(index)"
                    >
                      {{ t("Order.AddNewSupplier_103") }}
                    </button>
                  </label>
                  <LegacyMultiSelect
                    v-if="newCustomerId"
                    v-model="payments[index].supplier"
                    :loading="suppliersListLoading"
                    name="supplier"
                    mode="single"
                    :object="false"
                    :min-chars="0"
                    :options="suppliersList"
                    :resolve-on-load="true"
                    valueProp="id"
                    label="display_name"
                    :placeholder="t('Order.SelectSupplier_99')"
                    :closeOnSelect="true"
                    :clearOnBlur="false"
                    :searchable="true"
                    @search-change="filterSuppliers"
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].supplier !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].supplier }}
                  </span>
                </div>
                <!-- Order No. Supplier -->
                <div class="form-input">
                  <label class="form-label">
                    {{ t("Order.OrderSupplierNo_98") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    readonly
                    v-model="payments[index].order_no_supplier"
                  />
                </div>
                <!-- Invoice No. -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >{{ t("Payments.PaymentInvoiceNo_15") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model="payments[index].invoice_number"
                    :placeholder="t('Payments.PaymentInvoiceNo_15')"
                  />
                  <span
                    v-if="
                      isErrorFound && paymentErrors[index].invoice_number !== ''
                    "
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].invoice_number }}
                  </span>
                </div>
                <!-- Invoice Date -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >{{ t("Payments.PaymentInvoiceDate_16") }}
                    <span class="text-danger">*</span>
                  </label>
                  <VueDatePicker
                    v-model="payments[index].invoice_date"
                    :placeholder="t('Payments.PaymentInvoiceDate_16')"
                    :enable-time-picker="false"
                    :format="formatDate"
                    auto-apply
                  />
                  <span
                    v-if="
                      isErrorFound && paymentErrors[index].invoice_date !== ''
                    "
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].invoice_date }}
                  </span>
                </div>
                <!-- Due Date -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >{{ t("Payments.PaymentDueDate_3") }}
                    <span class="text-danger">*</span>
                  </label>
                  <VueDatePicker
                    v-model="payments[index].due_date"
                    :placeholder="t('Payments.PaymentDueDate_3')"
                    :enable-time-picker="false"
                    :format="formatDate"
                    auto-apply
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].due_date !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].due_date }}
                  </span>
                </div>
                <!-- Payment Date -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >Payment Date <span class="text-danger">*</span>
                  </label>
                  <VueDatePicker
                    v-model="payments[index].date"
                    placeholder="Payment Date"
                    :enable-time-picker="false"
                    :format="formatDate"
                    auto-apply
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].date !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].date }}
                  </span>
                </div>
                <!-- Dynamic Form Fields -->
                <div class="form-input" v-for="item in formFields" :key="item">
                  <label class="form-label">
                    {{ item.field.field_name }}
                    <span class="text-danger" v-if="item.is_mandatory">*</span>
                  </label>
                  <!-- Input Text Fields -->
                  <input
                    v-if="item.field.data_type === 'TEXT'"
                    type="text"
                    :placeholder="'Enter ' + item.field.field_name"
                    v-model="payments[index][item.field.model_field_name]"
                  />
                  <!-- Input Number Fields -->
                  <input
                    v-else-if="item.field.data_type === 'INTEGER'"
                    type="text"
                    :placeholder="'Enter ' + item.field.field_name"
                    min="0"
                    v-model="payments[index][item.field.model_field_name]"
                  />
                  <!-- Date Picker -->
                  <VueDatePicker
                    v-else-if="item.field.data_type === 'DATE'"
                    auto-apply
                    :placeholder="'Enter ' + item.field.field_name"
                    v-model="payments[index][item.field.model_field_name]"
                    :format="formatDate"
                  />
                  <!-- (Boolean) Radio = Masse Liability -->
                  <div
                    class="row"
                    v-else-if="item.field.data_type === 'BOOLEAN'"
                  >
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          :name="`${item.field.model_field_name}-${index}`"
                          :value="true"
                          v-model="payments[index][item.field.model_field_name]"
                          :id="`${item.field.model_field_name}-yes-${index}`"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`${item.field.model_field_name}-yes-${index}`"
                          >{{ t("Order.Yes_45") }}</label
                        >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          :name="`${item.field.model_field_name}-${index}`"
                          :value="false"
                          v-model="payments[index][item.field.model_field_name]"
                          :id="`${item.field.model_field_name}-no-${index}`"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`${item.field.model_field_name}-no-${index}`"
                          >{{ t("Order.No_46") }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <span
                    v-if="
                      isErrorFound &&
                      paymentErrors[index][item.field.model_field_name] !== ''
                    "
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index][item.field.model_field_name] }}
                  </span>
                </div>
                <!-- Net Euro -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >{{ t("Order.NetEuro_37") }}
                    <span class="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    v-model="payments[index].net_eur"
                    :placeholder="t('Order.NetEuro_37')"
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].net_eur !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].net_eur }}
                  </span>
                </div>
                <!-- Gross Euro -->
                <div class="form-input mb-3">
                  <label for="" class="form-label"
                    >{{ t("Order.GrossEuro_38") }}
                    <span class="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    v-model="payments[index].amount"
                    :placeholder="t('Order.GrossEuro_38')"
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].amount !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].amount }}
                  </span>
                </div>
                <!-- Comparison Gross Euro Order List -->
                <div class="form-input mb-3">
                  <label for="" class="form-label">{{
                    t("Payments.PaymentComparisonGrossEur_17")
                  }}</label>
                  <CurrencyInput
                    v-model="payments[index].comparison_gross_eur_order_list"
                    :readonly="
                      currentUser?.user_role.includes('Payer') ? false : true
                    "
                    :placeholder="t('Payments.PaymentComparisonGrossEur_17')"
                  />
                </div>
                <!-- Discount -->
                <div class="form-input mb-3">
                  <label for="" class="form-label">
                    {{ t("Payments.PaymentDiscount_18") }} (%)
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model="payments[index].discount"
                    :placeholder="t('Payments.PaymentDiscount_18')"
                  />
                  <span
                    v-if="isErrorFound && paymentErrors[index].discount !== ''"
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].discount }}
                  </span>
                </div>
                <!-- Gross Euro Less Discount -->
                <div class="form-input mb-3">
                  <label for="" class="form-label">
                    {{ t("Payments.PaymentDiscountGrossEur_19") }}
                    <span class="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    v-model="payments[index].gross_eur_less_discount"
                    :placeholder="t('Payments.PaymentDiscountGrossEur_19')"
                  />
                  <span
                    v-if="
                      isErrorFound &&
                      paymentErrors[index].gross_eur_less_discount !== ''
                    "
                    class="form__error-msg"
                  >
                    {{ paymentErrors[index].gross_eur_less_discount }}
                  </span>
                </div>
                <!-- Payment Date List -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Payment Date List</label>
                  <VueDatePicker
                    v-model="payments[index].payment_date_list"
                    placeholder="Payment Date List"
                    :enable-time-picker="false"
                    :format="formatDate"
                    auto-apply
                  />
                </div> -->
                <!-- Pay No. -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Pay No.</label>
                  <input
                    type="text"
                    v-model="payments[index].pay_no"
                    placeholder="Pay No."
                  />
                </div> -->
                <!-- Order Type -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Order Type</label>
                  <input
                    type="text"
                    v-model="payments[index].order_type"
                    placeholder="Order Type"
                  />
                </div> -->
                <!-- Internal Order No. -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Internal Order No.</label>
                  <input
                    type="text"
                    v-model="payments[index].internal_order_number"
                    placeholder="Internal Order No."
                  />
                </div> -->
                <!-- Vendor No. -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Vendor No.</label>
                  <input
                    type="text"
                    v-model="payments[index].vendor_no"
                    placeholder="Vendor No."
                  />
                </div> -->
                <!-- Vendor -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Vendor</label>
                  <input
                    type="text"
                    v-model="payments[index].vendor"
                    placeholder="Vendor"
                  />
                </div> -->
                <!-- Payment Term -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Payment Term</label>
                  <input
                    type="text"
                    v-model="payments[index].payment_term"
                    placeholder="Payment Term"
                  />
                </div> -->

                <!-- Good Receipt -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Good Receipt</label>
                  <VueDatePicker
                    v-model="payments[index].goods_receipt"
                    placeholder="Good Receipt"
                    :enable-time-picker="false"
                    :format="formatDate"
                    auto-apply
                  />
                </div> -->

                <!-- Comment -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Comment</label>
                  <input
                    type="text"
                    v-model="payments[index].comment"
                    placeholder="Comment"
                  />
                </div> -->
                <!-- Payment Amount -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Payment Amount</label>
                  <CurrencyInput
                    v-model="payments[index].amount"
                    placeholder="Payment Amount"
                  />
                </div> -->
                <!-- Comment Allea -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Comment Allea</label>
                  <input
                    type="text"
                    v-model="payments[index].comment_allea"
                    placeholder="Comment Allea"
                  />
                </div> -->
                <!-- Comment Management -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Comment Management</label>
                  <input
                    type="text"
                    v-model="payments[index].comment_management"
                    placeholder="Comment Management"
                  />
                </div> -->
                <!-- Comment Insv -->
                <!-- <div class="form-input mb-3">
                  <label for="" class="form-label">Comment Insv</label>
                  <input
                    type="text"
                    v-model="payments[index].comment_insv"
                    placeholder="Comment Insv"
                  />
                </div> -->
                <!-- Masse Liability -->
                <!-- <div class="form-input mb-3">
                  <label class="form-label">
                    Masse Liability
                  </label>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          v-model="payments[index].masse_liability"
                          :name="`masse-liability-${index}`"
                          :value="true"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label class="mb-0" for="yes">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          v-model="payments[index].masse_liability"
                          :name="`masse-liability-${index}`"
                          :value="false"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label class="mb-0" for="no">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- Invoice Attachment -->
          <div class="col-lg-8 col-12">
            <div class="form-wrapper h-100">
              <div
                class="form-title mb-3 d-flex align-items-center justify-content-between"
              >
                <h2>{{ t("Order.Attachment_40") }}</h2>
                <div class="d-flex">
                  <!-- Upload -->
                  <!-- <div>
                    <button class="upload-btn" @click="onUploadDocx()">Upload</button>
                  </div> -->
                  <!-- Take Picture -->
                  <div class="d-lg-none d-block ms-4">
                    <h2>
                      <label
                        for="take-picture"
                        class="pointer border px-2 py-1 rounded"
                        >{{ t("Order.TakePicture_41") }}</label
                      >
                    </h2>
                    <input type="file" id="take-picture" class="d-none" />
                  </div>
                </div>
              </div>
              <div style="height: calc(100% - 55px)">
                <label
                  v-if="payments[index].url === null"
                  :for="`choose-document-${index}`"
                  class="w-100 h-100 block pointer border border-secondary d-flex flex-column align-items-center justify-content-center"
                  style="
                    border-style: dashed !important;
                    background-color: #eff0f6;
                  "
                >
                  <span class="mb-2"
                    ><strong>{{ t("Order.ClickUpload_52") }}</strong>
                    {{ t("Order.YourDocument_53") }}
                  </span>
                  <span> {{ t("Order.DocumentType_54") }} </span>
                </label>
                <div v-else>
                  <span
                    @click="deleteAttachment(index)"
                    class="pointer d-block text-end mb-3"
                  >
                    <i class="icon-delete"></i>
                  </span>
                  <div v-html="payments[index].url" />
                </div>
                <input
                  type="file"
                  :id="`choose-document-${index}`"
                  class="d-none"
                  accept="jpg,jpeg,png,pdf"
                  @change="chooseDocument($event, index)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Orders Table -->
        <div class="list-table mt-4">
          <SelectOrdersForPaymentTable
            :key="selectedOrdersChangeKey"
            :index="index"
            :customer="formData.customer"
            @order-selected="updateSelectedOrders"
            @get-current-index="getCurrentIndex"
            :orders="paymentOrders"
          />
          <span
            v-if="isErrorFound && paymentErrors[index].orders !== ''"
            class="form__error-msg"
          >
            {{ paymentErrors[index].orders }}
          </span>
        </div>
        <div class="my-4 border" v-if="index < formData.paymentCount - 1"></div>
      </div>
      <!-- Order Form -->
      <!-- Remaining Balance -->
      <div class="row" v-if="!currentUser?.is_admin">
        <div class="col-lg-6 col-12 mt-3">
          <div class="form-input mb-lg-0">
            <label class="form-label">
              {{ t("Order.Current_Balance_58") }}
            </label>
            <span
              style="color: #09cf97; font-size: 16px; margin-left: 8px"
              :style="
                currentBalance >= 0 ? 'color: #09cf97;' : 'color: #fa5c7c;'
              "
            >
              {{ n(currentBalance, "currency") }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-12 mt-3">
          <div class="form-input mb-lg-0">
            <label class="form-label">
              {{ t("Order.Remaining_Balance_58") }}
            </label>
            <span
              style="color: #09cf97; font-size: 16px; margin-left: 8px"
              :style="
                remainingBalance >= 0 ? 'color: #09cf97;' : 'color: #fa5c7c;'
              "
            >
              {{ n(remainingBalance, "currency") }}
            </span>
          </div>
        </div>
      </div>
      <!-- Action Buttons -->
      <div class="mt-4">
        <div class="col-12">
          <div class="generic-form">
            <div
              class="mt-4"
              v-if="
                isCreatedSuccessfully != null ||
                isEditSuccessfully !== null ||
                isDraftSuccessfully !== null
              "
            >
              <div
                v-if="isCreatedSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                {{ t("CustomerManagement.FormsOrderCreatedSuccessfully_81") }}
              </div>
              <div
                v-else-if="isEditSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                {{ t("Payments.PaymentEditPaymentSuccess_20") }}
              </div>
              <div
                v-else-if="isDraftSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                {{ t("Payments.PaymentSaveDraft_21") }}
              </div>
              <div v-else class="col-md-5 alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
            </div>
            <div
              v-if="isUploadError"
              class="col-md-4 mt-4 alert alert-danger"
              role="alert"
            >
              {{ t("Payments.PaymentMaxFileSize_22") }}
            </div>
            <div class="form-options mt-4">
              <button class="cancel-btn" @click="onCancel()">
                {{ t("Order.CancelDiscard_55") }}
              </button>
              <button
                class="cancel-btn"
                @click="isEditPayment ? onDraftEdit() : onDraft()"
                v-if="
                  !isEditPayment ||
                  (isEditPayment &&
                    payments.length &&
                    !payments[0].is_clarification_requested)
                "
              >
                {{ t("Order.SaveDraft_56") }}
              </button>
              <button
                class="submit-btn"
                @click.prevent="isEditPayment ? onEdit() : onSubmit()"
              >
                {{
                  currentUser?.is_admin
                    ? t("Payments.PaymentSubmitSign_41")
                    : t("Order.SubmitSign_57")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- payment adjustment popup -->
    <adjust-payment-for-submission
      v-if="isPaymentAdjustment"
      :payments-data="paymentsNeedAdjustment"
      @close-adjustment-modal="closeAdjustmentModal"
      @adjust-payments="adjustPaymentsAndSubmit"
    >
    </adjust-payment-for-submission>
    <!-- Payment Adjust Popup -->
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, type Ref, watch } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useCustomerStore } from "@/stores/customer.store";
import { useProjectStore } from "@/stores/project.store";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import SelectOrdersForPaymentTable from "@/components/shared/tables/payments-table/SelectOrdersForPaymentTable.vue";
import router from "@/router";
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";
import moment from "moment";
import axios from "axios";
import { usePaymentStore } from "@/stores/payment.store";
import { useSupplierStore } from "@/stores/supplier.store";
import { useSupplierForm } from "@/_helpers/composables/master-data/supplier-changing.composable";
import { useOrderStore } from "@/stores/order.store";
import AdjustPaymentForSubmission from "@/components/payments/AdjustPaymentForSubmission.vue";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash";

const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { currentUser } = storeToRefs(useUserStore());
const { getSuppliersList, createSupplier } = useSupplierStore();
const { getCustomerCurrentBalance } = useOrderStore();
const { t, n } = useI18n();

const {
  errors,
  handleSubmit,
  isSubmitting,
  meta,
  resetForm,
  supplierName,
  supplierNumber,
  address,
  zipCode,
  country,
  city,
  phone,
  supplierEmail,
  countriesList,
  citiesList,
  setFieldValue,
} = useSupplierForm();

const {
  createPayment,
  createPaymentFile,
  getPaymentById,
  editPayment,
  deletePaymentFile,
} = usePaymentStore();

let customersList: any = ref([]);

let projectsList: any = ref([]);

const suppliersList: any = ref([]);
const suppliersListLoading: any = ref(false);

const isCancelPopup = ref(false);

const isSupplierPopup = ref(false);

const isUploadError = ref(false);

const isEditPayment = ref(false);

const selectedOrdersChangeKey = ref(0);

let editPaymentId = ref(0);

let deletedFileId = ref(null);

let fileCount = ref(0);

const newFiles: any = ref([
  {
    file: null,
    index: null,
    file_name: null,
    file_size: null,
  },
]);

let currentindex = ref(0);

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);

const isEditSuccessfully: Ref<boolean | null> = ref(null);

const isDraftSuccessfully: Ref<boolean | null> = ref(null);

const formFields: any = ref([]);

const currentBalance: any = ref(0);

const remainingBalance: any = ref(0);

const newCustomerId: any = ref(null);

const isPaymentAdjustment: any = ref(false);

const paymentsNeedAdjustment: any = ref([]);

const uuidv4 = () => {
  return URL.createObjectURL(new Blob()).substring(36);
};

let payments: any = ref([
  {
    id: uuidv4(),
    project: null,
    customer: null,
    amount: null,
    status: "PENDING_APPROVAL",
    payment_date_list: null,
    sequence_order_no: "",
    pay_no: null,
    order_type: null,
    internal_order_number: "",
    vendor_no: null,
    vendor: null,
    payment_term: null,
    invoice_number: null,
    invoice_date: null,
    due_date: null,
    goods_receipt: null,
    net_eur: null,
    gross_eur: null,
    comparison_gross_eur_order_list: null,
    total_order_balance: null,
    discount: null,
    gross_eur_less_discount: null,
    comment: null,
    date: null,
    comment_allea: null,
    comment_management: null,
    comment_insv: null,
    masse_liability: false,
    submit: false,
    document: null,
    url: null,
    orders: [],
    orders_verbose: [],
    end_after: null,
    frequency: "",
    name: "",
    is_recurring: false,
    supplier: null,
  },
]);

let formData: any = reactive({
  customer: null,
  project: null,
  paymentCount: payments.value.length,
  invoiceName: "",
  startDate: null,
  requestType: "one-time",
  frequency: "",
  endsAfter: 0,
});

const errorMessage: any = ref("");

const rules = {
  customer: {
    required,
  },
};

const mandatoryFields: any = ref([
  "supplier",
  "invoice_number",
  "due_date",
  "invoice_date",
  "date",
  "net_eur",
  "amount",
  "discount",
  "gross_eur_less_discount",
]);

const supplierCreatedSuccessfully: Ref<boolean | null> = ref(null);

const errorSupplierMessage = ref("");

const paymentSupplierIndex = ref(0);

const paymentOrders = ref([]);

let isErrorFound = ref(false);

let paymentErrors: any = ref([
  {
    project: "",
    customer: "",
    amount: "",
    status: "",
    payment_date_list: "",
    sequence_order_no: "",
    pay_no: "",
    order_type: "",
    internal_order_number: "",
    vendor_no: "",
    vendor: "",
    payment_term: "",
    invoice_number: "",
    invoice_date: "",
    due_date: "",
    goods_receipt: "",
    net_eur: "",
    gross_eur: "",
    comparison_gross_eur_order_list: "",
    discount: "",
    gross_eur_less_discount: "",
    comment: "",
    date: "",
    comment_allea: "",
    comment_management: "",
    comment_insv: "",
    masse_liability: "",
    submit: "",
    document: "",
    url: "",
    orders: "",
    end_after: "",
    frequency: "",
    name: "",
    is_recurring: "",
    supplier: "",
  },
]);

const $v = useVuelidate(rules, formData);

/* Get Project By Customer id*/
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

const add = () => {
  formData.paymentCount++;
  payments.value.push({
    id: uuidv4(),
    project: null,
    customer: null,
    amount: null,
    status: "PENDING_APPROVAL",
    payment_date_list: null,
    sequence_order_no: "",
    pay_no: null,
    order_type: null,
    internal_order_number: "",
    vendor_no: null,
    vendor: null,
    payment_term: null,
    invoice_number: null,
    invoice_date: null,
    due_date: null,
    goods_receipt: null,
    net_eur: null,
    gross_eur: null,
    comparison_gross_eur_order_list: null,
    total_order_balance: null,
    discount: null,
    gross_eur_less_discount: null,
    comment: null,
    date: null,
    comment_allea: null,
    comment_management: null,
    comment_insv: null,
    masse_liability: false,
    submit: false,
    document: null,
    url: null,
    orders: [],
    orders_verbose: [],
    end_after: null,
    frequency: "",
    name: "",
    is_recurring: false,
    supplier: null,
  });
  paymentErrors.value.push({
    project: "",
    customer: "",
    amount: "",
    status: "",
    payment_date_list: "",
    sequence_order_no: "",
    pay_no: "",
    order_type: "",
    internal_order_number: "",
    vendor_no: "",
    vendor: "",
    payment_term: "",
    invoice_number: "",
    invoice_date: "",
    due_date: "",
    goods_receipt: "",
    net_eur: "",
    gross_eur: "",
    comparison_gross_eur_order_list: "",
    discount: "",
    gross_eur_less_discount: "",
    comment: "",
    date: "",
    comment_allea: "",
    comment_management: "",
    comment_insv: "",
    masse_liability: "",
    submit: "",
    document: "",
    url: "",
    orders: "",
    end_after: "",
    frequency: "",
    name: "",
    is_recurring: "",
    supplier: "",
  });
};
const subtract = () => {
  formData.paymentCount--;
  if (formData.paymentCount < 1) {
    formData.paymentCount = 1;
    return;
  }
  payments.value.pop();
};

watch(
  () => formData.customer,
  async (newVal, oldVal) => {
    formFields.value = [];
    await getProjectsTableList(newVal);
    await axios
      .get("/payments/customer_payment_fields/?customer=" + newVal)
      .then((res) => {
        res.data.results.map((item: any) => {
          formFields.value.push(item);
          if (item.is_mandatory) {
            mandatoryFields.value.push(item.field.model_field_name);
          }
        });

        /* sort array */
        formFields.value.sort((a, b) => {
          const fieldA = a.field.field_name.toUpperCase();
          const fieldB = b.field.field_name.toUpperCase();

          if (fieldA < fieldB) {
            return -1;
          }
          if (fieldA > fieldB) {
            return 1;
          }
          return 0;
        });
      })
      .catch((error) => console.log(error));

    newCustomerId.value = newVal;
    if (newVal !== null) {
      await getCustomerCurrentBalance(newVal, null).then((response) => {
        currentBalance.value = response.current_balance;
        remainingBalance.value = response.current_balance;
      });
    }
    if (oldVal !== null) {
      // empty supplier selection
      payments.value = payments.value.map((payment) => {
        return { ...payment, supplier: null };
      });
      // list supplier by customer
    }
    await getSupplierByCustomerAndName(newCustomerId.value);
  }
);

watch(
  () => formData.project,
  async (newVal) => {
    if (newCustomerId.value !== null) {
      await getCustomerCurrentBalance(newCustomerId.value, newVal).then(
        (response) => {
          currentBalance.value = response.current_balance;
          remainingBalance.value = response.current_balance;
        }
      );
    }
  }
);

/* Choose Document */
const chooseDocument = (event: any, index: number) => {
  let fileToUpload = event.target.files[0];
  let size = fileToUpload.size / 1024;
  if (size > 20000) {
    isUploadError.value = true;
    return;
  }
  isUploadError.value = false;
  payments.value[index].document = fileToUpload;
  deletedFileId.value = null;

  var reader = new FileReader();
  reader.readAsDataURL(fileToUpload);
  if (fileToUpload.type.includes("pdf")) {
    reader.onload = async () => {
      let response = await fetch(`${reader.result}`);
      let pdfBlob = await response.blob();

      const blobUrl = URL.createObjectURL(pdfBlob);
      payments.value[index].url =
        "<embed src='" +
        blobUrl +
        "' type='application/pdf' frameBorder='0' scrolling='auto' width='100%' height='700px' />";
    };
  }
  if (fileToUpload.type.includes("image")) {
    reader.onload = async () => {
      let response = await fetch(`${reader.result}`);
      let pdfBlob = await response.blob();

      const blobUrl = URL.createObjectURL(pdfBlob);
      payments.value[index].url =
        "<img src='" +
        blobUrl +
        "' alt='preview' style='width: 100%' class='img-fluid' />";
    };
  }
  onUploadDocx(index, fileToUpload.name, fileToUpload.size);
};

const deleteAttachment = (index: number) => {
  payments.value[index].document = null;
  payments.value[index].url = null;
  let deleted = payments.value[index].files.shift();
  deletedFileId.value = deleted.id;
};

/* Mapping all errors messages to respected field */
const showErrorMessageToField = (errors: []) => {
  errors.map((error: any) => {
    if (error.attr.includes(".")) {
      let array = error.attr.split(".");
      let errorPaymentIndex = array[0];
      let errorPaymentField = array[1];
      let message = error.detail;

      paymentErrors.value[errorPaymentIndex][errorPaymentField] = message;
    } else {
      isEditSuccessfully.value = false;
      errorMessage.value = error.detail;
    }
  });
};

const openAdjustmentModal = () => {
  isPaymentAdjustment.value = true;
};

const closeAdjustmentModal = () => {
  isPaymentAdjustment.value = false;
  paymentsNeedAdjustment.value = [];
};

const adjustPaymentsAndSubmit = async (data: Object) => {
  Object.entries(data).forEach(([key, value]) => {
    const paymentIdx = payments.value.findIndex(
      (payment: any) => payment.id == key
    );
    let payment_allocation: any = [];
    value.forEach((d: any) => {
      if (
        d.allocated_amount !== null &&
        d.allocated_amount !== "" &&
        d.allocated_amount !== 0
      ) {
        payment_allocation.push({
          order: d.id,
          amount: d.allocated_amount,
        });
      } else {
        payment_allocation.push({
          order: d.id,
          amount: 0,
        });
      }
    });
    if (paymentIdx !== -1)
      payments.value[paymentIdx].payment_allocation = payment_allocation;
  });
  if (isEditPayment.value) {
    await performEdit();
  } else {
    await performCreate();
  }
  closeAdjustmentModal();
};

/* Submit the data */
const onSubmit = async () => {
  isErrorFound.value = false;
  isCreatedSuccessfully.value = null;

  let error = false;
  payments.value.map((item: any, index: any) => {
    mandatoryFields.value.map((field: any) => {
      if (item[field] === null || item[field] === "") {
        error = true;
        isErrorFound.value = true;
        paymentErrors.value[index][field] = t("Global.RequiredField_20");
      }
    });
  });
  if (error) {
    isCreatedSuccessfully.value = false;
    errorMessage.value = t("Global.FillAllFields_21");

    return;
  }

  // loading = true;
  payments.value.map((item: any) => {
    item.project = formData.project;
    item.customer = formData.customer;
    item.end_after = formData.endsAfter;
    item.frequency = formData.frequency;
    item.name = formData.invoiceName;
    item.is_recurring = formData.requestType === "one-time" ? false : true;
    item.start_date =
      formData.startDate !== null
        ? moment(formData.startDate).format("YYYY-MM-DD")
        : null;
    item.submit = true;
    item.payment_date_list =
      item.payment_date_list !== null
        ? moment(item.payment_date_list).format("YYYY-MM-DD")
        : null;
    item.invoice_date =
      item.invoice_date !== null
        ? moment(item.invoice_date).format("YYYY-MM-DD")
        : null;
    item.due_date =
      item.due_date !== null
        ? moment(item.due_date).format("YYYY-MM-DD")
        : null;
    item.goods_receipt =
      item.goods_receipt !== null
        ? moment(item.goods_receipt).format("YYYY-MM-DD")
        : null;
    item.date =
      item.date !== null ? moment(item.date).format("YYYY-MM-DD") : null;
    item.status = currentUser.value?.is_admin ? "APPROVED" : "PENDING_APPROVAL";
  });

  /* Check Validations */
  $v.value.$touch();

  if (!$v.value.$invalid) {
    // manual validation to check if payment amount matches order amounts
    payments.value.forEach((payment: any) => {
      let paymentAmountCents = payment.amount * 100;
      let orderAmountCents = payment.total_order_balance * 100;
      if (paymentAmountCents === orderAmountCents) {
        return;
      }
      paymentsNeedAdjustment.value.push({
        payment: payment,
        excessAmount: (paymentAmountCents - orderAmountCents) / 100,
      });
    });
    if (paymentsNeedAdjustment.value.length > 0) {
      openAdjustmentModal();
      return;
    }
    if (isEditPayment.value) {
      await performEdit();
    } else {
      await performCreate();
    }
  }
};

const performCreate = async () => {
  try {
    await createPayment(
      payments.value.map((item: any) => {
        delete item.total_order_balance;
        delete item.orders_verbose;
        delete item.id;
        return item;
      })
    ).then((response) => {
      response.map((payment: any, index) => {
        if (payments.value[index].document !== null) {
          createPaymentFile({
            payment: payment.id,
            file: payments.value[index].document,
          })
            .then(() => {
              isCreatedSuccessfully.value = true;
              router.push({ name: "payments" });
            })
            .catch((e) => {
              isCreatedSuccessfully.value = false;
              errorMessage.value = e.response.data.errors[0].detail;
            });
        } else {
          router.push({ name: "payments" });
        }
      });
    });
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};

const performEdit = async () => {
  try {
    let data = await editPayment(editPaymentId.value, payments.value[0]);
    if (data && newFiles.value.length > 0 && newFiles.value[0].file) {
      createPaymentFile({
        payment: data.data.id,
        file: newFiles.value[0].file,
        file_name: newFiles.value[0].file_name,
        file_size: newFiles.value[0].file_size,
      });
      isEditSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "payments" });
      }, 1000);
    } else if (data && deletedFileId.value) {
      await deletePaymentFile(deletedFileId.value);
      isEditSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "payments" });
      }, 1000);
    } else if (data) {
      isEditSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "payments" });
      }, 1000);
    } else {
      isEditSuccessfully.value = false;
    }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      isEditSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
      setTimeout(() => {
        isEditSuccessfully.value = null;
      }, 2000);
    }
  }
};

const onEdit = async () => {
  isErrorFound.value = false;
  isEditSuccessfully.value = null;
  let error = false;
  payments.value.map((item: any, index: any) => {
    mandatoryFields.value.map((field: any) => {
      if (item[field] === null || item[field] === "") {
        error = true;
        isErrorFound.value = true;
        paymentErrors.value[index][field] = "This field is Required";
      }
    });
  });
  if (error) {
    isEditSuccessfully.value = false;
    errorMessage.value =
      "Please fill all required fields first before submitting";
    return;
  }

  payments.value.map((item: any) => {
    item.project = formData.project;
    item.customer = formData.customer;
    item.end_after = formData.endsAfter;
    item.frequency = formData.frequency;
    item.name = formData.invoiceName;
    item.parent = null;
    item.is_recurring = formData.requestType === "one-time" ? false : true;
    item.start_date =
      formData.startDate !== null
        ? moment(formData.startDate).format("YYYY-MM-DD")
        : null;
    item.payment_date_list =
      item.payment_date_list !== null
        ? moment(item.payment_date_list).format("YYYY-MM-DD")
        : null;
    item.invoice_date =
      item.invoice_date !== null
        ? moment(item.invoice_date).format("YYYY-MM-DD")
        : null;
    item.due_date =
      item.due_date !== null
        ? moment(item.due_date).format("YYYY-MM-DD")
        : null;
    item.goods_receipt =
      item.goods_receipt !== null
        ? moment(item.goods_receipt).format("YYYY-MM-DD")
        : null;
    item.date =
      item.date !== null ? moment(item.date).format("YYYY-MM-DD") : null;
    item.status = currentUser.value?.is_admin ? "APPROVED" : "PENDING_APPROVAL";
    item.submit = true;
  });

  /* Check Validations */
  $v.value.$touch();

  if (!$v.value.$invalid) {
    // manual validation to check if payment amount matches order amounts
    payments.value.forEach((payment: any) => {
      let paymentAmountCents = payment.amount * 100;
      let orderAmountCents = payment.total_order_balance * 100;
      if (paymentAmountCents === orderAmountCents) {
        return;
      }
      paymentsNeedAdjustment.value.push({
        payment: payment,
        excessAmount: (paymentAmountCents - orderAmountCents) / 100,
      });
    });
    if (paymentsNeedAdjustment.value.length > 0) {
      openAdjustmentModal();
      return;
    }
    await performEdit();
  }
};

const closePopup = () => {
  isCancelPopup.value = false;
};

const onChangePaymentCancel = async () => {
  closePopup();
  router.push({ name: "payments" });
};

/* on Cancel */
const onCancel = () => {
  isCancelPopup.value = true;
};

/* on Draft */
const onDraft = async () => {
  payments.value.map((item: any) => {
    item.project = formData.project;
    item.customer = formData.customer;
    item.end_after = formData.endsAfter;
    item.frequency = formData.frequency;
    item.name = formData.invoiceName;
    item.is_recurring = false;
    item.start_date =
      formData.startDate !== null
        ? moment(formData.startDate).format("YYYY-MM-DD")
        : null;
    item.payment_date_list =
      item.payment_date_list !== null
        ? moment(item.payment_date_list).format("YYYY-MM-DD")
        : null;
    item.invoice_date =
      item.invoice_date !== null
        ? moment(item.invoice_date).format("YYYY-MM-DD")
        : null;
    item.due_date =
      item.due_date !== null
        ? moment(item.due_date).format("YYYY-MM-DD")
        : null;
    item.goods_receipt =
      item.goods_receipt !== null
        ? moment(item.goods_receipt).format("YYYY-MM-DD")
        : null;
    item.date =
      item.date !== null ? moment(item.date).format("YYYY-MM-DD") : null;
    item.status = "DRAFT";
    item.submit = false;
    item.payment_allocation = item.orders.map((order: any) => {
      return {
        order,
        amount: 0, // allocate 0 for now since it's a draft
      };
    });
    delete item.orders_verbose;
  });
  try {
    await createPayment(payments.value).then((response) => {
      response.map((payment: any, index) => {
        if (payments.value[index].document !== null) {
          createPaymentFile({
            payment: payment.id,
            file: payments.value[index].document,
          })
            .then(() => {
              isCreatedSuccessfully.value = true;
              router.push({ name: "payments" });
            })
            .catch((e) => {
              isCreatedSuccessfully.value = false;
              errorMessage.value = e.response.data.errors[0].detail;
            });
        } else {
          router.push({ name: "payments" });
        }
      });
    });

    // let { data } = await createPayment(payments.value);
    // console.log("payments.value", payments.value);
    // console.log("payments.value[0].document", payments.value[0].document);

    // if (data && payments.value[0].document !== null){
    //   console.log('ABC', data.id);

    //   createPaymentFile({
    //     payment: data.id,
    //     file: payments.value[0].document,
    //   }).then(() => {
    //     isDraftSuccessfully.value = true;
    //     setTimeout(() => {
    //       router.push({name: 'payments'});
    //     }, 1000);
    //   }).catch(e => {
    //     isDraftSuccessfully.value = false;
    //     console.log(e)
    //   });
    // } else {
    //   isDraftSuccessfully.value = true;
    //   setTimeout(() => {
    //     router.push({name: 'payments'});
    //   }, 1000);
    // }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      errorMessage.value = error.response.data.errors[0].detail;
      isDraftSuccessfully.value = false;
      setTimeout(() => {
        isDraftSuccessfully.value = null;
      }, 2000);
    }
  }
};

/* on Draft Edit */
const onDraftEdit = async () => {
  payments.value.map((item: any) => {
    item.project = formData.project;
    item.customer = formData.customer;
    item.end_after = formData.endsAfter;
    item.frequency = formData.frequency;
    item.name = formData.invoiceName;
    item.is_recurring = formData.requestType === "one-time" ? false : true;
    item.parent = null;
    item.start_date =
      formData.startDate !== null
        ? moment(formData.startDate).format("YYYY-MM-DD")
        : null;
    item.payment_date_list =
      item.payment_date_list !== null
        ? moment(item.payment_date_list).format("YYYY-MM-DD")
        : null;
    item.invoice_date =
      item.invoice_date !== null
        ? moment(item.invoice_date).format("YYYY-MM-DD")
        : null;
    item.due_date =
      item.due_date !== null
        ? moment(item.due_date).format("YYYY-MM-DD")
        : null;
    item.goods_receipt =
      item.goods_receipt !== null
        ? moment(item.goods_receipt).format("YYYY-MM-DD")
        : null;
    item.date =
      item.date !== null ? moment(item.date).format("YYYY-MM-DD") : null;
    item.status = "DRAFT";
    item.submit = false;
    item.payment_allocation = item.orders.map((order: any) => {
      return {
        order,
        amount: 0, // allocate 0 for now since it's a draft
      };
    });
  });

  try {
    let { data } = await editPayment(editPaymentId.value, payments.value[0]);
    if (
      data &&
      payments.value[0].document &&
      payments.value[0].document !== null
    ) {
      createPaymentFile({
        payment: data.id,
        file: payments.value[0].document,
      })
        .then(() => {
          isDraftSuccessfully.value = true;
          setTimeout(() => {
            router.push({ name: "payments" });
          }, 1000);
        })
        .catch((e) => {
          isDraftSuccessfully.value = false;
          console.log(e);
        });
    } else {
      isDraftSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "payments" });
      }, 1000);
    }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      isDraftSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
      setTimeout(() => {
        isDraftSuccessfully.value = null;
      }, 2000);
    }
  }
};

const getSupplierByCustomerAndName = async (customerId: any, name?: any) => {
  suppliersListLoading.value = true;
  const response = await getSuppliersList({
    page_size: 500,
    customer: customerId,
    search: name,
  });
  suppliersList.value = response.results;

  suppliersList.value.map((supplier: any) => {
    supplier.display_name = supplier.name + " - " + supplier.supplier_number;
  });
  suppliersListLoading.value = false;
  return suppliersList.value;
};

const filterSuppliers = debounce(async function (query: string, _: any) {
  getSupplierByCustomerAndName(newCustomerId.value, query);
}, 300);

const updateSelectedOrders = (newValue: any) => {
  payments.value[currentindex.value].orders = [];
  payments.value[currentindex.value].orders_verbose = [];
  payments.value[currentindex.value].comparison_gross_eur_order_list = 0;
  payments.value[currentindex.value].total_order_balance = 0;
  payments.value[currentindex.value].sequence_order_no = "";
  newValue.map((order: any) => {
    payments.value[currentindex.value].orders.push(order.id);
    payments.value[currentindex.value].orders_verbose.push(order);
    payments.value[currentindex.value].comparison_gross_eur_order_list +=
      +order.amount;
    payments.value[currentindex.value].total_order_balance +=
      +order.current_balance;
  });

  let orderSum = payments.value.reduce(
    (total, item) => total + item.comparison_gross_eur_order_list,
    0
  );

  remainingBalance.value = currentBalance.value - orderSum;
};

const getCurrentIndex = (newValue: any) => {
  currentindex.value = newValue;
};

const openNewSupplierModal = (currentIndex: number) => {
  isSupplierPopup.value = true;
  paymentSupplierIndex.value = currentIndex;
  setFieldValue("customer", formData.customer);
};
const closeNewSupplierModal = () => {
  isSupplierPopup.value = false;
  resetForm();
};

const addNewSupplier = handleSubmit(async (values) => {
  try {
    createSupplier({
      name: values.supplierName,
      supplier_number: values.supplierNumber,
      address: values.address,
      zip_code: values.zipCode!,
      city: values.city!,
      country: values.country!,
      phone_number: values.phone,
      email: values.supplierEmail,
      customer: formData.customer,
    })
      .then((response) => {
        let supplierResponse: any = response;

        supplierResponse["display_name"] =
          supplierResponse.name + " - " + supplierResponse.supplier_number;

        suppliersList.value.push(supplierResponse);

        payments.value[paymentSupplierIndex.value].supplier =
          supplierResponse.id;
        payments.value[paymentSupplierIndex.value].order_no_supplier =
          supplierResponse.supplier_number;

        supplierCreatedSuccessfully.value = true;
        resetForm();
        setTimeout(() => {
          supplierCreatedSuccessfully.value = null;
          closeNewSupplierModal();
        }, 1000);
      })
      .catch((error: any) => {
        supplierCreatedSuccessfully.value = false;
        errorSupplierMessage.value = error.response.data.errors[0].detail;
      });
  } catch (error: any) {
    console.error(error);
    throw error;
  }
});

watch(
  payments,
  (newPayments, oldPayments) => {
    newPayments.forEach((newObj: any, index: any) => {
      const oldObj = oldPayments[index];
      suppliersList.value.map((item: any) => {
        if (item.id === oldObj.supplier) {
          newObj.order_no_supplier = item.supplier_number;
        }
      });
    });
  },
  { deep: true }
);

onMounted(async () => {
  await getCustomersOptions().then((response) => {
    customersList.value = response;
    let getSelectedCustomerID = localStorage.getItem("selectedCustomerId");
    let customerId = getSelectedCustomerID
      ? JSON.parse(getSelectedCustomerID)
      : -1;
    if (customerId !== -1) formData.customer = customerId;
  });

  if (formData.customer) {
    newCustomerId.value = formData.customer;
  }

  isEditPayment.value =
    localStorage.getItem("isEditPayment") === "true" ? true : false;

  if (isEditPayment.value) {
    // let paymentId = localStorage.getItem("paymentId");
    let paymentId = router.currentRoute.value.query.payment;
    formData.paymentCount = 0;
    payments.value = [];
    getPaymentById(paymentId).then((response) => {
      payments.value.push(response);
      payments.value.map((payment: any) => {
        if (payment.customer.id !== formData.customer) {
          router.push({ name: "payments" });
        }
        editPaymentId.value = payment.id;
        formData.customer = payment.customer.id;
        formData.project = payment.project?.id || null;
        formData.orderCount = 1;
        formData.name = payment.name === null ? "" : payment.name;
        formData.startDate = payment.start_date;
        formData.requestType = payment.is_recurring ? "recurring" : "one-time";
        formData.frequency =
          payment.frequency === null ? "" : payment.frequency;
        formData.endsAfter = payment.end_after === null ? 0 : payment.end_after;
        paymentOrders.value = payment.orders;
        selectedOrdersChangeKey.value++; // force table rerender
        payment.supplier = payment.supplier?.id;
        payment.order_no_supplier = payment.supplier?.supplier_number;

        payment.url = payment.files.length > 0 ? payment.files[0].file : null;
        if (payment.url !== null) {
          if (
            payment.files.length > 0 &&
            payment.files[0].file.includes(".pdf")
          ) {
            payment.url =
              "<embed src='" +
              payment.url +
              "' type='application/pdf' frameBorder='0' scrolling='auto' width='100%' height='700px' />";
          } else {
            payment.url =
              "<img src='" +
              payment.url +
              "' alt='preview' style='width: 100%' class='img-fluid' />";
          }
        }
      });
      formData.paymentCount = payments.value.length;
    });
  }
});

const onUploadDocx = (index: any, fileName: string, fileSize: string) => {
  if (fileCount.value === 0) {
    newFiles.value[index].file = payments.value[index].document;
    newFiles.value[index].index = index;
    newFiles.value[index].file_name = fileName;
    newFiles.value[index].file_size = fileSize;
  } else {
    newFiles.value.push({
      file: payments.value[index].document,
      index: index,
      file_name: fileName,
      file_size: fileSize,
    });
  }
  fileCount.value++;
};

const formatDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};
</script>

<style lang="css" scoped>
.order-counter {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-left: 0;
  border-right: 0;
  text-align: center;
}

.order-counter button {
  width: 32px;
  height: 32px;
  color: #ffffff;
  background-color: #7a79f3;
  border-radius: 3px;
}

.order-counter div {
  width: calc(100% - 64px);
  padding: 0 10px;
}

.upload-btn {
  font-size: 15px;
  background-color: #ceec34;
  color: #323232;
  border-radius: 3px;
  padding: 10px 25px;
  min-width: 120px;
  margin-bottom: 10px;
}

.add-new-supplier {
  background: #ceec34;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 1.3;
  cursor: pointer;
}
</style>
