<template>
  <div class="tw-flex tw-flex-row tw-space-x-8 tw-w-full">
    <StatisticsBarChart
      :series="series"
      :xaxis="xaxis"
      :isLoading="isLoading"
      isMoney
    >
      <template #title> {{ t("Statistics.OrderVolumeTitle_9") }} </template>
    </StatisticsBarChart>

    <div class="tw-w-2/12 tw-flex tw-flex-col tw-gap-y-4 tw-max-h-72">
      <StatisticsCard
        :title="t('Statistics.TotalOrderVolume_10')"
        :value="totalOrdersVolume"
        :trendingValue="totalOrdersVolumeComparison"
      >
        <template #icon>
          <totalRequestedOrdersIcon />
        </template>
        <template #value
          >{{ formattedTotalOrdersVolume.value
          }}<span class="tw-text-sm">
            {{ formattedTotalOrdersVolume.unit }}
          </span>
        </template>
      </StatisticsCard>

      <StatisticsCard
        :title="t('Statistics.TotalOrderVolumeApproved_11')"
        :value="totalApprovedOrdersVolume"
        :trendingValue="totalApprovedOrdersVolumeComparison"
        dark
      >
        <template #icon>
          <approvedOrdersIcon />
        </template>
        <template #value>
          {{ formattedApprovedOrdersVolume.value
          }}<span class="tw-text-sm">{{
            formattedApprovedOrdersVolume.unit
          }}</span>
        </template>
      </StatisticsCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import totalRequestedOrdersIcon from "../icons/totalRequestedOrdersIcon.svg";
import approvedOrdersIcon from "../icons/approvedOrdersIcon.svg";
import { ref, onMounted, watch, type Ref, computed } from "vue";
import StatisticsBarChart from "../components/StatisticsBarChart.vue";
import StatisticsCard from "../components/StatisticsCard.vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";

const { formatCurrency } = useFormatValue();
const { getOrdersVolume } = useDashboardStore();

const isLoading = ref(true);
const { t } = useI18n();

const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.TotalOrderVolumeApproved_11"),
    data: [],
  },
  {
    name: t("Statistics.TotalOrderVolume_10"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);
const totalOrdersVolume = ref(0);
const totalApprovedOrdersVolume = ref(0);
const totalOrdersVolumeComparison = ref(0);
const totalApprovedOrdersVolumeComparison = ref(0);

const formattedTotalOrdersVolume: Ref<any> = computed(() => {
  return formatCurrency(totalOrdersVolume.value, true);
});

const formattedApprovedOrdersVolume: Ref<any> = computed(() => {
  return formatCurrency(totalApprovedOrdersVolume.value, true);
});

const fetchData = async () => {
  isLoading.value = true;
  try {
    const data = await getOrdersVolume(
      props.startDate,
      props.endDate,
      props.customerId
    );
    series.value[0].data = data.approved_orders_volume;
    series.value[1].data = data.all_orders_volume;
    xaxis.value = data.dates;
    totalOrdersVolume.value = data.total_orders_volume;
    totalApprovedOrdersVolume.value = data.total_approved_orders_volume;
    totalOrdersVolumeComparison.value = data.total_orders_volume_comparison;
    totalApprovedOrdersVolumeComparison.value =
      data.total_approved_orders_volume_comparison;
  } catch (error) {
    console.error("Error fetching orders volume:", error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(fetchData);

watch(() => props, fetchData, { deep: true });
</script>
