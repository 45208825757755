<template>
  <nav class="back-nav" @click="emit('backToLogin')">
    <i class="icon-arrow-left"></i>
    <span>back</span>
  </nav>

  <header class="login-header">
    <h2>{{ t("Login.ForgetPassword_16").toUpperCase() }}</h2>
    <p>{{ t("Login.PleaseTypeInYourEmail_17") }}</p>
  </header>

  <div v-if="isEmailSentSuccessfully" class="alert alert-success" role="alert">
    {{ t("Login.YouShouldHaveReceivedAnEmail_30") }}
  </div>

  <Form
    @submit="onSendPasswordResetMail"
    v-slot="{ errors, isSubmitting }"
    class="login-form"
  >
    <div class="form-group email-group">
      <label for="email" class="form-label">{{ t("Global.Email_8") }}</label>

      <Field
        id="email"
        name="email"
        type="email"
        class="form-control"
        :class="{ 'is-invalid': errors.email }"
        :placeholder="t('Login.EnterYourEmailHere_7')"
        :rules="emailRules"
      />

      <div class="invalid-feedback">{{ errors.email }}</div>
    </div>

    <button
      class="login-action-btn"
      :disabled="isSubmitting || isEmailSentSuccessfully"
    >
      {{ t("Login.SendEmail_31") }}
    </button>
  </Form>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/auth.store";

import { Form, Field } from "vee-validate";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { string } from "yup";

const { t } = useI18n();
const emailRules = string()
  .required(t("Login.EmailIsRequired_34"))
  .email(t("Login.EmailMustBeValid_35"));

const emit = defineEmits<{
  (e: "backToLogin"): void;
}>();

const isEmailSentSuccessfully = ref(false);

const { sendPasswordResetMail } = useAuthStore();

const onSendPasswordResetMail = async (values: any): Promise<void> => {
  await sendPasswordResetMail(values.email);
  isEmailSentSuccessfully.value = true;
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";
</style>
